{
    /* eslint-disable */
}
import { LoadingButton } from '@mui/lab';
import { Button, Checkbox, FormControlLabel, Grid, InputLabel, Modal } from '@mui/material';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box } from '@mui/system';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import MainCard from 'ui-component/cards/MainCard';
import InputTextField from 'ui-component/form/InputTextField';
import { useStyles } from 'views/utilities/Style';
import { SetNotification } from 'store/services/api';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import useGetAxios from 'hooks/useGetAxios';
import Loader from 'ui-component/Loader';
import EditIcon from '@mui/icons-material/Edit';
import useFetch from 'hooks/useFetch';
import RenameForm from '../../ui-component/form/RenameForm';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import SelectForm from '../../ui-component/form/SelectForm';
import FormDateTime from '../../ui-component/dateTime/FormDateTime';
import validator from 'validator';
import { formatErpResponse } from '../../utils/utils';
import moment from 'moment';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
// import { Value } from 'sass';

function EditSalesPerson() {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { t } = useTranslation();
    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const [isEnabled, setIsEnabled] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);

    const [open, setOpen] = useState(false);
    const { data: doc, isPending } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Sales%20Person&name=${id}`);

    const countIsParentReqData = {
        doctype: 'Sales Person',
        fields: ['`tabSales Person`.`name`'],
        filters: [['Sales Person', 'parent_sales_person', '=', id]]
    };
    const gendersReqData = {
        doctype: 'Gender',
        fields: ['`tabGender`.`name`'],
        start: 0,
        page_length: 500
    };
    const employmentTypesReqData = {
        doctype: 'Employment Type',
        fields: ['`tabEmployment Type`.`name`'],
        start: 0,
        page_length: 500
    };
    const companyReqData = {
        doctype: 'Company',
        fields: ['`tabCompany`.`name`'],
        start: 0,
        page_length: 500
    };
    const gradesReqData = {
        doctype: 'Employee Grade',
        fields: ['`tabEmployee Grade`.`name`'],
        start: 0,
        page_length: 500
    };
    const caisseReqData = {
        doctype: 'POS Profile',
        fields: ['`tabPOS Profile`.`name`', '`tabPOS Profile`.`company`', '`tabPOS Profile`.`sales_person`', '`tabPOS Profile`.`disabled`'],
        filters: [['POS Profile', 'sales_person', '=', id]],
        start: 0,
        page_length: 500
    };

    const { data: count, isPending: isPendingcount } = useFetch(`api/get-list`, countIsParentReqData, 'POST');
    const { data: genders, isPending: isGendersPending } = useFetch(`/api/get-list`, gendersReqData, 'POST');
    const { data: types, isPending: isTypesPending } = useFetch(`/api/get-list`, employmentTypesReqData, 'POST');
    const { data: companies, isPending: isCompaniesPending } = useFetch(`/api/get-list`, companyReqData, 'POST');
    const { data: grades, isPending: isGradesPending } = useFetch(`/api/get-list`, gradesReqData, 'POST');
    const { data: caisse, isPending: isCaissePending } = useFetch(`/api/get-list`, caisseReqData, 'POST');
    const newSalesPerson = {
        docstatus: 0,
        ...(id && {
            creation: doc.creation,
            modified: doc.modified,
            owner: doc.owner,
            modified_by: doc.owner,
            name: doc.name
        }),
        doctype: 'Sales Person',
        is_group: 0,
        // sales_person_name: salesPersonName,
        enabled: isEnabled ? 1 : 0
    };

    const [state_rename, setState_rename] = useState({
        doctype: 'Sales Person',
        docname: id,
        new_name: id,
        enqueue: true,
        merge: 0
    });

    const handleClose = () => {
        setOpen(false);
        setState_rename({ doctype: 'Sales Person', docname: id, new_name: id, enqueue: true, merge: 0 });
    };

    const previousEnabled = doc?.enabled;
    console.log('dooooooc', previousEnabled);
    const handleAddSalesPerson = async (value) => {
        setLoadingButton(true);

        const dataToSave = { ...newSalesPerson, ...value };

        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: dataToSave, action: 'Save' })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    var message = t('common:vendeurModifieSuccess');

                    // Vérifier si l'attribut 'enabled' a été changé
                    if ((id && value.enabled) !== previousEnabled) {
                        if (id && value.enabled === 1) {
                            message = t('common:vendeurActiveSuccess');
                        } else if (id && value.enabled === 0) {
                            message = t('common:vendeurDesactiveSuccess');
                        }
                    }

                    // Afficher la notification appropriée
                    dispatch(
                        SetNotification({
                            code: 'success',
                            message: message
                        })
                    );
                    // console.log('yyyyyyyyyyyyy', value);
                    navigate('/core/vendeurs');
                }
            });
    };

    useEffect(() => {
        if (id && !Array.isArray(doc) && doc) {
            formik.setValues(doc, false);
            setIsEnabled(doc.enabled == 1 ? true : false);
        }
    }, [id, doc]);

    const formik = useFormik({
        initialValues: {
            gender: '',
            employment_type: '',
            company: '',
            grade: '',
            cin: '',
            prefered_email: '',
            personal_email: '',
            date_of_joining: '',
            date_of_birth: '',
            mobile: ''
        },
        validationSchema: yup.object({
            gender: yup.string('Enter your Gender').required(t('core:SALES_PERSON_FORM_VALIDATION.REQUIRED.GENDER')),
            employment_type: yup
                .string('Enter your Employment type')
                .required(t('core:SALES_PERSON_FORM_VALIDATION.REQUIRED.EMPLOYMENT_TYPE')),
            company: yup.string('Enter your Company').required(t('core:SALES_PERSON_FORM_VALIDATION.REQUIRED.COMPANY')),
            grade: yup.string('Enter your Company').required(t('core:SALES_PERSON_FORM_VALIDATION.REQUIRED.GRADE')),
            cin: yup.string('Enter your cin').length(8).required(t('core:SALES_PERSON_FORM_VALIDATION.REQUIRED.CIN')),
            mobile: yup
                .string('Enter your mobile')
                .required(t('core:SALES_PERSON_FORM_VALIDATION.REQUIRED.MOBILE'))
                .test('isMobilePhone', 'Invalid format', function (value) {
                    const { path, createError } = this;
                    return (
                        (!!value && validator.isMobilePhone(value)) ||
                        createError({
                            path,
                            message: 'Invalid format'
                        })
                    );
                }),
            prefered_email: yup
                .string('Enter your prefered email')
                .required(t('core:SALES_PERSON_FORM_VALIDATION.REQUIRED.PREFERED_EMAIL'))
                .email(),
            personal_email: yup
                .string('Enter your personal email')
                .required(t('core:SALES_PERSON_FORM_VALIDATION.REQUIRED.PERSONAL_EMAIL'))
                .email(),
            date_of_joining: yup.date().max(new Date()).required(t('core:SALES_PERSON_FORM_VALIDATION.REQUIRED.DATE_OF_JOINING')),
            date_of_birth: yup.date().required(t('core:SALES_PERSON_FORM_VALIDATION.REQUIRED.DATE_OF_JOINING'))
        }),
        onSubmit: (values) => {
            handleAddSalesPerson({ ...values, enabled: isEnabled ? 1 : 0 });
        }
    });

    const chips = caisse.map((value, index) => (
        <Chip key={index} label={value.name} color="primary" size="large" variant="outlined" margin="1" className={classes.inputLabel} />
    ));

    if (isCaissePending && isPending && isPendingcount && isGendersPending && isTypesPending && isCompaniesPending && isGradesPending)
        return <Loader />;

    return (
        <div>
            <Box>
                <MainCard
                    title={
                        <Box display={'flex'} flexDirection={'column'}>
                            <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
                            <Box display={'flex'} flexWrap="wrap">
                                {id ? `${t('core:asp')}: ${id}` : t('core:newsp')}
                                {id && <EditIcon sx={{ fontSize: 17, ml: 1, cursor: 'pointer' }} onClick={() => setOpen(true)} />}
                            </Box>
                        </Box>
                    }
                >
                    <Box>
                        <form onSubmit={formik.handleSubmit}>
                            <FormControlLabel
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'left'
                                }}
                                label={t('core:enabled')}
                                control={<Checkbox color="blue" checked={isEnabled} onChange={() => setIsEnabled(!isEnabled)} />}
                            />
                            {/*{!id && (*/}
                            <Grid container spacing={2} columns={2} display={'flex'} justifyContent={'center'}>
                                <Grid item xs={1}>
                                    <SelectForm
                                        fullwidth
                                        label={t('core:contract-type')}
                                        name={'employment_type'}
                                        handleChange={formik.handleChange}
                                        value={formik.values.employment_type}
                                        data={types}
                                        error={formik.touched.employment_type && Boolean(formik.errors.employment_type)}
                                        helper={formik.touched.employment_type && formik.errors.employment_type}
                                        propToRender={'name'}
                                        required
                                        sx={{ mt: 0 }}
                                    />
                                    <SelectForm
                                        fullwidth
                                        label={t('core:grade')}
                                        name={'grade'}
                                        handleChange={formik.handleChange}
                                        value={formik.values.grade}
                                        data={grades}
                                        error={formik.touched.grade && Boolean(formik.errors.grade)}
                                        helper={formik.touched.grade && formik.errors.grade}
                                        propToRender={'name'}
                                        required
                                        sx={{ mt: 0 }}
                                    />
                                    <InputTextField
                                        fullwidth
                                        handleChange={formik.handleChange}
                                        value={formik.values.prefered_email}
                                        name="prefered_email"
                                        required
                                        label="Prefered Email"
                                        id="outlined-size-small"
                                        size="small"
                                        error={formik.touched.prefered_email && Boolean(formik.errors.prefered_email)}
                                        helper={formik.touched.prefered_email && formik.errors.prefered_email}
                                        sx={{ mt: 0 }}
                                    />
                                    <FormDateTime
                                        fullwidth
                                        sx={{ mt: 1 }}
                                        required
                                        type="date"
                                        label={'Date of Joining'}
                                        name="date_of_joining"
                                        value={formik.values.date_of_joining}
                                        handleChange={formik.handleChange}
                                        error={formik.touched.date_of_joining && Boolean(formik.errors.date_of_joining)}
                                        helper={formik.touched.date_of_joining && formik.errors.date_of_joining}
                                        disabled={id && doc?.status !== 'Draft'}
                                        isValidDate={true}
                                    />
                                    <InputTextField
                                        fullwidth
                                        handleChange={formik.handleChange}
                                        value={formik.values.mobile}
                                        name="mobile"
                                        required
                                        label="Mobile"
                                        id="outlined-size-small"
                                        size="small"
                                        error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                                        helper={formik.touched.mobile && formik.errors.mobile}
                                        sx={{ mt: 0 }}
                                    />
                                    <Box display={'flex'} flexDirection="row" mt={3}>
                                        <Stack marginLeft={5} direction="row" spacing={2}>
                                            <InputLabel className={classes.inputLabel}>Caisse associé :</InputLabel>
                                            {caisse.length > 0 ? chips : <Typography>no caisse associé</Typography>}
                                        </Stack>
                                    </Box>
                                </Grid>
                                <Grid item xs={1}>
                                    <SelectForm
                                        fullwidth
                                        label={t('core:gender')}
                                        name={'gender'}
                                        handleChange={formik.handleChange}
                                        value={formik.values.gender}
                                        data={genders}
                                        error={formik.touched.gender && Boolean(formik.errors.gender)}
                                        helper={formik.touched.gender && formik.errors.gender}
                                        propToRender={'name'}
                                        required
                                        sx={{ mt: 0 }}
                                    />
                                    <SelectForm
                                        fullwidth
                                        label={t('core:comp')}
                                        name={'company'}
                                        handleChange={formik.handleChange}
                                        value={formik.values.company}
                                        data={companies}
                                        error={formik.touched.company && Boolean(formik.errors.company)}
                                        helper={formik.touched.company && formik.errors.company}
                                        propToRender={'name'}
                                        required
                                        sx={{ mt: 0 }}
                                    />
                                    <InputTextField
                                        fullwidth
                                        sx={{ mt: 1 }}
                                        required
                                        id="cin"
                                        name="cin"
                                        label={t('core:cin')}
                                        value={formik.values.cin}
                                        handleChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.cin && Boolean(formik.errors.cin)}
                                        helper={formik.touched.cin && formik.errors.cin}
                                        size="small"
                                    />
                                    <InputTextField
                                        fullwidth
                                        handleChange={formik.handleChange}
                                        value={formik.values.personal_email}
                                        name="personal_email"
                                        required
                                        label="Personal Email"
                                        id="outlined-size-small"
                                        size="small"
                                        error={formik.touched.personal_email && Boolean(formik.errors.personal_email)}
                                        helper={formik.touched.personal_email && formik.errors.personal_email}
                                        sx={{ mt: 0 }}
                                    />
                                    <FormDateTime
                                        fullwidth
                                        sx={{ mt: 1 }}
                                        required
                                        type="date"
                                        label={'Date of Birth'}
                                        name="date_of_birth"
                                        value={formik.values.date_of_birth}
                                        handleChange={formik.handleChange}
                                        error={formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth)}
                                        helper={formik.touched.date_of_birth && formik.errors.date_of_birth}
                                        disabled={id && doc?.status !== 'Draft'}
                                        max={moment().subtract(10, 'years').format('YYYY-MM-DD')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                                        <Button sx={{ mr: 1 }} onClick={() => navigate(-1)}>
                                            {t('common:cancel')}
                                        </Button>
                                        <LoadingButton type="submit" size="large" variant="contained" color="blue" loading={loadingButton}>
                                            {t('common:save')}
                                        </LoadingButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </MainCard>
            </Box>
            <Modal onClose={handleClose} open={open}>
                <RenameForm
                    state={state_rename}
                    setState={setState_rename}
                    list={'/core/vendeurs'}
                    message_success={t('core:sprenamed')}
                    title={t('core:renamesp')}
                    handleClose={handleClose}
                />
            </Modal>
        </div>
    );
}

export default EditSalesPerson;
