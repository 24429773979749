import { Box } from '@mui/system';
import useGetAxios from 'hooks/useGetAxios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import MainCard from 'ui-component/cards/MainCard';
import { useStyles } from 'views/utilities/Style';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Loader from 'ui-component/Loader';
import InputTextField from 'ui-component/form/InputTextField';
import { Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { formatErpResponse } from 'utils/utils';
import { SetNotification } from 'store/services/api';
import InfoIcon from '@mui/icons-material/Info';
import useFetch from 'hooks/useFetch';
import { useTranslation } from 'react-i18next';
import FormDateTime from 'ui-component/dateTime/FormDateTime';
import InputCheckboxField from 'ui-component/form/InputCheckboxField';

const ItemCollectionForm = () => {
    const classes = useStyles();
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const { data: doc, isPending } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Item%20Collection&name=${id}`);

    const { data: count, isPending: isCountPending } = useFetch(
        `/api/get-list-count`,
        {
            doctype: 'Item',
            filters: [['Item', 'item_collection', '=', `${id}`]],
            distinct: false
        },
        'POST'
    );

    const { data: parents } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Item Collection',
            fields: ['`tabItem Collection`.`name`'],
            filters: [['Item Collection', 'is_group', '=', 1]]
        },
        'POST'
    );

    const [loadingButton, setLoadingButton] = useState(false);

    const [formState, setFormState] = useState({
        is_group: 0,
        collection_name: '',
        collection_code: '',
        start_date: '',
        end_date: '',
        parent_item_collection: '',
        description: ''
    });
    const [formErrState, setFormErrState] = useState({
        collection_name: false,
        collection_code: false
    });

    const handleChange = (e) => {
        setFormState({ ...formState, [e.target.name]: e.target.value });
        // err handlers
        if (e.target.value == '') {
            setFormErrState({ ...formErrState, [e.target.name]: true });
        } else {
            setFormErrState({ ...formErrState, [e.target.name]: false });
        }
    };

    const handleChangeCheckbox = (event, value) => {
        setFormState({ ...formState, [event.target.name]: value ? 1 : 0 });
    };

    const handleErrorMessage = (errorMessage) => {
        const valueMissingMatch = errorMessage.match(/Value missing for (.+): (.+)/);
        if (valueMissingMatch) {
            const fieldName = valueMissingMatch[2].toLowerCase().replace(/ /g, '_');
            return t('common:fieldIsRequired', { field: t(`common:${fieldName}`) });
        }

        const mandatoryFieldMatch = errorMessage.match(/(.+) is (mandatory|required)/i);
        if (mandatoryFieldMatch) {
            const fieldName = mandatoryFieldMatch[1].toLowerCase().replace(/ /g, '_');
            return t('common:fieldIsRequired', { field: t(`common:${fieldName}`) });
        }

        if (errorMessage.includes('already exists')) {
            return t('common:collectionAlreadyExists');
        }

        if (errorMessage.includes('Record does not exist')) {
            return t('common:recordDoesNotExist');
        }

        return errorMessage;
    };

    const handleAddCollection = async () => {
        setLoadingButton(true);
        const data = {
            doc: {
                ...(!id && { __islocal: 1 }),
                ...(id && {
                    creation: doc.creation,
                    modified: doc.modified,
                    owner: doc.owner,
                    modified_by: doc.owner,
                    name: doc.name
                }),
                doctype: 'Item Collection',
                collection_name: formState.collection_name,
                collection_code: formState.collection_code,
                is_group: formState.is_group,
                start_date: formState.start_date,
                end_date: formState.end_date,
                description: formState.description,
                __unsaved: 1
            },
            action: 'Save'
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => res.json())
            .then((data) => {
                setLoadingButton(false);
                if (data?.success === false) {
                    const errorMessage = formatErpResponse(data?.message);
                    const customErrorMessage = handleErrorMessage(errorMessage);
                    dispatch(SetNotification({ code: 'warning', message: customErrorMessage }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: t('common:collectionCreatedSuccess') }));
                    navigate('/pim/product-collections');
                }
            })
            .catch((error) => {
                setLoadingButton(false);
                dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
            });
    };
    useEffect(() => {
        if (id && doc?.collection_name) {
            setFormState({
                ...formState,
                collection_name: doc?.collection_name,
                collection_code: doc?.collection_code,
                end_date: doc?.end_date,
                is_group: doc?.is_group,
                start_date: doc?.start_date,
                description: doc?.description
            });
        }
    }, [doc]);

    const title = (
        <Box p={-1} display={'flex'} flexDirection={'column'}>
            <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
            {id ? `${t('products:collection')}: ${doc?.collection_name}` : t('products:newCollection')}
        </Box>
    );

    if (id && isPending) return <Loader />;

    return (
        <Box>
            <MainCard title={title}>
                {doc?.collection_name && count && (
                    <Box display={'flex'} mt={-4} mb={2}>
                        <InfoIcon sx={{ mt: 0.6 }} />
                        <Typography p={1} variant="h5" color="primary">
                            {`Cette collection comprend ${count} produit(s).`}
                        </Typography>
                    </Box>
                )}
                <Grid container spacing={2} display={'flex'} justifyContent={'left'} flexDirection={'column'}>
                    <InputCheckboxField
                        label={t('columns:band')}
                        name="is_group"
                        value={formState.is_group === 1 ? true : false}
                        handleChange={handleChangeCheckbox}
                    />
                    <InputTextField
                        required
                        handleChange={handleChange}
                        value={formState.collection_code}
                        error={formErrState?.collection_code}
                        label={t('products:collectioncode')}
                        name="collection_code"
                    />
                    <InputTextField
                        required
                        handleChange={handleChange}
                        value={formState.collection_name}
                        error={formErrState?.collection_name}
                        label={t('columns:coll')}
                        name="collection_name"
                    />
                    <InputTextField
                        handleChange={handleChange}
                        value={formState.description}
                        label={t('common:description')}
                        name="description"
                    />
                    <FormDateTime
                        type="date"
                        label={t('documents:psd')}
                        name="start_date"
                        value={formState.start_date}
                        handleChange={handleChange}
                        maxdate={formState.end_date}
                    />
                    <FormDateTime
                        type="date"
                        label={t('documents:ped')}
                        name="end_date"
                        value={formState.end_date}
                        handleChange={handleChange}
                        nextDate={formState.start_date}
                    />
                    {/*<SelectForm*/}
                    {/*    label={t('columns:parent')}*/}
                    {/*    value={formState.parent_item_collection}*/}
                    {/*    handleChange={handleChange}*/}
                    {/*    data={parents}*/}
                    {/*    name="parent_item_collection"*/}
                    {/*    propToRender="name"*/}
                    {/*/>*/}
                </Grid>
                <Grid container item xs={12} sm={12} md={8} lg={8} xl={8} spacing={4} display={'flex'} justifyContent={'center'}>
                    <Grid item mt={3}>
                        <LoadingButton
                            loading={loadingButton}
                            variant="contained"
                            size="large"
                            color="blue"
                            onClick={handleAddCollection}
                            disabled={!formState.collection_name}
                        >
                            {t('common:save')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </MainCard>
        </Box>
    );
};

export default ItemCollectionForm;
