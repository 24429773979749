import React, { useState } from 'react';
import { InputLabel, Grid, Typography, Paper, Box } from '@mui/material';
import { useStyles } from 'views/utilities/Style';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { DropzoneArea } from 'react-mui-dropzone';
import MyFileLink from './MyFileLink';
import { useTranslation } from 'react-i18next';

const InputFileField = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const [openDropZone, setOpenDropZone] = useState(false);
    const { handleChange, label, name, error, required, sx, fullwidth, helper, pathimage } = props;
    return (
        <Grid
            container
            item
            xs={!fullwidth && 12}
            sm={!fullwidth && 12}
            md={!fullwidth && 8}
            lg={!fullwidth && 8}
            xl={!fullwidth && 8}
            spacing={4}
            display={'flex'}
            alignItems={'center'}
            sx={sx}
        >
            <Grid item xs={4}></Grid>
            <Grid item xs={8}>
                <Box width={'100%'} sx={{ padding: theme.spacing(2) }}>
                    {pathimage?.map((file, i) => (
                        <>{file && <MyFileLink key={i} file={file} />}</>
                    ))}
                    {openDropZone && (
                        <DropzoneArea
                            filesLimit={1}
                            dropzoneText="Déposer les fichiers ici"
                            name={name}
                            maxFileSize={5000000}
                            onChange={handleChange}
                        />
                    )}
                </Box>
            </Grid>
            <Grid item xs={4}>
                <InputLabel error={error ?? error} required={required} className={classes.inputLabel}>
                    {label}
                </InputLabel>
            </Grid>
            <Grid item xs={8}>
                <Paper
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        '&:hover': {
                            backgroundColor: theme.palette.action.hover
                        },
                        padding: theme.spacing(2),
                        marginBottom: theme.spacing(1)
                    }}
                    onClick={() => setOpenDropZone(!openDropZone)}
                >
                    <AttachmentIcon sx={{ marginRight: 2, width: 34, height: 34, objectFit: 'cover' }} />
                    <Typography variant="subtitle1">{t('common:attachFile')}</Typography>
                </Paper>

                {helper && <Typography sx={{ fontSize: 14 }}>{helper}</Typography>}
            </Grid>
        </Grid>
    );
};

export default InputFileField;
