{
    /* eslint-disable */
}
import { Button, Grid, Modal, Typography, TextField, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DataRender from 'ui-component/tables/DataRender';
import useFetch from 'hooks/useFetch';
import { LoadingButton } from '@mui/lab';
import MainCard from 'ui-component/cards/MainCard';
import { modalStyle } from 'views/utilities/ModalStyle';
import { useDispatch } from 'react-redux';
import { SetNotification } from 'store/services/api';
import { formatErpResponse } from 'utils/utils';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'views/utilities/Style';
import useGetAxios from 'hooks/useGetAxios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InputTextField from 'ui-component/form/InputTextField';
import Loader from 'ui-component/Loader';
import SettingsIcon from '@mui/icons-material/Settings';
import InputCheckboxField from 'ui-component/form/InputCheckboxField';
import SelectForm from 'ui-component/form/SelectForm';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    border: 'none'
};
const SupplierCodeSettings = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loadingButton, setLoadingButton] = React.useState(false);

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const {
        data: doc,
        isPending,
        refetch: refetchDoc,
        docinfo
    } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Supplier%20Code%20Settings&name=Supplier%20Code%20Setting`);

    const suppliersReqData = {
        doctype: 'Supplier',
        fields: ['`tabSupplier`.`name`']
    };
    const { data: suppliers, isPending: isPendingSuppliers } = useFetch(`/api/get-list`, suppliersReqData, 'POST');

    console.log('suppliers', suppliers);

    const supplierReqData = {
        doctype: 'Supplier',
        fields: ['`tabSupplier`.`name`', '`tabSupplier`.`supplier_name`', '`tabSupplier`.`supplier_code`'],
        start: 0,
        page_length: 1,
        order_by: '`tabSupplier`.`creation` desc'
    };
    const { data: lastsupplier, isPending: isPendingLastSupplier } = useFetch(`/api/get-list`, supplierReqData, 'POST');

    let lastPart = null;
    if (lastsupplier && lastsupplier.length > 0) {
        const supplierCode = lastsupplier[0].supplier_code;
        if (supplierCode && typeof supplierCode === 'string') {
            const parts = supplierCode.split('-');
            lastPart = parseInt(parts[parts.length - 1], 10);
        } else {
            console.warn('Invalid or missing supplier_code in lastsupplier[0]');
        }
    } else {
        console.warn('No lastsupplier found or lastsupplier is empty');
    }

    //console.log('lastPart', lastPart);

    const [state, setState] = React.useState({
        prefix: '',
        length: '',
        automatic: 0,
        chrono_length: '',
        last_chrono_used: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'chrono_length') {
            const maxChronoLength = state.length - state.prefix.length;
            const newValue = Math.min(parseInt(value) || 0, maxChronoLength);
            setState((prevState) => ({ ...prevState, [name]: newValue.toString() }));
        } else {
            setState((prevState) => ({ ...prevState, [name]: value }));
        }
    };

    const calculateMaxChronoLength = () => {
        return Math.max(0, state.length - state.prefix.length);
    };

    const handleChangeCheckbox = (event, value) => {
        setState({ ...state, [event.target.name]: value ? 1 : 0 });
    };

    useEffect(() => {
        if (doc) {
            setState({
                prefix: doc.prefix,
                length: doc.length,
                automatic: doc.automatic,
                chrono_length: doc.chrono_length,
                last_chrono_used: lastPart
            });
        }
    }, [doc]);

    const handleSubmit = () => {
        if (parseInt(state.chrono_length) > calculateMaxChronoLength()) {
            dispatch(SetNotification({ code: 'error', message: t('common:exceeds_maximum') }));
            return;
        }
        const data = {
            ...doc,
            doctype: 'Supplier Code Settings',
            prefix: state.prefix,
            length: state.length,
            chrono_length: state.chrono_length,
            automatic: state.automatic,
            last_chrono_used: state.last_chrono_used
        };
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: data, action: 'Save' })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'error', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: t('common:settings_updated') }));
                    refetchDoc({});
                }
            });
    };

    if (isPending) return <Loader />;

    return (
        <>
            <MainCard
                border
                isWhite
                boxShadow
                divider
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        <SettingsIcon sx={{ mr: 1 }} />
                        {t('common:Supplier_Code_settings')}
                    </Box>
                }
                sx={{ height: '70%', mt: 2 }}
            >
                <Box display="flex" flexWrap="wrap">
                    <Box sx={{ width: '50%', pr: 2 }}>
                        <InputCheckboxField
                            fullwidth
                            label={t('common:Supplier_Code_generation')}
                            name="automatic"
                            value={state.automatic === 1 ? true : false}
                            sx={{ mb: 2 }}
                            handleChange={handleChangeCheckbox}
                        />
                        {state.automatic === 1 && (
                            <>
                                <InputTextField
                                    fullwidth
                                    label={t('core:settingprefix')}
                                    name="prefix"
                                    value={state.prefix}
                                    sx={{ mb: 2 }}
                                    handleChange={handleChange}
                                />
                                <InputTextField
                                    fullwidth
                                    label={t('core:settinglength')}
                                    name="length"
                                    value={state.length}
                                    sx={{ mb: 2 }}
                                    handleChange={handleChange}
                                />
                                <InputTextField
                                    fullwidth
                                    label={t('core:settingchronolength')}
                                    name="chrono_length"
                                    value={state.chrono_length}
                                    sx={{ mb: 2 }}
                                    handleChange={handleChange}
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        max: calculateMaxChronoLength()
                                    }}
                                    helperText={`Max allowed: ${calculateMaxChronoLength()}`}
                                />
                                <InputTextField
                                    fullwidth
                                    label={t('common:last_chrono_used')}
                                    name="last_chrono_used"
                                    value={lastPart}
                                    sx={{ mb: 2 }}
                                    handleChange={handleChange}
                                    disabled
                                />
                            </>
                        )}
                    </Box>
                </Box>
            </MainCard>
            <Box display={'flex'} justifyContent={'right'} mt={3}>
                <LoadingButton variant="contained" size="large" color="blue" onClick={handleSubmit} loading={loadingButton}>
                    {t('settings:text-50')}
                </LoadingButton>
            </Box>
        </>
    );
};

export default SupplierCodeSettings;
