import React, { useState } from 'react';
import { TextField, Box } from '@mui/material';
import { useStyles } from 'views/utilities/Style';
import { modalStyle } from 'views/utilities/ModalStyle';
import { LoadingButton } from '@mui/lab';
import MainCard from 'ui-component/cards/MainCard';
import { header, formatErpResponse } from 'utils/utils';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { SetNotification } from 'store/services/api';

const RenameForm = (props) => {
    const { state, setState, list, message_success, title, handleClose } = props;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loadingButton, setLoadingButton] = useState(false);
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };
    const handleRename = async () => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/rename-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(state)
        })
            .then((res) => {
                setLoadingButton(false);
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ message: message_success /*  */, code: 'success' }));
                    navigate(list);
                    handleClose();
                }
            });
    };
    return (
        <Box sx={modalStyle}>
            <MainCard title={title} divider>
                <TextField
                    name="new_name"
                    value={state.new_name}
                    onChange={handleChange}
                    required
                    sx={{ width: '100%' }}
                    label={'Nouveau nom'}
                    id="outlined-size-small"
                />
                {/*   <FormControlLabel
                    sx={{ mt: 2 }}
                    label="Fusionner avec l'existant (cette opération ne peut être annulée)."
                    control={<Checkbox checked={false} />}
                /> */}
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <LoadingButton
                        loading={loadingButton}
                        disabled={state.new_name == '' || state.new_name == state.docname}
                        onClick={handleRename}
                        variant="contained"
                        color="blue"
                        size="large"
                    >
                        Renommer
                    </LoadingButton>
                </Box>
            </MainCard>
        </Box>
    );
};

export default RenameForm;
