import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Button, ClickAwayListener, Grid, Modal, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import useFetch from 'hooks/useFetch';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { SetBulkEditFields, SetBulkEditIds, SetNotification } from 'store/services/api';
import MainCard from 'ui-component/cards/MainCard';
import { formatErpResponse } from 'utils/utils';
import { modalStyle } from 'views/utilities/ModalStyle';
import DataRender from 'ui-component/tables/DataRender';
import { useTranslation } from 'react-i18next';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid } from '@mui/x-data-grid';
import { frLocal } from '../../utils/dataGridFRLocal';

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)'
        }
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff'
            }
        }
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200
        })
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box'
    }
}));

const UOMList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [selectionModel, setSelectionModel] = useState([]);

    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openEditBulk, setOpenEditBulk] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [setLoadingButton] = useState(false);
    const [loadingBulkEdit, setLoadingBulkEdit] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleEditClose = () => {
        setOpenEdit(false);
        setSelectionModel([]);
    };
    const handleDeleteClose = () => {
        setOpenDelete(false);
        setSelectionModel([]);
    };
    const handleBulkEditClose = () => {
        setOpenEditBulk(false);
        dispatch(SetBulkEditFields([]));
    };
    const [disabled, setDisabled] = useState(1);

    const uomReqData = {
        doctype: 'UOM',
        fields: ['`tabUOM`.`name`', '`tabUOM`.`uom_name`', '`tabUOM`.`enabled`', '`tabUOM`.`modified`', '`tabUOM`.`uom_famille`'],
        order_by: '`tabUOM`.`modified` desc',
        start: 0,
        page_length: 500
    };

    const { data, isPending, refetch } = useFetch(`/api/get-list`, uomReqData, 'POST');

    const handleEdit = (row) => {
        navigate(`/pim/uom/${row}`);
    };

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };
    const [selectionFieldsModel, setSelectionFieldsModel] = useState([]);

    const columns = [
        { field: 'id', headerName: 'ID', flex: 1, visible: false },
        {
            field: 'fieldName',
            headerName: t('common:fieldName'),
            flex: 1
        }
    ];

    const rows = [
        { id: 'uom_name', fieldName: t('common:uom-name') },
        { id: 'enabled', fieldName: t('columns:status') },
        { id: 'uom_famille', fieldName: t('common:uom-family') }
    ];
    const handleBulkEdit = () => {
        dispatch(SetBulkEditIds([]));
        dispatch(SetBulkEditIds(selectionModel));

        dispatch(SetBulkEditFields([]));
        dispatch(SetBulkEditFields(selectionFieldsModel));

        navigate('/pim/uom-bulk-edit');
    };
    const fieldSelection = (
        <Box display={'grid'} sx={{ placeItems: 'center' }}>
            <ClickAwayListener onClickAway={() => handleBulkEditClose()}>
                <Box width={500} mt={'5%'}>
                    <MainCard divider title={t('common:editer-en-masse')}>
                        <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                            {t('common:select-label-edit')}
                        </Typography>
                        <Box height={400} width={'100%'} mt={3}>
                            <DataGrid
                                initialState={{
                                    columns: {
                                        columnVisibilityModel: {
                                            id: false
                                        }
                                    }
                                }}
                                rows={rows}
                                columns={columns}
                                checkboxSelection
                                getRowId={(row) => row.id}
                                localeText={frLocal}
                                hideFooterPagination
                                hideFooterSelectedRowCount
                                rowSelectionModel={selectionFieldsModel}
                                onRowSelectionModelChange={(newSelectionModel) => {
                                    setSelectionFieldsModel(newSelectionModel);
                                }}
                            />
                        </Box>
                        <Box display={'flex'} justifyContent={'flex-end'} mt={0}>
                            <Box>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <Button variant="outlined" color="blue" size="large" onClick={handleBulkEditClose}>
                                            {t('common:cancel')}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button color="blue" size="large" onClick={() => handleBulkEdit()} variant="contained">
                                            {t('common:contine')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </MainCard>
                </Box>
            </ClickAwayListener>
        </Box>
    );

    const handleBulkChangeStatus = () => {
        const editUomStatus = {
            doctype: 'UOM',
            freeze: true,
            docnames: selectionModel,
            action: 'update',
            data: {
                disabled: disabled
            }
        };
        setLoadingBulkEdit(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/update-status`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(editUomStatus)
        })
            .then((res) => {
                setLoadingBulkEdit(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                    throw Error('Something went wrong');
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ message: 'Le statut du UOMs a été modifié avec succès.', code: 'success' }));
                    handleEditClose();
                    refetch({});
                    setDisabled(1);
                }
            });
    };

    const handleDelete = (row) => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'UOM', name: row[0] })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: t('common:UOMmsgDelete') }));
                    refetch({});
                    handleClose();
                }
            });
    };
    const handleBulkDelete = () => {
        const rows = data?.map((obj) => obj.name);

        fetch(`${process.env.REACT_APP_API_URI}/api/delete-items`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'UOM', items: rows })
        })
            .then((res) => {
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'Les UOMs ont été supprimés avec succès' }));
                    handleDeleteClose();
                    setTimeout(() => {
                        refetch({});
                    }, 2000);
                }
            });
    };

    const changeStatusConfirm = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('core:confirm')}>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    {t('core:changeStatus')} <strong>{selectionModel.join(', ')}</strong> ?
                </Typography>
                <Box display={'flex'} justifyContent={'center'} mt={3}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography>{t('core:deactivated')}</Typography>
                        <AntSwitch
                            onClick={() => setDisabled(disabled === 1 ? 0 : 1)}
                            name="disabled"
                            inputProps={{ 'aria-label': 'ant design' }}
                        />
                        <Typography>{t('core:activated')}</Typography>
                    </Stack>
                </Box>
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item>
                                <LoadingButton
                                    color="blue"
                                    size="large"
                                    onClick={() => handleBulkChangeStatus()}
                                    variant="contained"
                                    loading={loadingBulkEdit}
                                >
                                    {t('users:yes')}
                                </LoadingButton>
                            </Grid>
                            <Grid item>
                                <Button color="blue" size="large" variant="outlined" onClick={handleEditClose}>
                                    {t('users:no')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </MainCard>
        </Box>
    );
    const BulkDeleteConfirm = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('core:confirm')}>
                <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
                    {t('Delete')} <strong>{selectionModel.join(', ')}</strong> ?
                </Typography>
                <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button color="blue" size="large" variant="outlined" onClick={handleDeleteClose}>
                                    {t('users:no')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    color="blue"
                                    size="large"
                                    onClick={() => handleBulkDelete()}
                                    variant="contained"
                                    loading={loadingBulkEdit}
                                >
                                    {t('users:yes')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </MainCard>
        </Box>
    );

    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            {t('common:uoms')}
            <Box>
                <Grid container spacing={1}>
                    <Grid item>
                        <Button
                            startIcon={<AddCircleIcon />}
                            variant="outlined"
                            color="blue"
                            onClick={() => navigate('/pim/uom')}
                            sx={{ marginTop: -3 }}
                        >
                            {t('common:uom-add')}
                        </Button>
                    </Grid>
                    {Array.isArray(selectionModel) && selectionModel?.length !== 0 && (
                        <Grid item>
                            <Button
                                startIcon={<EditIcon />}
                                variant="outlined"
                                color="blue"
                                sx={{ marginTop: -3, marginLeft: 1 }}
                                spacing={1}
                                onClick={() => setOpenEditBulk(true)}
                            >
                                {t('core:edit')}
                            </Button>
                            <Button
                                startIcon={<DeleteIcon color="error" />}
                                variant="outlined"
                                color="error"
                                sx={{ marginTop: -3, marginLeft: 1 }}
                                onClick={() => setOpenDelete(true)}
                            >
                                {t('common:delete')}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Box>
    );

    return (
        <div>
            <DataRender
                refetch={refetch}
                data={data}
                tag={'common:uom-name'}
                loading={isPending}
                selectionModel={selectionModel}
                title={title}
                setSelectionModel={setSelectionModel}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                // tag={t('common:code')}
                hasCheckBoxes
            />
            <Modal onClose={handleEditClose} open={openEdit}>
                {changeStatusConfirm}
            </Modal>
            <Modal onClose={handleDeleteClose} open={openDelete}>
                {BulkDeleteConfirm}
            </Modal>
            <Modal onClose={handleBulkEditClose} open={openEditBulk}>
                {fieldSelection}
            </Modal>
        </div>
    );
};
export default UOMList;
