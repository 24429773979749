import { useStyles } from '../../utilities/Style';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import useGetAxios from '../../../hooks/useGetAxios';
import useFetch from '../../../hooks/useFetch';
import { SetNotification } from '../../../store/services/api';
import { formatErpResponse } from '../../../utils/utils';
import { Box } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Loader from '../../../ui-component/Loader';
import MainCard from '../../../ui-component/cards/MainCard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import SelectForm from '../../../ui-component/form/SelectForm';
import BarcodesForm from '../productManagement/components/components/BarcodesForm';
import FormDateTime from '../../../ui-component/dateTime/FormDateTime';
import { LoadingButton } from '@mui/lab';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, Grid } from '@mui/material';

const ProductFinancierEdit = () => {
    const classes = useStyles();
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const [disabled, setDisabled] = useState(false);
    const [libelle, setLibelle] = useState('');
    const [commentaire, setCommentaire] = useState('');
    const [division, setDivision] = useState('');
    const [typeProduct, setTypeProduct] = useState('');
    const [rattachement, setRattachement] = useState('');
    const [typeArticle, setTypeArticle] = useState('');
    const [typeUtilisation, setTypeUtilisation] = useState('');
    const [typeDemandeCredit, setTypeDemandeCredit] = useState('');
    const [familleComptable, setFamilleComptable] = useState('');
    const [journalComptable, setJournalComptable] = useState('');
    const [journalComptableList, setJournalComptableList] = useState([]);
    const [compteComptable, setCompteComptable] = useState('');
    const [attribution, setAttribution] = useState('');
    const [modePaiement, setModePaiement] = useState([]);
    const [loadingAccount, setLoadingAccount] = useState(false);
    const [typefamilleComptable, setTypeFamilleComptable] = useState('');
    const [familleComptableList, setFamilleComptableList] = useState([]);
    const [state, setState] = useState({
        modePaiementAssocie: []
    });
    const globalDefaults = useSelector((state) => state.login.globalDefaults);
    const [prix, setPrix] = useState('');
    const [barcodesState, setBarcodesState] = useState([]);
    const [dateDebut, setDateDebut] = useState(new Date());
    const [dateFin, setDateFin] = useState(new Date());
    const [modePaiementAssocie, setModePaiementAssocie] = useState('');
    const [checkboxState, setCheckboxState] = useState({
        utilisation: false,
        fidelite: false,
        indices: false,
        quantites: false,
        tpe: false,
        remisable: false,
        imprimeFiscale: false,
        preEnregistre: false
    });

    const handleCheckboxChange = (name) => (event) => {
        setCheckboxState({ ...checkboxState, [name]: event.target.checked });
    };

    const { data: doc, isPending } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Item%20Financier&name=${id}`);

    const barcodesettingsReqData = {
        doctype: 'Barcode Settings',
        fields: [
            '`tabBarcode Settings`.`name`',
            '`tabBarcode Settings`.`barcode_type`',
            '`tabBarcode Settings`.`suffix`',
            '`tabBarcode Settings`.`automatic`'
        ]
    };
    const { data: barcodesettings, isPending: isPendingBarcodeSettings } = useFetch(`/api/get-list`, barcodesettingsReqData, 'POST');

    let globalAutomatic;
    let barcodeTypeOptions;

    if (barcodesettings) {
        barcodesettings.forEach((setting) => {
            const name = setting.name;
            const barcode_type = setting.barcode_type;
            const suffix = setting.suffix;
            const automatic = setting.automatic;

            globalAutomatic = automatic;
            barcodeTypeOptions = barcode_type;
        });
    }

    const { data: modePaiementAssocieList } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Mode of Payment',
            fields: ['`tabMode of Payment`.`name`'],
            filters: [['Mode of Payment', 'mode_of_payment_for_item_financier', '=', 1]]
        },
        'POST'
    );

    const handleListAccount = () => {
        setLoadingAccount(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({
                doctype: 'Account',
                fields: [
                    '`tabAccount`.`name`',
                    '`tabAccount`.`owner`',
                    '`tabAccount`.`creation`',
                    '`tabAccount`.`modified`',
                    '`tabAccount`.`modified_by`',
                    '`tabAccount`.`_user_tags`',
                    '`tabAccount`.`_comments`',
                    '`tabAccount`.`_assign`',
                    '`tabAccount`.`_liked_by`',
                    '`tabAccount`.`docstatus`',
                    '`tabAccount`.`parent`',
                    '`tabAccount`.`parenttype`',
                    '`tabAccount`.`parentfield`',
                    '`tabAccount`.`idx`',
                    '`tabAccount`.`account_name`',
                    '`tabAccount`.`account_number`',
                    '`tabAccount`.`disabled`'
                ],
                filters: [
                    ['Account', 'company', '=', globalDefaults?.default_company],
                    ['Account', 'root_type', '=', typefamilleComptable]
                ]
            })
        })
            .then((res) => res.json())
            .then((data) => {
                setFamilleComptableList(data);
                console.log('familleComptableList', data);
                setLoadingAccount(false);
            });
    };
    const handleListJournal = () => {
        setLoadingAccount(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({
                doctype: 'Journal Entry',
                fields: [
                    '`tabJournal Entry`.`name`',
                    '`tabJournal Entry`.`owner`',
                    '`tabJournal Entry`.`creation`',
                    '`tabJournal Entry`.`modified`',
                    '`tabJournal Entry`.`modified_by`',
                    '`tabJournal Entry`.`_user_tags`',
                    '`tabJournal Entry`.`_comments`',
                    '`tabJournal Entry`.`_assign`',
                    '`tabJournal Entry`.`_liked_by`',
                    '`tabJournal Entry`.`docstatus`',
                    '`tabJournal Entry`.`parent`',
                    '`tabJournal Entry`.`parenttype`',
                    '`tabJournal Entry`.`parentfield`',
                    '`tabJournal Entry`.`idx`',
                    '`tabJournal Entry`.`cheque_no`',
                    '`tabJournal Entry`.`total_debit`',
                    '`tabJournal Entry`.`total_amount`',
                    '`tabJournal Entry`.`title`',
                    '`tabJournal Entry`.`voucher_type`',
                    '`tabJournal Entry`.`posting_date`',
                    '`tabJournal Entry`.`company`',
                    '`tabJournal Entry`.`user_remark`',
                    '`tabJournal Entry`.`total_amount_currency`'
                ],
                filters: [['Journal Entry', 'company', '=', globalDefaults?.default_company]]
            })
        })
            .then((res) => res.json())
            .then((data) => {
                setJournalComptableList(data);

                setLoadingAccount(false);
            });
    };
    useEffect(() => {
        handleListAccount();
        handleListJournal();
    }, [typefamilleComptable]);
    const { data: divisionList } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Warehouse',
            fields: ['`tabWarehouse`.`name`']
        },
        'POST'
    );

    useEffect(() => {
        if (doc) {
            setDisabled(doc.disabled === 1 ? true : false);
            setLibelle(doc.libelle);
            setCommentaire(doc.commentaire);
            setDivision(doc.division_commerciale);
            setTypeProduct(doc.type);
            setRattachement(doc.rattachement_à_un_client);
            setTypeArticle(doc.type_article_financier);
            setTypeUtilisation(doc.type_d_utilisation);
            setTypeDemandeCredit(doc.type_de_demande_de_crédit);
            setFamilleComptable(doc.famille_comptable);
            setJournalComptable(doc.journal_comptable);
            setCompteComptable(doc.compte_comptable);
            setAttribution(doc.attribution_du_numéro_de_bon);
            setPrix(doc.prix);
            setModePaiementAssocie(doc.mode_de_paiement_associé);

            setTypeFamilleComptable(doc.type_famille_comptable);

            setBarcodesState(doc?.barcodes);

            setDateDebut(doc.date_de_début);
            setDateFin(doc.jusquau);
            setCheckboxState({
                utilisation: doc.utilisation_avec_d_autre_article === 1 ? true : false,
                fidelite: doc.utilisable_pour_la_fidélité === 1 ? true : false,
                indices: doc.compté_dans_les_indices === 1 ? true : false,
                quantites: doc.compté_dans_le_cumul_des_quantités === 1 ? true : false,
                tpe: doc.envoi_du_montant_au_tpe === 1 ? true : false,
                remisable: doc.remisable_en_ligne === 1 ? true : false,
                imprimeFiscale: doc.ignoré_par_mimprimante_fiscale === 1 ? true : false,
                preEnregistre: doc.pré_enregistré === 1 ? true : false
            });
        }
    }, [doc]);

    const { data: customer } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Customer',
            fields: ['`tabCustomer`.`customer_name`']
        },
        'POST'
    );

    const [loadingButton, setLoadingButton] = useState(false);

    const handleAddFinancier = async () => {
        setLoadingButton(true);
        const data = {
            doc: {
                creation: doc.creation,
                modified: doc.modified,
                owner: doc.owner,
                modified_by: doc.owner,
                name: doc.name,
                idx: 0,
                docstatus: 0,
                disabled: disabled,
                type: typeProduct,
                code: doc.code,
                libelle: libelle,
                commentaire: commentaire,
                rattachement_à_un_client: rattachement,
                type_article_financier: typeArticle,
                type_d_utilisation: typeUtilisation,
                division_commerciale: division,
                type_famille_comptable: typefamilleComptable,
                type_de_demande_de_crédit: typeDemandeCredit,
                journal_comptable: journalComptable,
                utilisation_avec_d_autre_article: checkboxState.utilisation,
                utilisable_pour_la_fidélité: checkboxState.fidelite,
                compté_dans_les_indices: checkboxState.indices,
                compté_dans_le_cumul_des_quantités: checkboxState.quantites,
                envoi_du_montant_au_tpe: checkboxState.tpe,
                remisable_en_ligne: checkboxState.remisable,
                ignoré_par_mimprimante_fiscale: checkboxState.imprimeFiscale,
                pré_enregistré: checkboxState.preEnregistre,
                famille_comptable: familleComptable,
                compte_comptable: compteComptable,
                mode_de_paiement_associé: modePaiementAssocie,
                date_de_début: dateDebut,
                jusquau: dateFin,
                doctype: 'Item Financier',
                __unsaved: 1,
                barcodes: doc.barcodes
            },
            action: 'Save'
        };

        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'Produit financier a été modifié avec succès' }));
                    navigate('/pim/products-financier');
                }
            });
    };

    const title = (
        <Box p={-1} display={'flex'} flexDirection={'column'}>
            <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
            {t('columns:Produit_financier')}: {doc?.name}
        </Box>
    );

    if (id && isPending) return <Loader />;

    return (
        <Box>
            <MainCard title={title}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                        {t('columns:Caractéristiques')}
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormControlLabel
                            control={<Checkbox checked={disabled} onChange={(e) => setDisabled(e.target.checked)} />}
                            label={t('core:disable')}
                        />
                        <Grid container spacing={2} style={{ marginTop: '20px' }}>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label={t('columns:libelle')}
                                    required
                                    fullWidth
                                    onChange={(e) => setLibelle(e.target.value)}
                                    maxRows={4}
                                    value={libelle}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    id="outlined-multiline-static"
                                    label={t('columns:commentaire')}
                                    multiline
                                    fullWidth
                                    onChange={(e) => setCommentaire(e.target.value)}
                                    rows={4}
                                    value={commentaire}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={checkboxState.utilisation} onChange={handleCheckboxChange('utilisation')} />
                                    }
                                    label={t('columns:utilisation')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControlLabel
                                    control={<Checkbox checked={checkboxState.fidelite} onChange={handleCheckboxChange('fidelite')} />}
                                    label={t('columns:fidelite')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControlLabel
                                    control={<Checkbox checked={checkboxState.indices} onChange={handleCheckboxChange('indices')} />}
                                    label={t('columns:indices')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControlLabel
                                    control={<Checkbox checked={checkboxState.quantites} onChange={handleCheckboxChange('quantites')} />}
                                    label={t('columns:quantites')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControlLabel
                                    control={<Checkbox checked={checkboxState.tpe} onChange={handleCheckboxChange('tpe')} />}
                                    label={t('columns:tpe')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControlLabel
                                    control={<Checkbox checked={checkboxState.remisable} onChange={handleCheckboxChange('remisable')} />}
                                    label={t('columns:remisable')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkboxState.imprimeFiscale}
                                            onChange={handleCheckboxChange('imprimeFiscale')}
                                        />
                                    }
                                    label={t('columns:imprimeFiscale')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={checkboxState.preEnregistre} onChange={handleCheckboxChange('preEnregistre')} />
                                    }
                                    label={t('columns:preEnregistre')}
                                />
                            </Grid>
                            {checkboxState.preEnregistre && (
                                <Grid item xs={12} sm={6} md={6} marginLeft={50}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label={t('columns:price')}
                                        fullWidth
                                        value={prix}
                                        onChange={(e) => {
                                            const value = e.target.value.trim();
                                            setPrix(value);
                                        }}
                                        maxRows={4}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12} sm={6} md={5}>
                                <SelectForm
                                    label={t('columns:division')}
                                    name="type"
                                    value={division}
                                    handleChange={(e) => setDivision(e.target.value)}
                                    data={divisionList}
                                    propToRender={'name'}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={5}>
                                <SelectForm
                                    label={t('columns:typeProduct')}
                                    name="type"
                                    value={typeProduct}
                                    handleChange={(e) => setTypeProduct(e.target.value)}
                                    data={['Carte cadeau', 'Bon d’achat', 'Avoir (crédit note)', 'A compte (Déposit)']}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={5}>
                                <SelectForm
                                    label={t('columns:rattachement')}
                                    name="type"
                                    value={rattachement}
                                    handleChange={(e) => setRattachement(e.target.value)}
                                    data={['facultatif', 'interdit', 'obligatoire']}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={5}>
                                <SelectForm
                                    label={t('columns:typeArticle')}
                                    name="type"
                                    value={typeArticle}
                                    handleChange={(e) => setTypeArticle(e.target.value)}
                                    data={[
                                        'Acquisition d’une carte cadeau',
                                        'Acquisition Bon d’achat',
                                        'Acquisition Bon d’achat de fidélité'
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={5}>
                                <SelectForm
                                    label={t('columns:typeUtilisation')}
                                    name="type"
                                    value={typeUtilisation}
                                    handleChange={(e) => setTypeUtilisation(e.target.value)}
                                    data={['mode de paiement']}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={5}>
                                <SelectForm
                                    label={t('columns:typeDemandeCredit')}
                                    name="type"
                                    value={typeDemandeCredit}
                                    handleChange={(e) => setTypeDemandeCredit(e.target.value)}
                                    data={['activation', 'émission', 'rechargement']}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <BarcodesForm
                                    barcodesState={barcodesState}
                                    setBarcodesState={setBarcodesState}
                                    automatic={globalAutomatic}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                        {t('columns:Comptabilisation')}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <SelectForm
                                    label={t('columns:typefamilleComptable')}
                                    name="name"
                                    value={typefamilleComptable}
                                    handleChange={(e) => setTypeFamilleComptable(e.target.value)}
                                    data={['Asset', 'Liability', 'Income', 'Expense', 'Equity']}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <SelectForm
                                    label={t('columns:familleComptable')}
                                    name="name"
                                    value={familleComptable}
                                    handleChange={(e) => setFamilleComptable(e.target.value)}
                                    data={familleComptableList}
                                    propToRender={'name'}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <SelectForm
                                    label={t('columns:journalComptable')}
                                    name="type"
                                    required
                                    value={journalComptable}
                                    handleChange={(e) => setJournalComptable(e.target.value)}
                                    data={journalComptableList}
                                    propToRender={'title'}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <SelectForm
                                    label={t('columns:compteComptable')}
                                    name="type"
                                    value={compteComptable}
                                    handleChange={(e) => setCompteComptable(e.target.value)}
                                    data={['activation', 'émission', 'rechargement']}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                        {t('columns:Réglementsenattente')}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2} style={{ marginTop: '10px' }}>
                            <Grid item xs={12} sm={6} md={4}>
                                <SelectForm
                                    label={t('columns:attribution')}
                                    name="type"
                                    value={attribution}
                                    handleChange={(e) => setAttribution(e.target.value)}
                                    data={['non', 'automatique', 'manuelle', 'depuis un lecteur MSR']}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <SelectForm
                                    label={t('columns:modePaiementAssocie')}
                                    name="name"
                                    value={modePaiementAssocie}
                                    handleChange={(e) => setModePaiementAssocie(e.target.value)}
                                    data={modePaiementAssocieList}
                                    propToRender={'name'}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormDateTime
                                    required
                                    type="date"
                                    label={t('columns:dateDebut')}
                                    name="date"
                                    value={dateDebut}
                                    handleChange={(e) => setDateDebut(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormDateTime
                                    required
                                    type="date"
                                    label={t('columns:dateFin')}
                                    name="date"
                                    value={dateFin}
                                    handleChange={(e) => setDateFin(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Grid container justifyContent="center" spacing={4}>
                    <Grid item mt={3}>
                        <LoadingButton loading={loadingButton} variant="contained" size="large" color="blue" onClick={handleAddFinancier}>
                            {t('common:save')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </MainCard>
        </Box>
    );
};

export default ProductFinancierEdit;
