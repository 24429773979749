import { LoadingButton } from '@mui/lab';
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Typography
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import InputTextField from 'ui-component/form/InputTextField';
import SelectForm from 'ui-component/form/SelectForm';
import { isExistInDoc } from 'utils/utils';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { SetDoc2Duplicate, SetNotification } from 'store/services/api';
import { DropzoneArea } from 'react-mui-dropzone';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import AttachFileTwoToneIcon from '@mui/icons-material/AttachFileTwoTone';
import { useTranslation } from 'react-i18next';
import useFetch from 'hooks/useFetch';
import { itemFields, itemSections } from 'views/products/customizeForm/components/itemFieldsValues';
import ArrowDropDownCircleTwoToneIcon from '@mui/icons-material/ArrowDropDownCircleTwoTone';
import FormDateTime from 'ui-component/dateTime/FormDateTime';
import ReorderForm from './components/ReorderForm';
import BarcodesForm from './components/BarcodesForm';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import SupplierItemsForm from './components/SupplierItemsForm';
import ItemPriceForm from './components/ItemPriceForm';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AddIcon from '@mui/icons-material/Add';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';

const ServiceProdForm = ({
    storedFiles,
    refetchFiles,
    doc,
    companies,
    warehouses,
    brands,
    itemTaxTemplates,
    itemAttributes,
    priceLists,
    isSimple,
    editToggled,
    toPrint,
    uoms,
    suppliers,
    itemCollections,
    isAdd,
    currencyLists,
    sousfamilleLists,
    soustablelibreLists,
    tablelibreLists
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const docToDuplicate = useSelector((state) => state.pim.docToDuplicate);
    const theme = useTheme();
    const [activeTab, setActiveTab] = useState('basic_section');

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const [selectedFields, setSelectedFields] = useState([]);
    const [sections, setSections] = useState([]);

    const [selectedFamille, setSelectedFamille] = useState([]);
    const [selectedSousFamille, setSelectedSousFamille] = useState([null]);
    const uniqueFamilleLists = [...new Set(sousfamilleLists && sousfamilleLists?.map((item) => item.famille))].map((famille) => ({
        famille
    }));

    const handleParentFamilleChange = (event, index) => {
        const selectedValues = event.target.value;
        const updatedSelectedFamille = [...selectedFamille];
        updatedSelectedFamille[index] = selectedValues;
        setSelectedFamille(updatedSelectedFamille);

        const filteredSousFamilles = sousfamilleLists.filter((sousFamille) => selectedValues.includes(sousFamille.famille));
        const updatedSelectedSousFamille = [...selectedSousFamille];
        updatedSelectedSousFamille[index] = filteredSousFamilles[0]?.name || null;
        setSelectedSousFamille(updatedSelectedSousFamille);
    };

    const handleSousFamilleChange = (event, index) => {
        const selectedValue = event.target.value;
        const updatedSelectedSousFamille = [...selectedSousFamille];
        updatedSelectedSousFamille[index] = selectedValue === 'null' ? null : { name: selectedValue, famille: '' };
        setSelectedSousFamille(updatedSelectedSousFamille);
    };

    const handleAddNew = () => {
        setSelectedFamille([...selectedFamille, []]);
        setSelectedSousFamille([...selectedSousFamille, null]);
    };

    const handleRemove = (index) => {
        const updatedSelectedFamille = [...selectedFamille];
        updatedSelectedFamille.splice(index, 1);
        setSelectedFamille(updatedSelectedFamille);

        const updatedSelectedSousFamille = [...selectedSousFamille];
        updatedSelectedSousFamille.splice(index, 1);
        setSelectedSousFamille(updatedSelectedSousFamille);
    };
    const [selectedTableLibre, setSelectedTableLibre] = useState([]);
    const [selectedSousTableLibre, setSelectedSousTableLibre] = useState([]);

    const handleTableLibreChange = (event, index) => {
        const selectedValue = event.target.value;
        const updatedSelectedTableLibre = [...selectedTableLibre];
        updatedSelectedTableLibre[index] = selectedValue;
        setSelectedTableLibre(updatedSelectedTableLibre);

        const filteredSousTableLibres = soustablelibreLists?.filter((sousTableLibre) => sousTableLibre.table_libre === selectedValue);
        const updatedSelectedSousTableLibre = [...selectedSousTableLibre];
        updatedSelectedSousTableLibre[index] = filteredSousTableLibres[0]?.soustable_libre || null;
        setSelectedSousTableLibre(updatedSelectedSousTableLibre);
    };

    const handleSousTableLibreChange = (event, index) => {
        const selectedValue = event.target.value;
        const updatedSelectedSousTableLibre = [...selectedSousTableLibre];
        updatedSelectedSousTableLibre[index] = selectedValue === 'null' ? null : selectedValue;
        setSelectedSousTableLibre(updatedSelectedSousTableLibre);
    };

    const handleAddNewTable = () => {
        setSelectedTableLibre([...selectedTableLibre, '']);
        setSelectedSousTableLibre([...selectedSousTableLibre, null]);
    };

    const handleRemoveTable = (index) => {
        const updatedSelectedTableLibre = [...selectedTableLibre];
        updatedSelectedTableLibre.splice(index, 1);
        setSelectedTableLibre(updatedSelectedTableLibre);

        const updatedSelectedSousTableLibre = [...selectedSousTableLibre];
        updatedSelectedSousTableLibre.splice(index, 1);
        setSelectedSousTableLibre(updatedSelectedSousTableLibre);
    };

    const [selectedFile, setSelectedFile] = useState(null);
    const [filePreview, setFilePreview] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);

        // Generate a preview for the selected image
        const reader = new FileReader();
        reader.onloadend = () => {
            setFilePreview(reader.result);
        };
        reader.readAsDataURL(file);
    };

    useEffect(() => {
        if (!selectedFile && doc && doc?.image) {
            setFilePreview(`${process.env.REACT_APP_IMAGE_URL}${doc.image}`);
        } else if (!selectedFile && !doc?.image) {
            setFilePreview('https://upload.wikimedia.org/wikipedia/fr/4/45/CS_Sfaxien.gif');
        }
    }, [doc, selectedFile]);

    //const filteredFamille = selectedFamille ? sousfamilleLists.filter((group) => group.famille === selectedFamille) : sousfamilleLists;

    const globalDefaults = useSelector((state) => state.login.globalDefaults);

    const itemGroupsReqData = {
        doctype: 'Item Group',
        fields: ['`tabItem Group`.`name`'],
        start: 0,
        page_length: 500
    };

    const { data: itemGroups, isPending } = useFetch(`/api/get-list`, itemGroupsReqData, 'POST');

    const itemPriceReqData = {
        doctype: 'Item Price',
        fields: [
            '`tabItem Price`.`price_list`',
            '`tabItem Price`.`price_list_rate`',
            '`tabItem Price`.`currency`',
            '`tabItem Price`.`buying`',
            '`tabItem Price`.`selling`',
            '`tabItem Price`.`valid_from`',
            '`tabItem Price`.`valid_upto`'
        ],
        filters: [['Item Price', 'item_code', '=', doc?.item_code]],
        start: 0,
        page_length: 20,
        order_by: '`tabItem Price`.`modified` desc'
    };
    const taxcategoryReqData = {
        doctype: 'Tax Category',
        fields: ['`tabTax Category`.`name`'],
        filters: [['Tax Category', 'disabled', '=', 0]]
    };
    const taxetemplateReqData = {
        doctype: 'Item Tax Template',
        fields: ['`tabItem Tax Template`.`name`'],
        filters: [['Item Tax Template', 'disabled', '=', 0]]
    };
    const { data: itemPrice, isPending: isItemPricePending, refetch } = useFetch(`/api/get-list`, itemPriceReqData, 'POST');
    const { data: taxcategory, isPending: isTaxCategoryPending } = useFetch(`/api/get-list`, taxcategoryReqData, 'POST');
    const { data: taxetemplate, isPending: isTaxetemplatePending } = useFetch(`/api/get-list`, taxetemplateReqData, 'POST');
    console.log('taxetemplate', taxetemplate);

    const taxeitemReqData = {
        doctype: 'Item Tax Template Detail',
        fields: [
            '`tabItem Tax Template Detail`.`tax_type`',
            '`tabItem Tax Template Detail`.`tax_rate`',
            '`tabItem Tax Template Detail`.`parent`'
        ],
        order_by: '`tabItem Tax Template Detail`.`creation` ASC'
    };

    const { data: taxeitem, isPending: isTaxeitemPending } = useFetch(`/api/get-list`, taxeitemReqData, 'POST');

    console.log('taxeitem', taxeitem);

    const { data: selectedFieldsInLara, isPending: isFieldsPending } = useFetch(
        `/api/product-fields`,
        { company: globalDefaults.default_company },
        'POST'
    );
    const [itemDefaults, setItemDefaults] = useState(() => ({
        doctype: 'Item Default',
        __islocal: 1,
        __unsaved: 1,
        company: globalDefaults.default_company,
        default_warehouse: '',
        parentfield: 'item_defaults',
        parenttype: 'Item',
        idx: 1,
        __unedited: false,
        default_price_list: ''
    }));

    const barcodesettingsReqData = {
        doctype: 'Barcode Settings',
        fields: [
            '`tabBarcode Settings`.`name`',
            '`tabBarcode Settings`.`barcode_type`',
            '`tabBarcode Settings`.`suffix`',
            '`tabBarcode Settings`.`automatic`'
        ]
    };
    const { data: barcodesettings, isPending: isPendingBarcodeSettings } = useFetch(`/api/get-list`, barcodesettingsReqData, 'POST');

    console.log('settings', barcodesettings);

    let globalAutomatic;
    let barcodeTypeOptions;

    if (barcodesettings) {
        barcodesettings.forEach((setting) => {
            const name = setting.name;
            const barcode_type = setting.barcode_type;
            const suffix = setting.suffix;
            const automatic = setting.automatic;
            console.log('name', name);
            console.log('barcode_type', barcode_type);
            console.log('suffix', suffix);
            console.log('automatic', automatic);

            globalAutomatic = automatic;
            barcodeTypeOptions = barcode_type;
        });
    }

    const [formState, setFormState] = useState({
        // fixed values
        doctype: 'Item',
        __islocal: 1,
        __unsaved: 1,
        disabled: 0,
        allow_alternative_item: 0,
        is_stock_item: 0,
        include_item_in_manufacturing: 1,
        is_fixed_asset: 0,
        auto_create_assets: 0,
        is_grouped_asset: 0,
        end_of_life: '2099-12-31',
        default_material_request_type: 'Purchase',
        valuation_method: '',
        allow_negative_stock: 0,
        has_batch_no: 0,
        create_new_batch: 0,
        has_expiry_date: 0,
        retain_sample: 0,
        has_serial_no: 0,
        min_order_qty: 0,
        is_purchase_item: 1,
        is_customer_provided_item: 0,
        delivered_by_supplier: 0,
        enable_deferred_expense: 0,
        grant_commission: 1,
        is_sales_item: 1,
        enable_deferred_revenue: 0,
        inspection_required_before_purchase: 0,
        inspection_required_before_delivery: 0,
        is_sub_contracted_item: 0,
        published_in_website: 0,
        max_discount: '',
        stock_uom: 'Nos',
        weight_per_unit: '',
        // recently added
        safety_stock: 0,
        lead_time_days: 0,
        last_purchase_rate: 0,
        shelf_life_in_days: 0,
        warranty_period: '',
        sales_uom: '',
        purchase_uom: '',
        // changable values
        variant_based_on: 'Item Attribute',
        create_variant: 0,
        item_template: '',
        has_variants: 1,
        attributes: [],
        item_defaults: [],
        taxes: [],
        description: '',
        brand: '',
        item_code: '',
        item_name: '',
        item_group: 'Services',
        item_collection: '',
        opening_stock: 0,
        standard_rate: 0,
        valuation_rate: 0,
        prix_unitaire_ttc: '',
        prix_achat_ht: '',
        prix_de_vente_ht: '',
        prix_de_revient: 0,
        table_libre: [],
        currency: 'TND',
        sousfamillies: [],
        famillies: [],
        tablelibres: [],
        soustablelibres: [],
        supplier: '',
        item_tax_category: ''
    });

    const [itemPriceState, setItemPriceState] = useState([]);
    const [itemtaxeState, setItemTaxeState] = useState([]);
    const [reorderState, setReorderState] = useState([]);
    const [barcodesState, setBarcodesState] = useState([]);
    const [supplierItemsState, setSupplierItemsState] = useState([]);
    const [tableLibreData, setTableLibreData] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [objectService, setObjectService] = useState([]);
    //const [selecteddItems, setSelecteddItems] = useState([]);

    const handleLibreChange = (event, index) => {
        const selectedLibre = event.target.value;
        const updatedItems = [...selectedItems];
        updatedItems[index] = { ...updatedItems[index], libelle: selectedLibre, table_code: null };
        setSelectedItems(updatedItems);
    };

    const handleTableCodeChange = (event, index) => {
        const selectedTableCode = event.target.value;
        const updatedItems = [...selectedItems];
        updatedItems[index] = { ...updatedItems[index], table_code: selectedTableCode };
        setSelectedItems(updatedItems);
    };

    const handleAddTableLibreItem = () => {
        setSelectedItems([...selectedItems, { libelle: null, table_code: null }]);
    };

    const handleRemoveTableLibreItem = (index) => {
        const updatedItems = [...selectedItems];
        updatedItems.splice(index, 1);
        setSelectedItems(updatedItems);
    };

    const getTableCodeOptions = (libelle) => {
        if (libelle && tablelibreLists) {
            const selectedLibreObject = tablelibreLists.find((table) => table.libelle === libelle);
            if (selectedLibreObject) {
                return [{ table_code: selectedLibreObject.table_code }];
            } else {
                console.log(`No object found in tablelibreLists with libelle ${libelle}`);
            }
        } else {
            console.log('tablelibreLists or libelle is undefined or null');
        }
        return [];
    };

    useEffect(() => {
        const updateSelectedItems = () => {
            if (doc) {
                const updatedItems = [];
                for (let i = 0; i < doc.table_libre.length; i++) {
                    const selectedItem = doc.table_libre[i];
                    updatedItems.push(selectedItem);
                    console.log(`table libre ${i}`, selectedItem);
                }
                setSelectedItems(updatedItems);
            }
        };

        // Call the function to update selected items
        updateSelectedItems();
    }, []);

    //const handleService = (selectedTableLibre) => {
    //const serviceName = selectedTableLibre.target.value;
    // const selectedServiceObject = tablelibreLists.find(table => table.libelle === serviceName);
    //setObjectService(selectedServiceObject);
    //setSelectedItems([...selectedItems, selectedServiceObject]);
    //console.log('selectedItems',selectedItems)
    // };
    //const handleDelete = (index) => {
    //const newSelectedItems = [...selectedItems];
    //newSelectedItems.splice(index, 1);
    //setSelectedItems(newSelectedItems);
    // };
    //const handleAddToSelectedItems = () => {
    // const selectedItem = tableLibre.find(item => item.id === tableLibreData);
    //if (selectedItem) {
    // setSelectedItems([...selectedItems, selectedItem]);
    // }
    // console.log(selectedItem);
    // };
    //const handleRemoveFromSelectedItems = (id) => {
    //const updatedSelectedItems = selectedItems.filter(item => item.id !== id);
    //setSelectedItems(updatedSelectedItems);
    // };

    const [formErrState, setFormErrState] = useState({
        item_code: true,
        item_name: true
        /*item_group: true*/
    });

    const [loadingButton, setLoadingButton] = useState(false);

    const [files, setFiles] = useState([]);

    const [ItemsFiles, setItemsFiles] = useState([]);

    const [openDropZone, setOpenDropZone] = useState(false);
    const [openBarcodes, setOpenBarcodes] = useState(false);
    const [fileURL, setFileURL] = useState('');
    const [firstNonNullTaxPercentage, setFirstNonNullTaxPercentage] = useState(null);

    // handle the change of the form states
    // >>>
    const handleItemDefaultsChange = (e) => {
        setItemDefaults({ ...itemDefaults, [e.target.name]: e.target.value });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        let updatedDoc = { ...formState };

        updatedDoc[name] = value;

        if (name === 'prix_de_vente_ht') {
            const standardRate = parseFloat(value) / (1 + firstNonNullTaxPercentage / 100);
            updatedDoc['standard_rate'] = standardRate.toFixed(2);
        } else if (name === 'standard_rate') {
            const prixDeVenteHT = parseFloat(value) * (1 + firstNonNullTaxPercentage / 100);
            updatedDoc['prix_de_vente_ht'] = prixDeVenteHT.toFixed(2);
        }

        setFormState(updatedDoc);

        // Error handlers
        if (value === '' && ['item_name', 'item_code'].includes(name)) {
            setFormErrState({ ...formErrState, [name]: true });
        } else {
            setFormErrState({ ...formErrState, [name]: false });
        }
    };

    const handleCheckChange = (e) => {
        setFormState({ ...formState, [e.target.name]: e.target.checked ? 1 : 0 });
    };

    const handleSelectChange = (event) => {
        const {
            target: { value }
        } = event;
        if (event.target.name == 'taxe_items') {
            setFormState({
                ...formState,
                taxes: [
                    {
                        doctype: 'Item Tax',
                        __islocal: 1,
                        __unsaved: 1,
                        parentfield: 'taxes',
                        parenttype: 'Item',
                        __unedited: false,
                        itemTaxeState: value
                    }
                ]
            });
        } else {
            setFormState({ ...formState, [event.target.name]: value });
        }
        /*  if (event.target.name === 'item_group') {
            setFormErrState({ ...formErrState, [event.target.name]: false });
        }*/
    };
    const [isChangedAttributes, setIsChangedAttributes] = useState(false);
    const handleAttributesChange = (e) => {
        let row = {
            docstatus: 0,
            doctype: 'Item Variant Attribute',
            __islocal: 1,
            __unsaved: 1,
            parentfield: 'attributes',
            parenttype: 'Item',
            __unedited: false,
            numeric_values: 0,
            from_range: 0,
            to_range: 0,
            increment: 0,
            attribute: e.target.name
        };
        let data = doc && editToggled && !isChangedAttributes ? [...doc?.attributes] : [...formState.attributes];
        setIsChangedAttributes(true);
        const rowIndex = data.findIndex((el) => el.attribute == e.target.name);
        if (rowIndex == -1 && e.target.checked) {
            data.push(row);
        } else if (rowIndex != -1 && !e.target.checked) {
            data = data.filter((el) => el.attribute != e.target.name);
        }
        setFormState({ ...formState, attributes: data });
    };

    const handleFilesChange = (files) => {
        setFiles(files);
    };

    // handle submitting the form & redirection
    // >>>
    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const handleCreate = () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('doctype', 'Item');
            formData.append('docname', doc.name);

            const header = {
                'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
                Authorization: JSON.parse(localStorage.getItem('user'))?.token
            };

            return fetch(`${process.env.REACT_APP_API_URI}/api/add-assets-file`, {
                method: 'POST',
                headers: header,
                body: formData
            })
                .then((res) => {
                    if (!res.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return res.json(); // Parse the response body as JSON
                })
                .then((data) => {
                    handleSave(data.message.file_url);
                    return data.message;
                    // Return the data if needed
                })
                .catch((error) => {
                    console.error('Error:', error);
                    throw error; // Rethrow the error to propagate it
                });
        } else {
            handleSave(null);
        }
    };
    console.log('selectedFile', selectedFile);
    //console.log('Data10', Data.file_url);
    console.log('fileURL', fileURL);

    const handleSave = (URL) => {
        console.log('selectedFamille:', selectedFamille);
        setLoadingButton(true);
        const data = {
            doc: {
                ...formState,
                image: URL ? URL : '',
                item_defaults: [itemDefaults],
                table_libre: selectedItems,
                reorder_levels: reorderState,
                barcodes: barcodesState,
                supplier_items: supplierItemsState,
                taxes: itemtaxeState,
                famillies: selectedFamille.map((famille) => ({
                    docstatus: 0,
                    doctype: 'Item Famille',
                    __islocal: 1,
                    __unsaved: 1,
                    parentfield: 'famillies',
                    parenttype: 'Item',
                    __unedited: false,
                    famille: famille
                })),
                sousfamillies: selectedSousFamille
                    .filter((sf) => sf !== null)
                    .map((sousFamille) => ({
                        docstatus: 0,
                        doctype: 'Item SousFamille',
                        __islocal: 1,
                        __unsaved: 1,
                        parentfield: 'sousfamillies',
                        parenttype: 'Item',
                        __unedited: false,
                        sousfamille: sousFamille.name,
                        famille: sousFamille.famille
                    })),
                tablelibres: selectedTableLibre.map((tableLibre) => ({
                    docstatus: 0,
                    doctype: 'Item Table Libre',
                    __islocal: 1,
                    __unsaved: 1,
                    parentfield: 'tablelibres',
                    parenttype: 'Item',
                    __unedited: false,
                    table_libre: tableLibre
                })),
                soustablelibres: selectedSousTableLibre
                    .filter((sousTableLibre) => sousTableLibre !== null)
                    .map((sousTableLibre, index) => ({
                        docstatus: 0,
                        doctype: 'Item SousTable Libre',
                        __islocal: 1,
                        __unsaved: 1,
                        parentfield: 'soustablelibres',
                        parenttype: 'Item',
                        __unedited: false,
                        soustable_libre: sousTableLibre,
                        table_libre: selectedTableLibre[index]
                    })),

                ...(isSimple &&
                    !editToggled && { has_variants: 0, attributes: [], item_template: null, create_variant: 0, variant_based_on: null })
            },
            action: 'Save'
        };

        console.log('data', data);
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    if (typeof data.message === 'string') {
                        dispatch(SetNotification({ code: 'warning', message: data.message }));
                    } else {
                        dispatch(SetNotification({ code: 'warning', message: 'An error occurred while saving the item.' }));
                    }
                } else {
                    editToggled
                        ? dispatch(SetNotification({ code: 'success', message: t('columns:modif_prod') }))
                        : dispatch(SetNotification({ code: 'success', message: t('columns:the_prod') }));

                    /*upload other product files*/
                    if (files?.length !== 0) {
                        const header = {
                            'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
                            Authorization: JSON.parse(localStorage.getItem('user'))?.token
                        };
                        const formData = new FormData();
                        for (var x = 0; x < files?.length; x++) {
                            formData.append(`file_${x}`, files[x], files[x]?.name);
                        }
                        formData.append('doctype', 'Item');
                        formData.append('name', data.docs[0].name);

                        fetch(`${process.env.REACT_APP_API_URI}/api/upload-file`, {
                            method: 'POST',
                            headers: header,
                            body: formData
                        })
                            .then((res) => {})
                            .then((data) => {
                                navigate('/pim/products-provision');
                            });
                    } else {
                        navigate('/pim/products-provision');
                    }
                }
                dispatch(SetDoc2Duplicate(null));
            });
    };
    useEffect(() => {
        const selectedTaxCategory = doc && !editToggled ? doc.item_tax_category : formState.item_tax_category;

        const taxe = taxeitem.find((item) => item.parent === selectedTaxCategory);

        if (taxe) {
            setFirstNonNullTaxPercentage(parseFloat(taxe.tax_rate));
        } else {
            setFirstNonNullTaxPercentage(null);
        }
    }, [doc, editToggled, formState.item_tax_category, taxeitem]);

    //useEffect(() => {
    //if (firstNonNullTaxPercentage !== null && !isEditing) {
    // let updatedDoc = { ...formState };
    // updatedDoc['standard_rate'] = '';
    // updatedDoc['prix_de_vente_ht'] = '';
    // setFormState(updatedDoc);
    // }
    //}, [firstNonNullTaxPercentage, isEditing]);

    const taxeNames = itemtaxeState ? itemtaxeState.map((taxe) => taxe.item_tax_template) : [];
    console.log('Tax Percentage:', firstNonNullTaxPercentage);
    console.log('itemtaxeState:', itemtaxeState);
    console.log('itemtaxeState.item.template:', taxeNames);

    //const updateItemWithImageUrl = (itemName, imageUrl) => {
    //const data = {
    // doc: {
    // name: itemName,
    //image: imageUrl,
    //},
    //action: 'Save',
    //};

    //fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
    //  method: 'POST',
    //  headers: header,
    // body: JSON.stringify(data),
    // })
    // .then((res) => {
    // if (!res.ok) {
    // throw new Error('Failed to update item with image URL');
    // }
    //return res.json();
    //})
    //.then((data) => {
    //if (data?.success === true) {
    // dispatch(SetNotification({ code: 'success', message: 'Item image uploaded successfully' }));
    // navigate('/pim/products-view');
    //} else {
    //throw new Error('Failed to update item with image URL');
    //}
    //})
    //.catch((err) => {
    // console.error('Error:', err);
    //dispatch(SetNotification({ code: 'error', message: err.message || 'An error occurred' }));
    // });
    //};

    const renderSaveButton = () => {
        if (doc) {
            if (editToggled) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    useEffect(() => {
        if (itemPrice) {
            setItemPriceState(itemPrice);
        }
    }, [itemPrice]);
    console.log('itemPriceState', itemPriceState);
    console.log('setItemPriceState', setItemPriceState);

    useEffect(() => {
        if (editToggled && doc && sousfamilleLists && soustablelibreLists) {
            // set doc values to the form state
            setFormState({
                doctype: 'Item',
                naming_series: doc.naming_series,
                // __islocal: 1,
                __unsaved: 1,
                creation: doc.creation,
                modified: doc.modified,
                owner: doc.owner,
                modified_by: doc.owner,
                name: doc.name,
                sousfamillies: doc.sousfamillies,
                famillies: doc.famillies,
                disabled: doc.disabled,
                external_id: doc.external_id,
                allow_alternative_item: doc.allow_alternative_item,
                is_stock_item: doc.is_stock_item,
                include_item_in_manufacturing: doc.include_item_in_manufacturing,
                is_fixed_asset: doc.is_fixed_asset,
                auto_create_assets: doc.auto_create_assets,
                is_grouped_asset: doc.is_grouped_asset,
                end_of_life: doc.end_of_life,
                default_material_request_type: doc.default_material_request_type,
                valuation_method: doc.valuation_method,
                allow_negative_stock: doc.allow_negative_stock,
                has_batch_no: doc.has_batch_no,
                create_new_batch: doc.create_new_batch,
                has_expiry_date: doc.has_expiry_date,
                retain_sample: doc.retain_sample,
                has_serial_no: doc.has_serial_no,
                min_order_qty: doc.min_order_qty,
                is_purchase_item: doc.is_purchase_item,
                is_customer_provided_item: doc.is_customer_provided_item,
                delivered_by_supplier: doc.delivered_by_supplier,
                enable_deferred_expense: doc.enable_deferred_expense,
                grant_commission: doc.grant_commission,
                is_sales_item: doc.is_sales_item,
                enable_deferred_revenue: doc.enable_deferred_revenue,
                inspection_required_before_purchase: doc.inspection_required_before_purchase,
                inspection_required_before_delivery: doc.inspection_required_before_delivery,
                is_sub_contracted_item: doc.is_sub_contracted_item,
                published_in_website: doc.published_in_website,
                max_discount: doc.max_discount,
                stock_uom: doc.stock_uom,
                uoms: doc.uoms,
                supplier_items: doc.supplier_items,
                reorder_levels: doc.reorder_levels,
                customer_items: doc.customer_items,
                variant_of: doc.variant_of ? doc.variant_of : null,
                weight_per_unit: doc.weight_per_unit,
                // changable values
                variant_based_on: doc.variant_based_on,
                create_variant: doc?.create_variant ? doc?.create_variant : 0,
                item_template: doc?.item_template ? doc?.item_template : '',
                has_variants: doc?.has_variants ? doc?.has_variants : null,
                attributes: doc?.attributes,
                item_defaults: [],
                taxes: doc.taxes,
                description: doc.description || '',
                brand: doc.brand || '',
                item_code: doc.item_code,
                item_name: doc.item_name,
                item_group: doc.item_group,
                item_collection: doc?.item_collection,
                opening_stock: doc.opening_stock,
                standard_rate: doc.standard_rate,
                valuation_rate: doc.valuation_rate,
                prix_unitaire_ttc: doc.prix_unitaire_ttc,
                prix_achat_ht: doc.prix_achat_ht,
                prix_de_vente_ht: doc.prix_de_vente_ht,
                prix_de_revient: doc.prix_de_revient,
                table_libre: doc.table_libre,
                currency: doc.currency,
                supplier: doc.supplier,
                image: doc.image,
                tablelibres: doc.tablelibres,
                soustablelibres: doc.soustablelibres,
                item_tax_category: doc.item_tax_category
            });
            setItemDefaults({
                doctype: 'Item Default',
                __islocal: 1,
                __unsaved: 1,
                company: doc?.item_defaults?.[0]?.company,
                default_warehouse: doc?.item_defaults?.[0]?.default_warehouse,
                parentfield: 'item_defaults',
                parenttype: 'Item',
                idx: 1,
                __unedited: false,
                default_price_list: doc?.item_defaults?.[0]?.default_price_list
            });
            setReorderState(doc?.reorder_levels);
            setBarcodesState(doc?.barcodes);
            setSupplierItemsState(doc?.supplier_items);
            setItemTaxeState(doc?.taxes);
            setSelectedFamille(doc.famillies.map((f) => f.famille));
            setSelectedSousFamille(
                doc.sousfamillies.map((sf) => ({
                    name: sf.sousfamille,
                    famille: sf.famille
                }))
            );
            setSelectedTableLibre(doc.tablelibres.map((tl) => tl.table_libre));
            setSelectedSousTableLibre(doc.soustablelibres.map((stl) => stl.soustable_libre));
        }
    }, [editToggled, doc, sousfamilleLists, soustablelibreLists]);

    useEffect(() => {
        if (docToDuplicate && sousfamilleLists && soustablelibreLists) {
            setFormState({
                // fixed values
                doctype: 'Item',
                __islocal: 1,
                __unsaved: 1,
                sousfamillies: docToDuplicate?.sousfamillies,
                famillies: docToDuplicate?.famillies,
                disabled: docToDuplicate?.disabled,
                allow_alternative_item: docToDuplicate?.allow_alternative_item,
                is_stock_item: docToDuplicate?.is_stock_item,
                include_item_in_manufacturing: docToDuplicate?.include_item_in_manufacturing,
                is_fixed_asset: docToDuplicate?.is_fixed_asset,
                auto_create_assets: docToDuplicate?.auto_create_assets,
                is_grouped_asset: docToDuplicate?.is_grouped_asset,
                end_of_life: docToDuplicate?.end_of_life,
                default_material_request_type: docToDuplicate?.default_material_request_type,
                valuation_method: docToDuplicate?.valuation_method,
                allow_negative_stock: docToDuplicate?.allow_negative_stock,
                has_batch_no: docToDuplicate?.has_batch_no,
                create_new_batch: docToDuplicate?.create_new_batch,
                has_expiry_date: docToDuplicate?.has_expiry_date,
                retain_sample: docToDuplicate?.retain_sample,
                has_serial_no: docToDuplicate?.has_serial_no,
                min_order_qty: docToDuplicate?.min_order_qty,
                is_purchase_item: docToDuplicate?.is_purchase_item,
                is_customer_provided_item: docToDuplicate?.is_customer_provided_item,
                delivered_by_supplier: docToDuplicate?.delivered_by_supplier,
                enable_deferred_expense: docToDuplicate?.enable_deferred_expense,
                grant_commission: docToDuplicate?.grant_commission,
                is_sales_item: docToDuplicate?.is_sales_item,
                enable_deferred_revenue: docToDuplicate?.enable_deferred_revenue,
                inspection_required_before_purchase: docToDuplicate?.inspection_required_before_purchase,
                inspection_required_before_delivery: docToDuplicate?.inspection_required_before_delivery,
                is_sub_contracted_item: docToDuplicate?.is_sub_contracted_item,
                published_in_website: docToDuplicate?.published_in_website,
                max_discount: docToDuplicate?.max_discount,
                stock_uom: docToDuplicate?.stock_uom,
                uoms: docToDuplicate?.uoms,
                weight_per_unit: docToDuplicate?.weight_per_unit,
                // changable values
                variant_based_on: docToDuplicate?.variant_based_on,
                create_variant: docToDuplicate?.create_variant,
                item_template: docToDuplicate?.item_template,
                has_variants: docToDuplicate?.has_variants,
                attributes: docToDuplicate?.attributes,
                item_defaults: [],
                taxes: docToDuplicate?.taxes,
                description: docToDuplicate?.description,
                brand: docToDuplicate?.brand,
                item_code: docToDuplicate?.item_code,
                item_name: docToDuplicate?.item_name,
                item_group: docToDuplicate?.item_group,
                item_collection: docToDuplicate?.item_collection,
                opening_stock: docToDuplicate?.opening_stock,
                standard_rate: docToDuplicate?.standard_rate,
                valuation_rate: docToDuplicate?.valuation_rate,
                prix_unitaire_ttc: docToDuplicate?.prix_unitaire_ttc,
                prix_achat_ht: docToDuplicate?.prix_achat_ht,
                prix_de_vente_ht: docToDuplicate?.prix_de_vente_ht,
                prix_de_revient: docToDuplicate?.prix_de_revient,
                table_libre: docToDuplicate?.table_libre,
                currency: docToDuplicate?.currency,
                supplier: docToDuplicate?.supplier,
                image: docToDuplicate?.image,
                tablelibres: docToDuplicate?.tablelibres,
                soustablelibres: docToDuplicate?.soustablelibres,
                item_tax_category: docToDuplicate?.item_tax_category
            });
            setItemDefaults({
                doctype: 'Item Default',
                __islocal: 1,
                __unsaved: 1,
                company: docToDuplicate?.item_defaults[0].company,
                default_warehouse: docToDuplicate?.item_defaults[0].default_warehouse,
                parentfield: 'item_defaults',
                parenttype: 'Item',
                idx: 1,
                __unedited: false,
                default_price_list: docToDuplicate?.item_defaults[0].default_price_list
            });
            setReorderState(docToDuplicate?.reorder_levels);
            setBarcodesState(docToDuplicate?.barcodes);
            setSupplierItemsState(docToDuplicate?.supplier_items);
            setItemTaxeState(docToDuplicate?.taxes);
            setSelectedFamille(docToDuplicate?.famillies.map((f) => f.famille));
            setSelectedSousFamille(
                docToDuplicate?.sousfamillies.map((sf) => ({
                    name: sf.sousfamille,
                    famille: sf.famille
                }))
            );
            setSelectedTableLibre(docToDuplicate?.tablelibres.map((tl) => tl.table_libre));
            setSelectedSousTableLibre(docToDuplicate?.soustablelibres.map((stl) => stl.soustable_libre));
        }
    }, [docToDuplicate, sousfamilleLists, soustablelibreLists]);

    //useEffect(() => {
    //console.log('selectedSousFamilles changed:', selectedSousFamilles);
    //}, [selectedSousFamilles]);

    useEffect(() => {
        const header = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem('user'))?.token
        };
        if (storedFiles?.filePaths) {
            const fetchFiles = async () => {
                const promises = storedFiles?.filePaths?.map(async (url) => {
                    const response = await fetch(`${process.env.REACT_APP_API_URI}/api/files`, {
                        method: 'POST',
                        headers: header,
                        body: JSON.stringify({ fileUrl: url })
                    });
                    const fileType = response.headers.get('Content-Type');
                    const buffer = await response.arrayBuffer();
                    const blob = new Blob([buffer], { type: fileType });

                    const filename = url.substring(url.lastIndexOf('/') + 1);

                    return new File([blob], filename, { type: fileType });
                });
                const files = await Promise.all(promises);
                setItemsFiles(files);
            };

            fetchFiles();
        }
    }, [storedFiles]);
    const MyFileLink = ({ file }) => {
        const handleDownload = (event) => {
            event.preventDefault();
            const url = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = url;
            link.download = file.name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        };

        function findFilePathByName(name, filePaths) {
            for (const filePath of filePaths) {
                const parts = filePath.split('/');
                const fileName = parts[parts.length - 1];
                if (fileName === name) {
                    return filePath;
                }
            }
            return null;
        }

        const handleDeleteFile = async () => {
            const url = findFilePathByName(file?.name, storedFiles?.filePaths);
            if (url) {
                const response = await fetch(`${process.env.REACT_APP_API_URI}/api/delete-file`, {
                    method: 'DELETE',
                    headers: header,
                    body: JSON.stringify({ fileUrl: url })
                });
                if (response?.ok) {
                    dispatch(SetNotification({ code: 'success', message: 'File deleted successfully' }));
                    refetchFiles({});
                } else {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
            } else {
                return null;
            }
        };

        let preview = (
            <div>
                {file.type.startsWith('image/') ? (
                    <img style={{ width: 64, height: 64, objectFit: 'cover' }} src={URL.createObjectURL(file)} alt={file.name} />
                ) : (
                    <InsertDriveFileRoundedIcon sx={{ width: 64, height: 64, objectFit: 'cover' }} />
                )}
            </div>
        );

        return (
            <Paper
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover
                    },
                    padding: theme.spacing(2),
                    marginBottom: theme.spacing(1)
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', gap: 10 }}>
                    {preview}
                    <Typography variant="subtitle1">{file.name}</Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                    <IconButton onClick={handleDownload}>
                        <DownloadIcon />
                    </IconButton>
                    {editToggled ? (
                        <IconButton onClick={handleDeleteFile}>
                            <DeleteIcon color="error" />
                        </IconButton>
                    ) : null}
                </div>
            </Paper>
        );
    };

    useEffect(() => {
        if (selectedFieldsInLara?.[0]?.sections) {
            setSections(JSON.parse(selectedFieldsInLara?.[0]?.sections));
        } else {
            setSections(itemSections);
        }
        if (selectedFieldsInLara?.[0]?.product_required_fields) {
            setSelectedFields(JSON.parse(selectedFieldsInLara?.[0]?.product_required_fields));
        } else {
            setSelectedFields(itemFields?.filter((el) => el?.section === 'basic_section'));
        }
    }, [selectedFieldsInLara, itemSections]);

    function getFieldsBySection(section) {
        const data = selectedFields.filter((el) => el.section === section);
        if (Array.isArray(data) && data?.length !== 0) {
            return data;
        } else {
            return [];
        }
    }
    console.log(sousfamilleLists);
    console.log(selectedFamille);
    //console.log(selectedSousFamilles);
    console.log(setSelectedFamille);
    //console.log(setSelectedSousFamilles);
    console.log('tablelibreLists', tablelibreLists);

    // const { data: tablelibre, isPending } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Table%20Libre&name=${id}`);

    function renderField(field) {
        const containerStyle = {
            width: '50%'
        };
        switch (field.fieldname) {
            case 'customs_tariff_number':
                return field.label;
            case 'country_of_origin':
                return field.label;
            case 'item_code':
                return (
                    <div style={containerStyle} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            name={'item_code'}
                            error={doc && !editToggled ? null : formErrState.item_code}
                            handleChange={(doc && !editToggled) || editToggled ? null : handleChange}
                            disabled={editToggled || (doc && !editToggled)}
                            value={doc && !editToggled ? doc.item_code : formState.item_code}
                            required
                            label={t('products:productCode')}
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'item_name':
                return (
                    <div style={containerStyle} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            name={'item_name'}
                            error={doc && !editToggled ? null : formErrState.item_name}
                            handleChange={doc && !editToggled ? null : handleChange}
                            disabled={doc && !editToggled}
                            value={doc && !editToggled ? doc.item_name : formState.item_name}
                            required
                            label={t('columns:libelle')}
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'item_group':
                return (
                    <div style={containerStyle} key={field.fieldname}>
                        <SelectForm
                            fullwidth
                            label={t('products:productCategory')}
                            disabled={doc && !editToggled}
                            handleChange={doc && !editToggled ? null : handleSelectChange}
                            value={doc && !editToggled ? doc.item_group : formState.item_group}
                            name={'item_group'}
                            data={itemGroups}
                            //error={doc && !editToggled ? null : formErrState?.item_group}
                            propToRender={'name'}
                            required
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'supplier':
                return (
                    <>
                        <div style={containerStyle} key={field.fieldname}>
                            <SelectForm
                                fullwidth
                                label={t('products:Supplier')}
                                disabled={doc && !editToggled}
                                handleChange={doc && !editToggled ? null : handleSelectChange}
                                value={doc && !editToggled ? doc.supplier : formState.supplier}
                                name={'supplier'}
                                data={suppliers}
                                propToRender={'name'}
                                sx={{ mb: 1 }}
                            />
                        </div>
                        <div style={containerStyle} key={`${field.fieldname}-default_warehouse`}>
                            <SelectForm
                                fullwidth
                                label={t('products:defaultWarehouse')}
                                disabled={doc && !editToggled}
                                handleChange={doc && !editToggled ? null : handleItemDefaultsChange}
                                value={doc && !editToggled ? doc?.item_defaults?.[0]?.default_warehouse : itemDefaults?.default_warehouse}
                                name="default_warehouse"
                                data={warehouses}
                                propToRender={'name'}
                                sx={{ mb: 1 }}
                            />
                        </div>
                    </>
                );
            case 'famille':
                return (
                    <div style={containerStyle} key={field.fieldname}>
                        <Grid container justifyContent={'space-between'} alignItems={'center'}>
                            <Button color="blue" onClick={handleAddNew}>
                                <AddIcon sx={{ marginLeft: -1 }} /> {t('core:add')}
                            </Button>
                        </Grid>

                        {selectedFamille.map((selectedValues, index) => (
                            <Grid container alignItems={'center'} spacing={2} key={index}>
                                <Grid item xs={5.5}>
                                    <FormControl fullWidth sx={{ display: 'flex', alignItems: 'center' }}>
                                        <InputLabel id={`famille-label-${index}`}>{t('core:famille')}</InputLabel>
                                        <Select
                                            labelId={`famille-label-${index}`}
                                            id={`famille-select-${index}`}
                                            value={selectedValues || []}
                                            onChange={(event) => handleParentFamilleChange(event, index)}
                                            required
                                            sx={{ mt: 2, mb: 2 }}
                                            renderValue={(selected) => (Array.isArray(selected) ? selected.join(', ') : selected)}
                                        >
                                            {uniqueFamilleLists.map((famille, index) => (
                                                <MenuItem key={index} value={famille.famille}>
                                                    {famille.famille}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={5.5}>
                                    <FormControl fullWidth>
                                        <InputLabel id={`sousfamille-label-${index}`}>{t('core:sousfamille')}</InputLabel>
                                        <Select
                                            labelId={`sousfamille-label-${index}`}
                                            id={`sousfamille-select-${index}`}
                                            value={selectedSousFamille[index] ? selectedSousFamille[index].name : ''}
                                            onChange={(event) => handleSousFamilleChange(event, index)}
                                            disabled={selectedValues.length === 0}
                                            required
                                            sx={{ mt: 2, mb: 2 }}
                                        >
                                            {sousfamilleLists
                                                .filter((sf) => selectedValues.includes(sf.famille))
                                                .map((sousFamille, index) => (
                                                    <MenuItem key={index} value={sousFamille.name}>
                                                        {sousFamille.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton onClick={() => handleRemove(index)}>
                                        <RestartAltIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))}
                    </div>
                );

            case 'stock_uom':
                return (
                    <div style={containerStyle} key={field.fieldname}>
                        <SelectForm
                            fullwidth
                            label={t('common:unité-de-mesure')}
                            disabled={doc && !editToggled}
                            handleChange={doc && !editToggled ? null : handleSelectChange}
                            value={doc && !editToggled ? doc?.stock_uom : formState?.stock_uom}
                            name={'stock_uom'}
                            data={uoms}
                            propToRender={'name'}
                            required
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'description':
                return (
                    <div style={containerStyle} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            name={'description'}
                            handleChange={doc && !editToggled ? null : handleChange}
                            disabled={doc && !editToggled}
                            value={doc && !editToggled ? doc?.description : formState?.description}
                            label={t('common:description')}
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'brand':
                return (
                    <div style={containerStyle} key={field.fieldname}>
                        <SelectForm
                            fullwidth
                            disabled={doc && !editToggled}
                            label={t('products:brand')}
                            handleChange={doc && !editToggled ? null : handleSelectChange}
                            value={doc && !editToggled ? doc.brand : formState.brand}
                            name={'brand'}
                            data={brands}
                            propToRender={'name'}
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'item_collection':
                return (
                    <>
                        <div style={{ marginTop: '1px', width: '50%' }} key={field.fieldname}>
                            <SelectForm
                                fullwidth
                                label={t('products:collection')}
                                disabled={doc && !editToggled}
                                handleChange={doc && !editToggled ? null : handleSelectChange}
                                value={doc && !editToggled ? doc?.item_collection : formState?.item_collection}
                                name={'item_collection'}
                                data={itemCollections}
                                propToRender={'name'}
                                sx={{ mb: 1 }}
                            />
                        </div>
                        {/*  <Link to={`/pim/product-collection/${formState.item_collection}`} style={{ margin: '10px' }} target='_blank'>
                          {' '}
                          <ModeEditIcon aria-label="edit collection" size="small">
                              {' '}
                              <ModeEditIcon fontSize="small" />{' '}
                          </ModeEditIcon>{' '}
                      </Link>*/}
                        <div style={containerStyle} key={'has_variants'}>
                            {!isSimple && !toPrint ? (
                                <FormControl sx={{ width: '100%' }} required error={doc ? null : formState.attributes?.length == 0}>
                                    <Grid container item spacing={4} display={'flex'} alignItems={'center'}>
                                        <Grid item xs={4}>
                                            <FormLabel sx={{ display: 'flex', justifyContent: 'right' }}>Types variantes:</FormLabel>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Box
                                                borderRadius={2}
                                                bgcolor={doc ? theme.palette.secondary.main : 'Menu'}
                                                p={2}
                                                sx={{ overflowY: 'scroll', height: 150, width: '100%' }}
                                            >
                                                {itemAttributes?.map((name, i) => (
                                                    <Box height={35} key={i}>
                                                        <FormControlLabel
                                                            key={i}
                                                            label={name.name}
                                                            control={
                                                                <Checkbox
                                                                    key={i}
                                                                    name={name.name}
                                                                    checked={
                                                                        doc && !editToggled
                                                                            ? isExistInDoc(name.name, doc.attributes, 'attribute')
                                                                            : isExistInDoc(name.name, formState.attributes, 'attribute')
                                                                    }
                                                                    onChange={doc && !editToggled ? () => {} : handleAttributesChange}
                                                                />
                                                            }
                                                        />
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            ) : null}
                        </div>
                    </>
                );
            case 'item_defaults':
                return (
                    <div style={{ marginTop: '1px', width: '50%', display: 'none' }} key={`${field.fieldname}-company`}>
                        <SelectForm
                            fullwidth
                            label={t('crm:company')}
                            disabled={itemDefaults?.company}
                            handleChange={itemDefaults?.company ? null : handleItemDefaultsChange}
                            value={doc ? doc.item_defaults?.[0]?.company : itemDefaults?.company}
                            name={'company'}
                            data={companies}
                            error={!itemDefaults?.company ? true : false}
                            propToRender={'name'}
                            required
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'table_libre':
                return (
                    <div style={containerStyle} key={field.fieldname}>
                        <Grid container justifyContent={'space-between'} alignItems={'center'}>
                            <Button color="blue" onClick={handleAddNewTable}>
                                <AddIcon sx={{ marginLeft: -1 }} /> {t('core:add')}
                            </Button>
                        </Grid>

                        {selectedTableLibre.map((selectedValue, index) => (
                            <Grid container alignItems={'center'} spacing={2} key={index}>
                                <Grid item xs={5.5}>
                                    <FormControl fullWidth sx={{ display: 'flex', alignItems: 'center' }}>
                                        <InputLabel id={`tablelibres-label-${index}`}>{t('core:libre')}</InputLabel>
                                        <Select
                                            labelId={`tablelibres-label-${index}`}
                                            id={`tablelibres-select-${index}`}
                                            value={selectedValue || ''}
                                            onChange={(event) => handleTableLibreChange(event, index)}
                                            required
                                            sx={{ mt: 2, mb: 2 }}
                                        >
                                            {tablelibreLists?.map((table, index) => (
                                                <MenuItem key={index} value={table.name}>
                                                    {table.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={5.5}>
                                    <FormControl fullWidth>
                                        <InputLabel id={`soustablelibres-label-${index}`}>{t('products:sub_table')}</InputLabel>
                                        <Select
                                            labelId={`soustablelibres-label-${index}`}
                                            id={`soustablelibres-select-${index}`}
                                            value={selectedSousTableLibre[index] || ''}
                                            onChange={(event) => handleSousTableLibreChange(event, index)}
                                            disabled={!selectedValue}
                                            required
                                            sx={{ mt: 2, mb: 2 }}
                                        >
                                            {soustablelibreLists
                                                .filter((sousTableLibre) => sousTableLibre.table_libre === selectedValue)
                                                .map((sousTableLibre, index) => (
                                                    <MenuItem key={index} value={sousTableLibre.name}>
                                                        {sousTableLibre.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton onClick={() => handleRemoveTable(index)}>
                                        <RestartAltIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))}
                    </div>
                );
            case 'max_discount':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            sx={{ mb: 2 }}
                            isPercentage
                            type="number"
                            handleChange={doc && !editToggled ? null : handleChange}
                            value={doc && !editToggled ? doc?.max_discount : formState?.max_discount}
                            label={t('products:maxDiscount')}
                            name="max_discount"
                            disabled={doc && !editToggled}
                        />
                    </div>
                );
            case 'is_sales_item':
                return (
                    <div style={{ width: '50%' }} key={`${field.fieldname}`}>
                        <FormControlLabel
                            sx={{ marginLeft: '35%' }}
                            control={
                                <Checkbox
                                    checked={doc && !editToggled ? doc?.is_sales_item === 1 : formState.is_sales_item === 1}
                                    onChange={doc && !editToggled ? null : handleCheckChange}
                                    name={field.fieldname}
                                />
                            }
                            label={<Typography fontWeight={500}> {t('products:isSalesItem')}</Typography>}
                        />
                    </div>
                );
            case 'grant_commission':
                return (
                    <div style={{ width: '50%' }} key={`${field.fieldname}`}>
                        <FormControlLabel
                            sx={{ marginLeft: '35%' }}
                            control={
                                <Checkbox
                                    checked={doc && !editToggled ? doc?.grant_commission === 1 : formState.grant_commission === 1}
                                    onChange={doc && !editToggled ? null : handleCheckChange}
                                    name={field.fieldname}
                                />
                            }
                            label={<Typography fontWeight={500}>{t('products:grantCommission')}</Typography>}
                        />
                    </div>
                );
            case 'sales_uom':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <SelectForm
                            fullwidth
                            label={t('products:salesUom')}
                            disabled={doc && !editToggled}
                            handleChange={doc && !editToggled ? null : handleSelectChange}
                            value={doc && !editToggled ? doc?.sales_uom : formState?.sales_uom}
                            name={'sales_uom'}
                            data={uoms}
                            propToRender={'name'}
                            sx={{ mb: 1 }}
                        />
                    </div>
                );

            case 'currency':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <SelectForm
                            fullwidth
                            disabled={doc && !editToggled}
                            label={t('columns:currency')}
                            handleChange={doc && !editToggled ? null : handleSelectChange}
                            value={doc && !editToggled ? doc.currency : formState.currency}
                            name={'currency'}
                            data={currencyLists}
                            propToRender={'name'}
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'has_serial_no':
                return (
                    <div style={{ width: '50%' }} key={`${field.fieldname}`}>
                        <FormControlLabel
                            sx={{ marginLeft: '35%' }}
                            control={
                                <Checkbox
                                    checked={doc && !editToggled ? doc?.has_serial_no === 1 : formState.has_serial_no === 1}
                                    onChange={doc && !editToggled ? null : handleCheckChange}
                                    name={field.fieldname}
                                />
                            }
                            label={<Typography fontWeight={500}> {t('products:hasSerialNo')}</Typography>}
                        />
                    </div>
                );
            case 'has_batch_no':
                return (
                    <div style={{ width: '50%' }} key={`${field.fieldname}`}>
                        <FormControlLabel
                            sx={{ marginLeft: '35%' }}
                            control={
                                <Checkbox
                                    checked={doc && !editToggled ? doc?.has_batch_no === 1 : formState.has_batch_no === 1}
                                    onChange={doc && !editToggled ? null : handleCheckChange}
                                    name={field.fieldname}
                                />
                            }
                            label={<Typography fontWeight={500}> {t('products:hasBatchNo')}</Typography>}
                        />
                    </div>
                );
            case 'weight_uom':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <SelectForm
                            fullwidth
                            label={t('products:weightUOM')}
                            disabled={doc && !editToggled}
                            handleChange={doc && !editToggled ? null : handleSelectChange}
                            value={doc && !editToggled ? doc?.weight_uom : formState?.weight_uom}
                            name={'weight_uom'}
                            data={uoms}
                            propToRender={'name'}
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'weight_per_unit':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            disabled={doc && !editToggled}
                            name={'weight_per_unit'}
                            handleChange={doc && !editToggled ? null : handleChange}
                            value={doc && !editToggled ? doc?.weight_per_unit : formState.weight_per_unit}
                            label={t('products:weightPerUnit')}
                            type="number"
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'warranty_period':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            disabled={doc && !editToggled}
                            name={'warranty_period'}
                            handleChange={doc && !editToggled ? null : handleChange}
                            value={doc && !editToggled ? doc?.warranty_period : formState.warranty_period}
                            label={t('products:warrantyPeriod')}
                            type="number"
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'valuation_method':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <SelectForm
                            fullwidth
                            disabled={doc && !editToggled}
                            label={t('products:valuationMethod')}
                            handleChange={doc && !editToggled ? null : handleSelectChange}
                            value={doc && !editToggled ? doc?.valuation_method : formState.valuation_method}
                            name={'valuation_method'}
                            data={['FIFO', 'Moving Average', 'LIFO']}
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'default_material_request_type':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <SelectForm
                            fullwidth
                            disabled={doc && !editToggled}
                            label={t('products:defaultMaterialRequestType')}
                            handleChange={doc && !editToggled ? null : handleSelectChange}
                            value={doc && !editToggled ? doc?.default_material_request_type : formState.default_material_request_type}
                            name={'default_material_request_type'}
                            data={['Purchase', 'Material Transfer', 'Material Issue', 'Manufacture', 'Customer Provided']}
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'end_of_life':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <FormDateTime
                            fullwidth
                            type="date"
                            label={t('products:endOfLife')}
                            name="end_of_life"
                            handleChange={doc && !editToggled ? null : handleChange}
                            value={doc && !editToggled ? doc?.end_of_life : formState.end_of_life}
                            disabled={doc && !editToggled}
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'shelf_life_in_days':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            disabled={doc && !editToggled}
                            name={'shelf_life_in_days'}
                            handleChange={doc && !editToggled ? null : handleChange}
                            value={doc && !editToggled ? doc?.shelf_life_in_days : formState.shelf_life_in_days}
                            label={t('products:shelfLifeInDays')}
                            type="number"
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'last_purchase_rate':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            disabled={doc && !editToggled}
                            name={'last_purchase_rate'}
                            handleChange={doc && !editToggled ? null : handleChange}
                            value={doc && !editToggled ? doc?.last_purchase_rate : formState.last_purchase_rate}
                            label={t('products:lastPurchaseRate')}
                            type="number"
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'lead_time_days':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            disabled={doc && !editToggled}
                            name={'lead_time_days'}
                            handleChange={doc && !editToggled ? null : handleChange}
                            value={doc && !editToggled ? doc?.lead_time_days : formState.lead_time_days}
                            label={t('products:leadTimeDays')}
                            type="number"
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'supplier_items':
                return (
                    <div
                        style={{
                            width: '100%',
                            backgroundColor: theme.palette.secondary.main,
                            margin: '20px 0px',
                            borderRadius: 10
                        }}
                        key={field.fieldname}
                    >
                        {doc ? (
                            <SupplierItemsForm
                                disabled={doc && !editToggled}
                                doc={doc}
                                formState={formState}
                                supplierItemsState={doc && !editToggled ? doc?.supplier_items : supplierItemsState}
                                setSupplierItemsState={setSupplierItemsState}
                                suppliers={suppliers}
                            />
                        ) : null}
                    </div>
                );
            case 'taxe_items':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <SelectForm
                            fullwidth
                            label={t('core:taxrate')}
                            disabled={doc && !editToggled}
                            handleChange={doc && !editToggled ? null : handleSelectChange}
                            value={doc && !editToggled ? doc?.item_tax_category : formState.item_tax_category}
                            name={'item_tax_category'}
                            data={taxetemplate}
                            propToRender={'name'}
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'reorder_levels':
                return (
                    <div
                        style={{
                            width: '100%',
                            backgroundColor: theme.palette.secondary.main,
                            margin: '20px 0px',
                            borderRadius: 10
                        }}
                        key={field.fieldname}
                    >
                        <ReorderForm
                            field={field}
                            disabled={doc && !editToggled}
                            reorderState={doc && !editToggled ? doc?.reorder_levels : reorderState}
                            setReorderState={setReorderState}
                            warehouses={warehouses}
                        />
                    </div>
                );
            case 'safety_stock':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            disabled={doc && !editToggled}
                            name={'safety_stock'}
                            handleChange={doc && !editToggled ? null : handleChange}
                            value={doc && !editToggled ? doc?.safety_stock : formState.safety_stock}
                            label={t('products:safetyStock')}
                            type="number"
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'min_order_qty':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            disabled={doc && !editToggled}
                            name={'min_order_qty'}
                            handleChange={doc && !editToggled ? null : handleChange}
                            value={doc && !editToggled ? doc?.min_order_qty : formState.min_order_qty}
                            label={t('products:minOrderQty')}
                            type="number"
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'purchase_uom':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <SelectForm
                            fullwidth
                            label={t('products:purchaseUOM')}
                            disabled={doc && !editToggled}
                            handleChange={doc && !editToggled ? null : handleSelectChange}
                            value={doc && !editToggled ? doc?.purchase_uom : formState?.purchase_uom}
                            name={'purchase_uom'}
                            data={uoms}
                            propToRender={'name'}
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'standard_rate':
                {
                    const standardSellingPrice = itemPriceState.find((item) => item.price_list === 'Standard Selling');
                    const standardRateValue = standardSellingPrice ? standardSellingPrice.price_list_rate : '';
                }

                return (
                    <>
                        <div style={{ width: '50%' }} key={field.fieldname}>
                            <InputTextField
                                fullwidth
                                disabled={(doc && !editToggled) || !formState.item_tax_category}
                                name={'standard_rate'}
                                handleChange={doc && !editToggled ? null : handleChange}
                                value={doc && !editToggled ? doc?.standard_rate : formState.standard_rate}
                                label={t('products:Prix_de_vente_HT')}
                                type="number"
                                sx={{ mb: 1 }}
                                helper={formState.item_tax_category ? '' : t('products:tax_field_must_HT')}
                            />
                        </div>
                        <div style={{ width: '50%' }} key={`${field.fieldname}-default_price_list`}>
                            <SelectForm
                                fullwidth
                                disabled={doc && !editToggled}
                                label={t('core:priceelistselling')}
                                handleChange={doc && !editToggled ? null : handleItemDefaultsChange}
                                value={doc && !editToggled ? doc?.item_defaults?.[0]?.default_price_list : itemDefaults?.default_price_list}
                                name="default_price_list"
                                data={priceLists}
                                propToRender={'name'}
                                sx={{ mb: 1 }}
                            />
                        </div>
                        {doc ? (
                            <div
                                style={{
                                    width: '100%',
                                    backgroundColor: theme.palette.secondary.main,
                                    margin: '20px 0px',
                                    borderRadius: 10
                                }}
                                key={'item-price'}
                            >
                                <ItemPriceForm
                                    disabled={doc && !editToggled}
                                    doc={doc}
                                    itemPriceState={doc && !editToggled ? itemPrice : itemPriceState}
                                    setItemPriceState={setItemPriceState}
                                    priceLists={priceLists}
                                />
                            </div>
                        ) : null}
                    </>
                );
            case 'valuation_rate':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            disabled={doc && !editToggled}
                            name={'valuation_rate'}
                            handleChange={doc && !editToggled ? null : handleChange}
                            value={doc && !editToggled ? doc?.valuation_rate : formState.valuation_rate}
                            label={t('dashboard:valuationRate')}
                            type="number"
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'is_purchase_item':
                return (
                    <div style={{ width: '50%' }} key={`${field.fieldname}`}>
                        <FormControlLabel
                            sx={{ marginLeft: '35%' }}
                            control={
                                <Checkbox
                                    checked={doc && !editToggled ? doc?.is_purchase_item === 1 : formState.is_purchase_item === 1}
                                    onChange={doc && !editToggled ? null : handleCheckChange}
                                    name={field.fieldname}
                                />
                            }
                            label={<Typography fontWeight={500}>{t('products:AllowPurchase')}</Typography>}
                        />
                    </div>
                );
            case 'item_code':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            name={'item_code'}
                            error={doc && !editToggled ? null : formErrState.item_code}
                            handleChange={(doc && !editToggled) || editToggled ? null : handleChange}
                            disabled={editToggled || (doc && !editToggled)}
                            value={doc && !editToggled ? doc.item_code : formState.item_code}
                            required
                            label={t('products:productCode')}
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'item_name':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            name={'item_name'}
                            error={doc && !editToggled ? null : formErrState.item_name}
                            handleChange={doc && !editToggled ? null : handleChange}
                            disabled={doc && !editToggled}
                            value={doc && !editToggled ? doc.item_name : formState.item_name}
                            required
                            label={t('products:productName')}
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'item_group':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <SelectForm
                            fullwidth
                            label={t('products:productCategory')}
                            disabled={doc && !editToggled}
                            handleChange={doc && !editToggled ? null : handleSelectChange}
                            value={doc && !editToggled ? doc.item_group : formState.item_group}
                            name={'item_group'}
                            data={itemGroups}
                            //error={doc && !editToggled ? null : formErrState?.item_group}
                            propToRender={'name'}
                            required
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'stock_uom':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <SelectForm
                            fullwidth
                            label={t('products:stockUOM')}
                            disabled={doc && !editToggled}
                            handleChange={doc && !editToggled ? null : handleSelectChange}
                            value={doc && !editToggled ? doc?.stock_uom : formState?.stock_uom}
                            name={'stock_uom'}
                            data={uoms}
                            propToRender={'name'}
                            required
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'description':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            name={'description'}
                            handleChange={doc && !editToggled ? null : handleChange}
                            disabled={doc && !editToggled}
                            value={doc && !editToggled ? doc?.description : formState?.description}
                            label={t('common:description')}
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'brand':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <SelectForm
                            fullwidth
                            disabled={doc && !editToggled}
                            label={t('products:brand')}
                            handleChange={doc && !editToggled ? null : handleSelectChange}
                            value={doc && !editToggled ? doc.brand : formState.brand}
                            name={'brand'}
                            data={brands}
                            propToRender={'name'}
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'item_collection':
                return (
                    <>
                        <div style={{ width: '50%' }} key={field.fieldname}>
                            <SelectForm
                                fullwidth
                                label={t('products:collection')}
                                disabled={doc && !editToggled}
                                handleChange={doc && !editToggled ? null : handleSelectChange}
                                value={doc && !editToggled ? doc?.item_collection : formState?.item_collection}
                                name={'item_collection'}
                                data={itemCollections}
                                propToRender={'name'}
                                sx={{ mb: 1 }}
                            />
                        </div>
                        <div style={{ width: '50%' }} key={'has_variants'}>
                            {!isSimple && !toPrint ? (
                                <FormControl sx={{ width: '100%' }} required error={doc ? null : formState.attributes?.length == 0}>
                                    <Grid container item spacing={4} display={'flex'} alignItems={'center'}>
                                        <Grid item xs={4}>
                                            <FormLabel sx={{ display: 'flex', justifyContent: 'right' }}>Types variantes:</FormLabel>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Box
                                                borderRadius={2}
                                                bgcolor={doc ? theme.palette.secondary.main : 'Menu'}
                                                p={2}
                                                sx={{ overflowY: 'scroll', height: 150, width: '100%' }}
                                            >
                                                {itemAttributes?.map((name, i) => (
                                                    <Box height={35} key={i}>
                                                        <FormControlLabel
                                                            key={i}
                                                            label={name.name}
                                                            control={
                                                                <Checkbox
                                                                    key={i}
                                                                    name={name.name}
                                                                    checked={
                                                                        doc && !editToggled
                                                                            ? isExistInDoc(name.name, doc.attributes, 'attribute')
                                                                            : isExistInDoc(name.name, formState.attributes, 'attribute')
                                                                    }
                                                                    onChange={doc && !editToggled ? () => {} : handleAttributesChange}
                                                                />
                                                            }
                                                        />
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            ) : null}
                        </div>
                    </>
                );
            case 'item_defaults':
                return (
                    <>
                        <div style={{ width: '50%' }} key={`${field.fieldname}-company`}>
                            <SelectForm
                                fullwidth
                                label={t('crm:company')}
                                disabled={itemDefaults?.company}
                                handleChange={itemDefaults?.company ? null : handleItemDefaultsChange}
                                value={doc ? doc.item_defaults?.[0]?.company : itemDefaults?.company}
                                name={'company'}
                                data={companies}
                                error={!itemDefaults?.company ? true : false}
                                propToRender={'name'}
                                required
                                sx={{ mb: 1 }}
                            />
                        </div>
                        <div style={{ width: '50%' }} key={`${field.fieldname}-default_warehouse`}>
                            <SelectForm
                                fullwidth
                                label={t('products:defaultWarehouse')}
                                disabled={doc && !editToggled}
                                handleChange={doc && !editToggled ? null : handleItemDefaultsChange}
                                value={doc && !editToggled ? doc?.item_defaults?.[0]?.default_warehouse : itemDefaults?.default_warehouse}
                                name="default_warehouse"
                                data={warehouses}
                                propToRender={'name'}
                                sx={{ mb: 1 }}
                            />
                        </div>
                    </>
                );

            case 'prix_de_revient':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            name={'prix_de_revient'}
                            handleChange={doc && !editToggled ? null : handleChange}
                            disabled={doc && !editToggled}
                            value={doc && !editToggled ? doc?.prix_de_revient : formState?.prix_de_revient}
                            label={t('products:PrixDeRevient')}
                            type="number"
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'prix_de_vente_ht':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            name={'prix_de_vente_ht'}
                            handleChange={doc && !editToggled ? null : handleChange}
                            disabled={(doc && !editToggled) || !formState.item_tax_category}
                            value={doc && !editToggled ? doc?.prix_de_vente_ht : formState?.prix_de_vente_ht}
                            label={t('dashboard:standardRate')}
                            type="number"
                            sx={{ mb: 1 }}
                            helper={formState.item_tax_category ? '' : t('products:tax_field_must')}
                        />
                    </div>
                );
            case 'prix_achat_ht':
                const today = new Date();
                const validStandardBuyingPrice = itemPriceState.find(
                    (item) => item.price_list === 'Standard Buying' && new Date(item.valid_upto) >= today
                );

                const buyingPriceValue = validStandardBuyingPrice ? validStandardBuyingPrice.price_list_rate : '';

                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            name={'prix_achat_ht'}
                            handleChange={doc && !editToggled ? null : handleChange}
                            disabled={doc && !editToggled}
                            value={doc && !editToggled ? doc?.prix_achat_ht : formState.prix_achat_ht || buyingPriceValue}
                            label={t('products:Prix_Achat_HT')}
                            type="number"
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            case 'prix_unitaire_ttc':
                return (
                    <div style={{ width: '50%' }} key={field.fieldname}>
                        <InputTextField
                            fullwidth
                            name={'prix_unitaire_ttc'}
                            handleChange={doc && !editToggled ? null : handleChange}
                            disabled={doc && !editToggled}
                            value={doc && !editToggled ? doc?.prix_unitaire_ttc : formState?.prix_unitaire_ttc}
                            label={t('products:PrixUnitaireTTC')}
                            type="number"
                            sx={{ mb: 1 }}
                        />
                    </div>
                );
            default:
                return null;
        }
    }
    return (
        <Box>
            <Box>
                {doc && doc?.variant_of && (
                    <InputTextField
                        fullwidth
                        name={'item_template'}
                        value={doc.variant_of}
                        label={t('products:variantOf')}
                        sx={{ mb: 1 }}
                    />
                )}
            </Box>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <TabContext value={activeTab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTabChange} aria-label="product form tabs">
                            {sections?.map((section) => (
                                <Tab key={section.id} label={t(section.label)} value={section.id} />
                            ))}
                        </TabList>
                    </Box>
                    {sections?.map((section) => (
                        <TabPanel key={section.id} value={section.id}>
                            {getFieldsBySection(section.id)?.map((field) => (
                                <React.Fragment key={field.fieldname}>{renderField(field)}</React.Fragment>
                            ))}
                        </TabPanel>
                    ))}
                </TabContext>
            </div>
            <Box display="flex" flexDirection="column" alignItems="center">
                <div
                    style={{
                        width: 120,
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'flex-start'
                    }}
                >
                    <Typography variant="subtitle1" fontWeight={700} style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>
                        {t('products:defaultarticleimage')}
                    </Typography>
                    <CropOriginalIcon sx={{ height: 30, objectFit: 'cover' }} />
                </div>
                <Box display="flex" flexDirection="column" alignItems="center">
                    {editToggled ? (
                        <input accept="image/*" id="image-upload" type="file" style={{ display: 'none' }} onChange={handleFileChange} />
                    ) : null}
                    {filePreview && (
                        <Box mt={2} style={{ boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', borderRadius: '8px', overflow: 'hidden' }}>
                            <img
                                src={filePreview}
                                alt="Selected"
                                style={{ maxWidth: '400px', maxHeight: '400px', display: 'block', borderRadius: '8px' }}
                            />
                        </Box>
                    )}
                    <Box mt={2}>
                        {editToggled ? (
                            <label htmlFor="image-upload">
                                <IconButton color="primary" component="span">
                                    <PhotoCameraIcon fontSize="large" />
                                </IconButton>
                            </label>
                        ) : null}
                    </Box>
                </Box>
            </Box>
            {isAdd && (
                <div style={{ padding: '10px 0px' }}>
                    <Box width={'100%'}>
                        <Paper
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: theme.palette.action.hover
                                },
                                padding: theme.spacing(2)
                            }}
                            onClick={() => setOpenBarcodes(!openBarcodes)}
                        >
                            <div
                                style={{
                                    width: 120,
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Typography variant="subtitle1" fontWeight={700}>
                                    {t('columns:Barcodes')}
                                </Typography>
                                <HorizontalSplitIcon sx={{ height: 30, objectFit: 'cover', transform: 'rotate(90deg)' }} />
                            </div>
                        </Paper>
                        {openBarcodes ? (
                            <div
                                style={{
                                    width: '100%',
                                    backgroundColor: theme.palette.secondary.main,
                                    margin: '20px 0px',
                                    borderRadius: 10
                                }}
                            >
                                <BarcodesForm
                                    disabled={doc && !editToggled}
                                    doc={doc}
                                    barcodesState={doc && !editToggled ? doc?.barcodes : barcodesState}
                                    setBarcodesState={setBarcodesState}
                                    automatic={globalAutomatic}
                                />
                            </div>
                        ) : null}
                    </Box>
                    {/* <Box width={'100%'}>
                        {ItemsFiles.map((file, i) => (
                            <MyFileLink key={i} file={file} />
                        ))}
                        <Paper
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: theme.palette.action.hover
                                },
                                padding: theme.spacing(2)
                            }}
                            onClick={() => setOpenDropZone(!openDropZone)}
                        >
                            <div
                                style={{
                                    width: 120,
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Typography variant="subtitle1" fontWeight={700}>
                                    {t('common:attachFile')}
                                </Typography>
                                <AttachFileTwoToneIcon sx={{ height: 30, objectFit: 'cover' }} />
                            </div>
                        </Paper>
                        {openDropZone && (
                            <Box width={'100%'} sx={{ padding: theme.spacing(2) }}>
                                <DropzoneArea
                                    filesLimit={5}
                                    dropzoneText="Déposer les fichiers ici"
                                    onChange={handleFilesChange}
                                    maxFileSize={5000000}
                                />
                            </Box>
                        )}
                    </Box>
                    <Box width={'100%'}>
                        <Paper
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: theme.palette.action.hover
                                },
                                padding: theme.spacing(2)
                            }}
                            onClick={() => navigate(`/pim/product-view/${doc?.name}/channels`)}
                        >
                            <div
                                style={{
                                    width: 120,
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Typography variant="subtitle1" fontWeight={700}>
                                    CHANNELS
                                </Typography>
                                <ArrowDropDownCircleTwoToneIcon sx={{ height: 30, objectFit: 'cover' }} />
                            </div>
                        </Paper>
                    </Box>*/}
                </div>
            )}
            {!toPrint && doc && (
                <div style={{ padding: '10px 0px' }}>
                    <Box width={'100%'}>
                        <Paper
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: theme.palette.action.hover
                                },
                                padding: theme.spacing(2)
                            }}
                            onClick={() => setOpenBarcodes(!openBarcodes)}
                        >
                            <div
                                style={{
                                    width: 120,
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Typography variant="subtitle1" fontWeight={700}>
                                    Barcodes
                                </Typography>
                                <HorizontalSplitIcon sx={{ height: 30, objectFit: 'cover', transform: 'rotate(90deg)' }} />
                            </div>
                        </Paper>
                        {openBarcodes ? (
                            <div
                                style={{
                                    width: '100%',
                                    backgroundColor: theme.palette.secondary.main,
                                    margin: '20px 0px',
                                    borderRadius: 10
                                }}
                            >
                                <BarcodesForm
                                    disabled={doc && !editToggled}
                                    doc={doc}
                                    barcodesState={doc && !editToggled ? doc?.barcodes : barcodesState}
                                    setBarcodesState={setBarcodesState}
                                    automatic={globalAutomatic}
                                />
                            </div>
                        ) : null}
                    </Box>
                    <Box width={'100%'}>
                        {ItemsFiles.map((file, i) => (
                            <MyFileLink key={i} file={file} />
                        ))}
                        <Paper
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: theme.palette.action.hover
                                },
                                padding: theme.spacing(2)
                            }}
                            onClick={() => setOpenDropZone(!openDropZone)}
                        >
                            <div
                                style={{
                                    width: 120,
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Typography variant="subtitle1" fontWeight={700}>
                                    {t('common:attachFile')}
                                </Typography>
                                <AttachFileTwoToneIcon sx={{ height: 30, objectFit: 'cover' }} />
                            </div>
                        </Paper>
                        {openDropZone && (
                            <Box width={'100%'} sx={{ padding: theme.spacing(2) }}>
                                <DropzoneArea
                                    filesLimit={5}
                                    dropzoneText="Déposer les fichiers ici"
                                    onChange={handleFilesChange}
                                    maxFileSize={5000000}
                                />
                            </Box>
                        )}
                    </Box>
                    <Box width={'100%'}>
                        <Paper
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: theme.palette.action.hover
                                },
                                padding: theme.spacing(2)
                            }}
                            onClick={() => navigate(`/pim/product-view/${doc?.name}/channels`)}
                        >
                            <div
                                style={{
                                    width: 120,
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Typography variant="subtitle1" fontWeight={700}>
                                    {t('channels:channels')}
                                </Typography>
                                <ArrowDropDownCircleTwoToneIcon sx={{ height: 30, objectFit: 'cover' }} />
                            </div>
                        </Paper>
                    </Box>
                </div>
            )}
            {renderSaveButton() && (
                <Box display={'flex'} mt={3} justifyContent="right">
                    <LoadingButton
                        loading={loadingButton}
                        variant="contained"
                        size="large"
                        color="blue"
                        disabled={(formErrState.item_code || formErrState.item_name || !barcodesState.length) && !doc}
                        onClick={() => handleCreate()}
                    >
                        {t('common:save')}
                    </LoadingButton>
                </Box>
            )}
        </Box>
    );
};

export default ServiceProdForm;
