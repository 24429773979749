import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Grid, Box, IconButton, Modal, Button, Divider, Chip, Drawer, useMediaQuery } from '@mui/material';
import useGetAxios from 'hooks/useGetAxios';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useStyles } from 'views/utilities/Style';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MainCard from 'ui-component/cards/MainCard';
import useFetch from 'hooks/useFetch';
import DataRender from 'ui-component/tables/DataRender';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import InputTextField from 'ui-component/form/InputTextField';
import { LoadingButton } from '@mui/lab';
import SelectForm from 'ui-component/form/SelectForm';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useEffect } from 'react';
import { SetNotification } from 'store/services/api';
import { useDispatch, useSelector } from 'react-redux';
import FormDateTime from 'ui-component/dateTime/FormDateTime';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import { modalStyle } from 'views/utilities/ModalStyle';
import { formatErpResponse } from 'utils/utils';
import vnditLogo from 'assets/images/vnditLogo.png';
import DataImporter from 'views/products/productManagement/components/DataImporter';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const SupplierCatalog = () => {
    const theme = useTheme();
    const { id } = useParams();
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const detailsDefaultCompany = useSelector((state) => state.login.detailsDefaultCompany);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [loadingButton, setLoadingButton] = React.useState(false);
    const [loadingDelete, setLoadingDelete] = React.useState(false);
    const [loadingPriceList, setLoadingPriceList] = React.useState(false);
    const [loadingItemDetails, setLoadingItemDetails] = React.useState(false);
    const [priceList, setPriceList] = React.useState(null);
    const [action, setAction] = React.useState(null);
    const [doctypeImport, setDoctypeImport] = React.useState(null);
    const [openImportForm, setOpenImportForm] = React.useState(false);
    const [openStepperImport, setOpenStepperImport] = React.useState(false);
    const handleOpenForm = (action) => {
        setOpenImportForm(true);
        setDoctypeImport(action);
    };
    const handleCloseForm = () => {
        setOpenImportForm(false);
        refetchItem({});
    };

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };
    const [selectedItemDetails, setSelectedItemDetails] = React.useState('');
    const [stateItem, setStateItem] = React.useState({
        doctype: 'Item',
        item_code: '',
        min_order_qty: 0
    });
    const [error, setError] = React.useState({
        item_code: false
    });

    const handleChange = (e) => {
        setError({ ...error, [e.target.name]: false });
        setStateItem({ ...stateItem, [e.target.name]: e.target.value });
    };

    const [statePriceList, setStatePriceList] = React.useState({
        price_list_rate: 0,
        lead_time_days: 0,
        valid_from: moment().format('YYYY-MM-DD'),
        valid_upto: ''
    });

    const handleChangePrice = (e) => {
        setError({ ...error, [e.target.name]: false });
        setStatePriceList({ ...statePriceList, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        if (stateItem?.item_code) {
            fetchItemDetails(stateItem?.item_code);
        }
    }, [stateItem?.item_code]);

    const {
        data: imageList,
        isImageListPending,
        refetch: refetchImageList
    } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/item-images`, null, true);

    const itemsReqDataBySupplier = {
        doctype: 'Item',
        fields: [
            '`tabItem`.`creation`',
            '`tabItem`.`name`',
            '`tabItem`.`item_name`',
            '`tabItem`.`item_group`',
            '`tabItem`.`description`',
            '`tabItem`.`disabled`'
        ],
        filters: [['Item Supplier', 'supplier', '=', id]]
    };

    const {
        data: productsData,
        isPending: isItemPending,
        refetch: refetchItem
    } = useFetch(`/api/get-list`, itemsReqDataBySupplier, 'POST');

    const itemsWithoutSupplier = {
        doctype: 'Item',
        fields: [
            '`tabItem`.`creation`',
            '`tabItem`.`name`',
            '`tabItem`.`item_name`',
            '`tabItem`.`item_group`',
            '`tabItem`.`description`',
            '`tabItem`.`disabled`'
        ],
        filters: [['Item Supplier', 'supplier', '!=', id]]
    };

    const {
        data: itemsSupplier,
        isPending: isItemsWithoutSupplierPending,
        refetch: refetchItemsWithoutSupplier
    } = useFetch(`/api/get-list`, itemsWithoutSupplier, 'POST');

    /* FETCHING */
    const fetchPriceListBySupplier = (row) => {
        setLoadingPriceList(true);
        let priceListReqData = {
            doctype: 'Item Price',
            fields: [
                '`tabItem Price`.`name`',
                '`tabItem Price`.`item_code`',
                '`tabItem Price`.`item_name`',
                '`tabItem Price`.`price_list`',
                '`tabItem Price`.`price_list_rate`',
                '`tabItem Price`.`lead_time_days`',
                '`tabItem Price`.`currency`',
                '`tabItem Price`.`valid_from`',
                '`tabItem Price`.`valid_upto`',
                '`tabItem Price`.`modified`',
                '`tabItem Price`.`creation`',
                '`tabItem Price`.`owner`'
            ],
            filters: [
                ['Item Price', 'item_code', '=', row],
                ['Item Price', 'supplier', '=', id],
                ['Item Price', 'price_list', '=', 'Standard Buying']
            ]
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(priceListReqData)
        })
            .then((res) => {
                setLoadingPriceList(false);
                return res.json();
            })
            .then((data) => {
                setPriceList(data);
            });
    };

    const fetchItemDetails = (row, isEdit) => {
        isEdit && setLoadingItemDetails(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/details?doctype=Item&name=${row}`, {
            method: 'GET',
            headers: header
        })
            .then((res) => {
                isEdit && setLoadingItemDetails(false);
                return res.json();
            })
            .then((data) => {
                setSelectedItemDetails(data?.docs[0]);
            });
    };

    const handleCancel = () => {
        setStateItem({
            ...stateItem,
            item_code: '',
            min_order_qty: 0,
            lead_time_days: 0
        });
        setStatePriceList({
            price_list_rate: 0,
            lead_time_days: 0,
            valid_from: moment().format('YYYY-MM-DD'),
            valid_upto: ''
        });
        setOpenDrawer(false);
    };

    const handleCancelModal = () => {
        setStateItem({ ...stateItem, item_code: '', min_order_qty: 0 });
        setStatePriceList({
            price_list_rate: 0,
            lead_time_days: 0,
            valid_from: moment().format('YYYY-MM-DD'),
            valid_upto: ''
        });
        setOpen(false);
    };

    const handleAddPriceBySupplier = (data, isEdit) => {
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: data, action: 'Save' })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: 'An error has occurred' }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'Price successfully added' }));

                    isEdit ? handleCancelModal() : handleCancel();
                }
            });
    };

    const handleUpdateItemBySupplier = (data, isEdit) => {
        setLoadingButton(true);

        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doc: data, action: 'Save' })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: 'An error has occurred' }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'Item successfully added' }));
                    refetchItem({});
                    isEdit ? handleCancelModal() : handleCancel();
                    isEdit && setSelectedItemDetails('');
                }
            });
    };

    const handleUpdateItem = () => {
        let suppliers = [
            {
                supplier: id,
                parentfield: 'supplier_items',
                parenttype: 'Item',
                doctype: 'Item Supplier'
            }
        ];

        selectedItemDetails?.supplier_items?.map((el) => {
            suppliers.push({
                supplier: el.supplier,
                parentfield: 'supplier_items',
                parenttype: 'Item',
                doctype: 'Item Supplier'
            });
        });

        const data = {
            ...selectedItemDetails,
            min_order_qty: stateItem.min_order_qty,
            supplier_items: suppliers
        };
        handleUpdateItemBySupplier(data);
    };

    const handleAddPrice = () => {
        let data = {
            item_code: selectedItemDetails?.item_code,
            item_name: selectedItemDetails?.item_name,
            item_description: selectedItemDetails?.item_description,
            price_list: 'Standard Buying',
            supplier: id,
            buying: 1,
            selling: 0,
            currency: 'TND',
            price_list_rate: statePriceList.price_list_rate,
            lead_time_days: statePriceList.lead_time_days,
            valid_from: statePriceList.valid_from,
            valid_upto: statePriceList.valid_upto,
            doctype: 'Item Price'
        };
        handleAddPriceBySupplier(data);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const fieldsToValidate = ['item_code', 'price_list_rate', 'valid_upto'];

        let isValid = true;
        const nextError = { ...error };

        fieldsToValidate.forEach((field) => {
            if (field === 'price_list_rate' && statePriceList[field] === 0) {
                nextError[field] = true;
                isValid = false;
            }
            if (field === 'valid_upto' && statePriceList[field] === '') {
                nextError[field] = true;
                isValid = false;
            }
            if (field === 'item_code' && stateItem[field] === '') {
                nextError[field] = true;
                isValid = false;
            }
        });

        setError(nextError);
        if (isValid) {
            await handleUpdateItem();
            await handleAddPrice();
        }
    };

    const handleBulkDelete = () => {
        const rows = priceList?.map((obj) => obj.name);
        fetch(`${process.env.REACT_APP_API_URI}/api/delete-items`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({ doctype: 'Item Price', items: rows })
        })
            .then((res) => {
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: "Une erreur s'est produite" }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                    return;
                } else {
                    dispatch(SetNotification({ code: 'success', message: 'Les prix(s) ont été supprimés avec succès' }));
                    refetchItem({});
                }
            });
    };

    const handleDataToDelete = async (row) => {
        await fetchPriceListBySupplier(row);
        const isEdit = true;
        await fetchItemDetails(row, isEdit);
    };

    const handleEdit = async (row) => {
        navigate(`/oms/edit-supplier/${id}`, { state: row });
    };

    const handleDelete = async () => {
        const data = {
            ...selectedItemDetails,
            min_order_qty: 0,
            supplier_items: []
        };
        const isEdit = true;
        await handleUpdateItemBySupplier(data, isEdit);
        await handleBulkDelete();
    };

    function getImgUrl(itemCode) {
        if (imageList && imageList?.itemImages) {
            const IIndex = imageList?.itemImages?.findIndex((e) => e.itemCode === itemCode);
            if (IIndex !== -1) {
                return `${process.env.REACT_APP_API_URI}` + imageList?.itemImages?.[IIndex]?.imgUrl;
            } else if (detailsDefaultCompany && detailsDefaultCompany?.company_logo) {
                return `${process.env.REACT_APP_IMAGE_URL}` + detailsDefaultCompany?.company_logo;
            } else {
                return vnditLogo;
            }
        } else if (detailsDefaultCompany && detailsDefaultCompany?.company_logo) {
            return `${process.env.REACT_APP_IMAGE_URL}` + detailsDefaultCompany?.company_logo;
        } else {
            return vnditLogo;
        }
    }

    const handleClick = (action) => {
        setAction(action);
        setOpenDrawer(true);
    };
    const title = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            <Grid
                spacing={isSmallScreen && 5}
                sx={{ flexGrow: 2, flexDirection: 'row', alignItems: { xs: 'center', sm: 'center' } }}
                container
            >
                <Grid item md>
                    <ArrowBackIcon
                        className={classes.backLink}
                        onClick={() => {
                            navigate(-1);
                        }}
                    />
                    {t('core:supplierCatalog')}: {id}
                </Grid>
                <Grid item xs display="flex" justifyContent="flex-end">
                    <Box>
                        <Grid
                            container
                            direction={isSmallScreen ? 'column' : 'row'}
                            spacing={1}
                            alignItems={isSmallScreen ? 'flex-end' : 'flex-end'}
                        >
                            <Grid item xs>
                                <Button
                                    startIcon={<AddCircleIcon />}
                                    variant="outlined"
                                    color="blue"
                                    onClick={() => handleClick('add')}
                                    sx={{ marginTop: -3 }}
                                    style={{ whiteSpace: 'nowrap' }}
                                >
                                    {t('core:addN')}
                                </Button>
                            </Grid>
                            <Grid item xs>
                                <Button
                                    variant="text"
                                    color="blue"
                                    onClick={() => setOpenStepperImport(true)}
                                    sx={{ marginTop: -3 }}
                                    style={{ whiteSpace: 'nowrap' }}
                                >
                                    Import
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
    const drawerTitle = (
        <Box p={-1} display={'flex'} justifyContent={'space-between'}>
            <Box p={-1} display={'flex'} flexDirection={'column'}>
                {t('core:addN')} {stateItem?.item_code}
            </Box>
            <Box>
                <IconButton aria-label="close" size="small" onClick={handleCancel}>
                    <CloseIcon color="blue" />
                </IconButton>
            </Box>
        </Box>
    );

    const columnsImport = [
        'Item Code',
        'Item Group',
        'Description',
        'Default Unit of Measure',
        'Maintain Stock',
        'ID (Supplier Items)',
        'Supplier (Supplier Items)'
    ];

    const secondColumns = ['Item Code', 'Price List', 'Rate', 'Supplier'];
    const itemImporter = (
        <Box sx={modalStyle}>
            <MainCard divider title={'Import'}>
                <DataImporter refetch={refetchItem} handleClose={handleCloseForm} columns={columnsImport} doctype="Item" />
            </MainCard>
        </Box>
    );

    const priceItemImporter = (
        <Box sx={modalStyle}>
            <MainCard divider title={'Import'}>
                <DataImporter refetch={refetchItem} handleClose={handleCloseForm} columns={secondColumns} doctype="Item Price" />
            </MainCard>
        </Box>
    );
    const stepperImport = (
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 550, border: 'none' }}>
            <MainCard>
                <Grid container justifyContent={'center'} spacing={2}>
                    <Grid item>
                        <Button startIcon={'1-'} variant="contained" color="blue" onClick={() => handleOpenForm('Item')}>
                            {t('core:importExternalArticle')}
                        </Button>
                    </Grid>
                    <ArrowForwardIcon sx={{ color: 'blue', mt: 3, ml: 3 }} />
                    <Grid item>
                        <Button startIcon={'2-'} variant="contained" color="blue" onClick={() => handleOpenForm('Item Price')}>
                            {t('core:importItemPrice')}
                        </Button>
                    </Grid>
                </Grid>
            </MainCard>
        </Box>
    );

    return (
        <Box>
            <MainCard title={title} />
            <MainCard
                divider
                border
                title={
                    <Box display={'flex'}>
                        <LocalShippingIcon sx={{ mr: 1.5, ml: -1.5 }} />
                        {t('common:prdcts')}
                    </Box>
                }
            >
                <DataRender
                    refetch={refetchItem}
                    data={productsData}
                    loading={isItemPending}
                    selectionModel={selectionModel}
                    setSelectionModel={setSelectionModel}
                    tag={t('columns:reference')}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    handleDataToDelete={handleDataToDelete}
                    fetchLoading={loadingItemDetails && loadingPriceList}
                    handleCancelModal={handleCancel}
                    hasImage
                    getImgUrl={getImgUrl}
                    isViewAction
                />
            </MainCard>
            <div>
                <Drawer variant="temporary" anchor={'right'} sx={{ left: 'auto', width: '50vw' }} onClose={handleCancel} open={openDrawer}>
                    <MainCard title={drawerTitle} divider sx={{ height: '100%' }}>
                        <Divider sx={{ m: 2 }}>
                            <Chip label={t('common:prdcts')} />
                        </Divider>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: '100%' }}>
                            <div style={{ width: '100%' }}>
                                <SelectForm
                                    fullwidth
                                    label={t('core:selectItem')}
                                    name="item_code"
                                    value={stateItem.item_code}
                                    handleChange={handleChange}
                                    data={itemsSupplier.length != 0 ? itemsSupplier : []}
                                    propToRender={'name'}
                                    error={error.item_code}
                                />
                            </div>
                            <div style={{ width: '100%' }}>
                                <InputTextField
                                    fullwidth
                                    name="min_order_qty"
                                    value={stateItem.min_order_qty}
                                    handleChange={handleChange}
                                    type="number"
                                    label={t('core:minOrderQty')}
                                    helper={t('core:MinOrderDescrp')}
                                    isButton
                                />
                            </div>
                        </div>
                        <Divider sx={{ m: 2 }}>
                            <Chip label={t('core:price')} />
                        </Divider>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: '100%', margin: '10px' }}>
                            <div style={{ width: '100%', margin: '5px' }}>
                                <InputTextField
                                    fullwidth
                                    type="number"
                                    label={t('core:price')}
                                    name="price_list_rate"
                                    value={statePriceList.price_list_rate}
                                    error={error.price_list_rate}
                                    handleChange={handleChangePrice}
                                />
                            </div>
                            <div style={{ width: '100%', margin: '5px' }}>
                                <InputTextField
                                    fullwidth
                                    type="number"
                                    label={t('core:leadTimeInDay')}
                                    helper={t('core:leadTimeInDay')}
                                    name="lead_time_days"
                                    value={statePriceList.lead_time_days}
                                    handleChange={handleChangePrice}
                                />
                            </div>
                        </div>

                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: '100%', margin: '10px' }}>
                            <div style={{ width: '100%', margin: '5px' }}>
                                <FormDateTime
                                    fullwidth
                                    type="date"
                                    label={t('crm:validfrom')}
                                    name="valid_from"
                                    value={statePriceList.valid_from}
                                    handleChange={handleChangePrice}
                                    isValidDate
                                />
                            </div>
                            <div style={{ width: '100%', margin: '5px' }}>
                                <FormDateTime
                                    fullwidth
                                    type="date"
                                    label={t('crm:validtill')}
                                    name="valid_upto"
                                    value={statePriceList.valid_upto}
                                    error={error.valid_upto}
                                    handleChange={handleChangePrice}
                                    nextDate={moment(statePriceList?.valid_from).add(1, 'days').format('YYYY-MM-DD')}
                                />
                            </div>
                        </div>

                        <Box mt={3}>
                            <Grid container justifyContent={'center'} spacing={2}>
                                <Grid item>
                                    <Button
                                        startIcon={<RemoveCircleIcon color={'Menu'} />}
                                        variant="contained"
                                        color="error"
                                        onClick={handleCancel}
                                    >
                                        {t('common:cancel')}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <LoadingButton
                                        startIcon={<AddCircleRoundedIcon color={'Menu'} />}
                                        variant="contained"
                                        color="blue"
                                        onClick={handleSubmit}
                                        loading={loadingButton}
                                    >
                                        {t('common:save')}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </MainCard>
                </Drawer>
            </div>
            <Modal onClose={() => setOpenStepperImport(false)} open={openStepperImport}>
                {stepperImport}
            </Modal>
            <Modal onClose={handleCloseForm} open={openImportForm}>
                {doctypeImport == 'Item' ? itemImporter : priceItemImporter}
            </Modal>
        </Box>
    );
};

export default SupplierCatalog;
