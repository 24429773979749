import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box } from '@mui/system';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import MainCard from 'ui-component/cards/MainCard';
import InputTextField from 'ui-component/form/InputTextField';
import { useStyles } from 'views/utilities/Style';
import { SetNotification } from 'store/services/api';
import { useDispatch } from 'react-redux';
import useGetAxios from 'hooks/useGetAxios';
import Loader from 'ui-component/Loader';
import SelectForm from 'ui-component/form/SelectForm';
import useFetch from 'hooks/useFetch';
import { formatErpResponse } from 'utils/utils';
import { useTranslation } from 'react-i18next';

const SousTablelibreForm = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { id } = useParams();

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const libreReqData = {
        doctype: 'Table Libre',
        fields: ['`tabTable Libre`.`name`', '`tabTable Libre`.`table_code`', '`tabTable Libre`.`libelle`'],
        start: 0,
        page_length: 500
    };
    const { data: tablelibres, isPending: isTableLibresPending, refetch } = useFetch(`/api/get-list`, libreReqData, 'POST');

    const { data: doc, isPending } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=SousTable%20Libre&name=${id}`);

    const [loadingButton, setLoadingButton] = useState(false);

    const [formState, setFormState] = useState({
        table_code: '',
        libelle: '',
        table_libre: ''
    });

    const handleChange = (e) => {
        setFormState({ ...formState, [e.target.name]: e.target.value });
    };

    const editLibre = {
        ...(id && {
            creation: doc.creation,
            modified: doc.modified,
            owner: doc.owner,
            modified_by: doc.owner,
            name: doc.name,
            table_code: formState.table_code,
            libelle: formState.libelle,
            table_libre: formState.table_libre
        }),
        ...(id
            ? {}
            : {
                  doctype: 'SousTable Libre',
                  __islocal: 1,
                  __unsaved: 1
              }),
        table_code: formState.table_code,
        libelle: formState.libelle,
        table_libre: formState.table_libre
    };

    const handleEditLibre = async () => {
        setLoadingButton(true);
        const isEditing = !!id;
        const requestData = {
            doc: {
                ...(isEditing && {
                    creation: doc.creation,
                    modified: doc.modified,
                    owner: doc.owner,
                    modified_by: doc.owner,
                    name: doc.table_code
                }),
                doctype: 'SousTable Libre',
                table_code: formState.table_code,
                libelle: formState.libelle,
                table_libre: formState.table_libre
            },
            action: 'Save'
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify(requestData)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            if (data?.success === false) {
                dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
            } else {
                const successMessage = isEditing
                    ? 'La Sous-table libre a été modifiée avec succès.'
                    : 'La Sous-table libre a été créée avec succès.';
                dispatch(SetNotification({ message: successMessage, code: 'success' }));

                if (isEditing && formState.table_code !== doc.table_code) {
                    const dataFormRenameTitleCollection = {
                        doctype: 'SousTable Libre',
                        docname: doc.table_code,
                        new_name: formState.table_code
                    };

                    await fetch(`${process.env.REACT_APP_API_URI}/api/rename-doc`, {
                        method: 'POST',
                        headers: header,
                        body: JSON.stringify(dataFormRenameTitleCollection)
                    });
                }

                navigate('/pim/soustable-libre');
            }
        } catch (error) {
            dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
            console.error('Error:', error);
        } finally {
            setLoadingButton(false);
        }
    };

    //const handleChangeSelect = (event) => {
    //setState({ ...state, [event.target.name]: event.target.value });
    // };

    useEffect(() => {
        if (id && !Array.isArray(doc) && doc) {
            setFormState({
                ...formState,
                table_code: doc.table_code,
                libelle: doc.libelle,
                table_libre: doc.table_libre
            });
        }
    }, [id, doc]);

    const disabledFieldStyle = {
        opacity: 0.7,
        pointerEvents: 'none'
    };

    if (id && isPending) return <Loader />;

    return (
        <Box>
            <MainCard
                title={
                    <Box display={'flex'} flexDirection={'column'}>
                        <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
                        {id ? t('products:editsubtable') : t('products:newsubtable')} : {doc.libelle}
                    </Box>
                }
            >
                <Box>
                    <Box>
                        <InputTextField
                            sx={{
                                mt: 0,
                                ...(id ? disabledFieldStyle : {})
                            }}
                            error={formState.table_code === ''}
                            handleChange={handleChange}
                            value={formState?.table_code}
                            name="table_code"
                            required
                            disabled={!!id}
                            label={t('core:code')}
                            id="outlined-size-small"
                            size="small"
                            InputProps={{
                                readOnly: !!id
                            }}
                        />
                        <InputTextField
                            sx={{ mt: 0 }}
                            error={formState.libelle === ''}
                            handleChange={handleChange}
                            value={formState.libelle}
                            name="libelle"
                            required
                            label={t('core:libelle')}
                            id="outlined-size-small"
                            size="small"
                        />
                        <SelectForm
                            sx={{ mt: 0 }}
                            required
                            label={t('core:libre')}
                            value={formState.table_libre}
                            error={formState.table_libre === ''}
                            data={tablelibres}
                            propToRender={'name'}
                            name="table_libre"
                            handleChange={handleChange}
                            renderDisplay={(option) => option.libelle}
                        />
                    </Box>
                </Box>
                <Grid container xs={12} sm={12} md={8} lg={8} xl={8}>
                    <Grid item xs={12}>
                        <Box display={'flex'} justifyContent={'center'} mt={4}>
                            <Button sx={{ mr: 1 }} onClick={() => navigate(-1)}>
                                {t('common:cancel')}
                            </Button>
                            <LoadingButton
                                disabled={formState.table_code == '' || formState.libelle == ''}
                                size="large"
                                variant="contained"
                                color="blue"
                                loading={loadingButton}
                                onClick={handleEditLibre}
                            >
                                {t('common:save')}
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </MainCard>
        </Box>
    );
};

export default SousTablelibreForm;
