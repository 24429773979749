import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { CurrencyFormatter, formatErpResponse } from 'utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
    Avatar,
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    Modal
} from '@mui/material';
import { Box } from '@mui/system';
import useFetch from 'hooks/useFetch';
import { LoadingButton } from '@mui/lab';
import { SetNotification } from 'store/services/api';
import { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import LoyaltyRoundedIcon from '@mui/icons-material/LoyaltyRounded';
import EditIcon from '@mui/icons-material/Edit';
import { modalStyle } from 'views/utilities/ModalStyle';
import MainCard from 'ui-component/cards/MainCard';
import ModalForm from 'ui-component/form/ModalForm';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left'
    },
    avatar: {
        marginRight: theme.spacing(2)
    },
    username: {
        fontWeight: 'bold'
    },
    divider: {
        width: '100%',
        margin: theme.spacing(2, 0)
    },
    title: {
        fontWeight: 'bold',
        marginTop: theme.spacing(2)
    },
    text: {
        marginTop: theme.spacing(1)
    },
    pointsCard: {
        padding: 10,
        marginTop: 16,
        borderRadius: 10,
        backgroundColor: theme.palette.grey[200]
    }
}));

const CustomerDetailsCard = ({ doc, refetch }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const globalDefaults = useSelector((state) => state.login.globalDefaults);
    const [loadingButton, setLoadingButton] = useState(false);
    const [formattedData, setFormattedData] = useState([]);
    const [openEditCustomer, setOpenEditCustomer] = useState(false);
    const [loadingButtonForm, setLoadingButtonForm] = useState(false);

    const [state, setState] = useState({
        lp: '',
        tier: ''
    });

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const { data: lps, isLpsPending } = useFetch(
        `/api/get-list`,
        {
            doctype: 'Loyalty Program',
            fields: ['`tabLoyalty Program`.`name`', '`tabLoyalty Program Collection`.`tier_name`'],
            order_by: '`tabLoyalty Program`.`modified` desc'
        },
        'POST'
    );

    const handleChangeForm = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const handleRemove = () => {
        setState({
            lp: '',
            tier: ''
        });
    };

    const handleSaveLp = () => {
        setLoadingButton(true);
        const data = {
            doc: {
                name: doc.name,
                owner: doc.owner,
                creation: doc.creation,
                modified: doc.modified,
                modified_by: doc.modified_by,
                naming_series: 'CUST-.YYYY.-',
                docstatus: 0,
                code_client: doc.code_client,
                customer_name: doc.customer_name,
                customer_group: doc.customer_group,
                customer_type: doc.customer_type,
                territory: doc.territory,
                doctype: doc.doctype,
                __onload: doc.__onload,
                loyalty_program: state.lp,
                __unsaved: 1,
                loyalty_program_tier: state.tier
            },
            action: 'Save'
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(
                        SetNotification({ code: 'success', message: `Loyalty information for the customer has been successfully saved.` })
                    );
                    refetch({});
                }
            });
    };

    useEffect(() => {
        if (lps && lps?.length !== 0) {
            const result = Object.values(
                lps.reduce((acc, cur) => {
                    if (acc[cur.name]) {
                        acc[cur.name].push(cur.tier_name);
                    } else {
                        acc[cur.name] = [cur.tier_name];
                    }
                    return acc;
                }, {})
            ).map((tier_names, index) => ({
                name: Object.keys(
                    lps.reduce((acc, cur) => {
                        acc[cur.name] = true;
                        return acc;
                    }, {})
                )[index],
                tier_names
            }));
            setFormattedData(result);
        }
    }, [lps]);

    useEffect(() => {
        if (doc) {
            setState({
                lp: doc?.loyalty_program,
                tier: doc?.loyalty_program_tier
            });
        }
    }, [doc]);

    const handleCloseForm = () => setOpenEditCustomer(false);
    const editCustomer = (
        <Box sx={modalStyle}>
            <MainCard divider title={t('crm:editcustomer')}>
                <ModalForm
                    handleClose={handleCloseForm}
                    loadingButton={loadingButtonForm}
                    setLoadingButton={setLoadingButtonForm}
                    refetch={refetch}
                    doc={doc}
                    isEdit={true}
                />
            </MainCard>
        </Box>
    );
    return (
        <div>
            <Card className={classes.root}>
                <CardContent>
                    <Box display="flex" justifyContent={'space-between'} height={33}>
                        <Box display={'flex'} flexDirection={'row'} alignItems="center">
                            <Avatar className={classes.avatar} />
                            <Typography className={classes.username}>
                                {doc?.salutation} {doc?.customer_name}
                            </Typography>
                        </Box>
                        <Button
                            startIcon={<EditIcon />}
                            variant="outlined"
                            color="blue"
                            sx={{ ml: 1 }}
                            onClick={() => {
                                setOpenEditCustomer(true);
                            }}
                        >
                            {t('crm:edit')}
                        </Button>
                    </Box>
                    <Divider className={classes.divider} />
                    <Typography variant="subtitle1" color="textSecondary" className={classes.title}>
                        {t('crm:cname')}:
                    </Typography>
                    <Typography variant="body1" className={classes.text}>
                        {doc?.salutation} {doc?.customer_name}
                    </Typography>
                    {doc?.mobile_no && (
                        <>
                            <Typography variant="subtitle1" color="textSecondary" className={classes.title}>
                                {t('crm:phonenum')}:
                            </Typography>
                            <Typography variant="body1" className={classes.text}>
                                {doc?.mobile_no}
                            </Typography>
                        </>
                    )}
                    {doc?.email_id && (
                        <>
                            <Typography variant="subtitle1" color="textSecondary" className={classes.title}>
                                Email:
                            </Typography>
                            <Typography variant="body1" className={classes.text}>
                                {doc?.email_id}
                            </Typography>
                        </>
                    )}
                    <Typography variant="subtitle1" color="textSecondary" className={classes.title}>
                        {t('crm:addr')}:
                    </Typography>
                    <Typography variant="body1" className={classes.text}>
                        {doc?.primary_address
                            ? formatErpResponse(doc?.primary_address)
                            : doc?.__onload?.addr_list[0]?.display && formatErpResponse(doc?.__onload?.addr_list[0]?.display)}
                    </Typography>
                    {doc?.territory && (
                        <>
                            <Typography variant="subtitle1" color="textSecondary" className={classes.title}>
                                {t('crm:trrtry')}:
                            </Typography>
                            <Typography variant="body1" className={classes.text}>
                                {doc?.territory}
                            </Typography>
                        </>
                    )}
                    <Typography variant="subtitle1" color="textSecondary" className={classes.title}>
                        {t('crm:annbill')}:
                    </Typography>
                    <Typography variant="body1" className={classes.text}>
                        <CurrencyFormatter
                            locale="fr-FR"
                            currency={globalDefaults?.default_currency}
                            minimumFractionDigits={3}
                            value={doc?.__onload?.dashboard_info[0]?.billing_this_year ?? 0}
                        />
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary" className={classes.title}>
                        {t('crm:ttlunpaid')}:
                    </Typography>
                    <Typography variant="body1" className={classes.text}>
                        <CurrencyFormatter
                            locale="fr-FR"
                            currency={globalDefaults?.default_currency}
                            minimumFractionDigits={3}
                            value={doc?.__onload?.dashboard_info[0]?.total_unpaid ?? 0}
                        />
                    </Typography>
                    <Divider sx={{ m: 2 }}>
                        <Chip
                            label={
                                <Box display={'flex'} alignItems={'center'} fontWeight={'bold'} color={'CaptionText'}>
                                    {t('crm:lyltysec')} <LoyaltyRoundedIcon sx={{ width: 20, ml: 1 }} />
                                </Box>
                            }
                        />
                    </Divider>
                    <>
                        <FormControl sx={{ width: '100%', mb: 2 }}>
                            <InputLabel id="demo-simple-select-label">{t('crm:lp')}</InputLabel>
                            <Select
                                name={'lp'}
                                value={state.lp}
                                onChange={handleChangeForm}
                                labelId="demo-simple-select-label"
                                id="demo-multiple-name"
                                label={t('crm:lp')}
                                endAdornment={
                                    state.lp && (
                                        <IconButton sx={{ marginRight: '5%' }} onClick={handleRemove}>
                                            <CloseIcon color="error" />
                                        </IconButton>
                                    )
                                }
                            >
                                {formattedData?.map((row, i) => (
                                    <MenuItem key={`${row?.name}-${i}`} value={row?.name}>
                                        {row?.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id="demo-simple-select-label">{t('crm:lpt')}</InputLabel>
                            <Select
                                name={'tier'}
                                value={state.tier}
                                onChange={handleChangeForm}
                                labelId="demo-simple-select-label"
                                id="demo-multiple-name"
                                label={t('crm:lpt')}
                            >
                                {state.lp &&
                                    formattedData
                                        ?.filter((el) => el?.name === state.lp)?.[0]
                                        ?.tier_names?.map((row, i) => (
                                            <MenuItem key={`${'tier'}-${i}`} value={row}>
                                                {row}
                                            </MenuItem>
                                        ))}
                            </Select>
                        </FormControl>
                        <Box className={classes.pointsCard}>
                            <Typography variant="overline" color="CaptionText" fontWeight={'bold'}>
                                {t('crm:lpp')}: {doc?.__onload?.dashboard_info[0]?.loyalty_points ?? 0}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'right', mt: 2 }}>
                            <LoadingButton
                                size="large"
                                loading={loadingButton}
                                variant="contained"
                                color="blue"
                                onClick={handleSaveLp}
                                disabled={state.lp === doc?.loyalty_program && state.tier === doc?.loyalty_program_tier}
                            >
                                {t('crm:save')}
                            </LoadingButton>
                        </Box>
                    </>
                </CardContent>
            </Card>
            <Modal open={openEditCustomer} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                {editCustomer}
            </Modal>
        </div>
    );
};

export default CustomerDetailsCard;
