import { LoadingButton } from '@mui/lab';
import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MainCard from 'ui-component/cards/MainCard';
import SelectForm from 'ui-component/form/SelectForm';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useStyles } from 'views/utilities/Style';
import { useNavigate } from 'react-router';
import useFetch from 'hooks/useFetch';
import InputTextField from 'ui-component/form/InputTextField';
import Loader from 'ui-component/Loader';
import { SetBulkEditFields, SetBulkEditIds, SetNotification } from 'store/services/api';
import { formatErpResponse } from 'utils/utils';
import { useTranslation } from 'react-i18next';

const BulkEditForm = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    // initial form state
    // >>>
    const [formState, setFormState] = useState({});
    const [formErrState, setFormErrState] = useState({});

    // fetching form data
    // >>>

    const { data: UOMData, isPending: isUOMPending } = useFetch(
        `/api/get-list`,
        { doctype: 'UOM', fields: ['`tabUOM`.`name`'], start: 0, page_length: 20 },
        'POST'
    );

    const { data: brands, isPending: isBrandsPending } = useFetch(
        `/api/get-list`,
        { doctype: 'Brand', fields: ['`tabBrand`.`name`'], start: 0, page_length: 100 },
        'POST'
    );

    const { data: itemGroups, isPending: isItemGroupPending } = useFetch(
        `/api/get-list`,
        { doctype: 'Item Group', fields: ['`tabItem Group`.`name`'], start: 0, page_length: 500 },
        'POST'
    );

    const [loadingButton, setLoadingButton] = useState(false);
    const bulkEditIds = useSelector((state) => state.pim.bulkEditIds);
    const bulkEditFields = useSelector((state) => state.pim.bulkEditFields);

    const handleChange = (e) => {
        if (
            [
                'weight_per_unit',
                'valuation_rate',
                'standard_selling_rate',
                'safety_stock',
                'price_list_rate',
                'max_discount',
                'disabled'
            ].includes(e.target.name)
        ) {
            if (parseFloat(e.target.value) && parseFloat(e.target.value) >= 0) {
                if (e.target.name == 'max_discount') {
                    e.target.value <= 100 && setFormState({ ...formState, [e.target.name]: parseFloat(e.target.value) });
                } else {
                    setFormState({ ...formState, [e.target.name]: parseFloat(e.target.value) });
                }
            }
        } else {
            setFormState({ ...formState, [e.target.name]: e.target.value });
        }

        // err handlers
        if (e.target.value == '') {
            setFormErrState({ ...formErrState, [e.target.name]: true });
        } else {
            setFormErrState({ ...formErrState, [e.target.name]: false });
        }
    };

    const handleSelectChange = (event) => {
        const {
            target: { value }
        } = event;
        setFormState({ ...formState, [event.target.name]: value });
        setFormErrState({ ...formErrState, [event.target.name]: false });
    };

    // form sumbittion
    // >>>
    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const formVerif = () => {
        var hasErrs = false;
        bulkEditFields.forEach((el) => {
            if (formState[el] === '') {
                setFormErrState({ ...formErrState, [el]: true });
                hasErrs = true;
            }
        });
        if (!hasErrs) handleBulkEdit();
    };

    const handleBulkEdit = () => {
        const bulkEditData = {
            doctype: 'Item',
            freeze: true,
            docnames: bulkEditIds,
            action: 'update',
            data: formState
        };
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/update-status`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(bulkEditData)
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                } else {
                    dispatch(SetNotification({ code: 'success', message: t('products:stat_modif_success') }));
                    navigate('/pim/products-view');
                }
            });
    };

    useEffect(() => {
        if (bulkEditFields?.length) {
            var form = {};
            var formErr = {};
            bulkEditFields.forEach((el) => {
                if (
                    [
                        'weight_per_unit',
                        'valuation_rate',
                        'standard_selling_rate',
                        'safety_stock',
                        'price_list_rate',
                        'max_discount',
                        'disabled'
                    ].includes(el)
                ) {
                    formErr = { ...formErr, [el]: false };
                    form = { ...form, [el]: 0 };
                } else {
                    formErr = { ...formErr, [el]: false };
                    form = { ...form, [el]: '' };
                }
            });
            setFormState(form);
            setFormErrState(formErr);
        }
    }, [bulkEditFields]);

    const backLink = () => {
        navigate(-1);
        dispatch(SetBulkEditIds([]));
        dispatch(SetBulkEditFields([]));
    };

    const title = (
        <Box display={'flex'} flexDirection={'column'}>
            <ArrowBackIcon className={classes.backLink} onClick={() => backLink()} />
            {`Editer en masse (${bulkEditIds?.length} produits à editer)`}
        </Box>
    );

    if (isBrandsPending || isItemGroupPending || isUOMPending) return <Loader />;

    return (
        <div>
            <MainCard title={title}>
                <Box p={5} sx={{ borderRadius: 2, bgcolor: 'Menu' }}>
                    <Box>
                        <Box>
                            {bulkEditFields.includes('disabled') && (
                                <FormControlLabel
                                    sx={{ mb: 1 }}
                                    label={<Typography sx={{ fontWeight: 500 }}>Désactivé</Typography>}
                                    control={
                                        <Checkbox
                                            color="blue"
                                            checked={!!formState.disabled}
                                            onChange={() => setFormState({ ...formState, disabled: !formState.disabled })}
                                        />
                                    }
                                />
                            )}
                            {bulkEditFields.includes('default_unit_of_measure') && (
                                <SelectForm
                                    label={'UDM par défaut'}
                                    data={UOMData}
                                    error={formErrState?.default_unit_of_measure}
                                    handleChange={handleSelectChange}
                                    value={formState?.default_unit_of_measure}
                                    name={'default_unit_of_measure'}
                                    propToRender={'name'}
                                    required
                                    sx={{ mb: 1 }}
                                />
                            )}
                            {bulkEditFields.includes('weight_uom') && (
                                <SelectForm
                                    label={'UDM du poids'}
                                    data={UOMData}
                                    error={formErrState?.weight_uom}
                                    handleChange={handleSelectChange}
                                    value={formState?.weight_uom}
                                    name={'weight_uom'}
                                    propToRender={'name'}
                                    required
                                    sx={{ mb: 1 }}
                                />
                            )}
                            {bulkEditFields.includes('weight_per_unit') && (
                                <InputTextField
                                    type="number"
                                    name={'weight_per_unit'}
                                    error={formErrState?.weight_per_unit}
                                    handleChange={handleChange}
                                    value={formState?.weight_per_unit}
                                    required
                                    label={'Poids par unité'}
                                    sx={{ mb: 1 }}
                                />
                            )}
                            {bulkEditFields.includes('safety_stock') && (
                                <InputTextField
                                    type="number"
                                    name={'safety_stock'}
                                    error={formErrState?.safety_stock}
                                    handleChange={handleChange}
                                    value={formState?.safety_stock}
                                    required
                                    label={'Stock sécurité'}
                                    sx={{ mb: 1 }}
                                />
                            )}
                            {bulkEditFields.includes('valuation_rate') && (
                                <InputTextField
                                    type="number"
                                    name={'valuation_rate'}
                                    error={formErrState?.valuation_rate}
                                    handleChange={handleChange}
                                    value={formState?.valuation_rate}
                                    required
                                    label={t('dashboard:valuationRate')}
                                    sx={{ mb: 1 }}
                                />
                            )}
                            {bulkEditFields.includes('standard_selling_rate') && (
                                <InputTextField
                                    type="number"
                                    name={'standard_rate'}
                                    error={formErrState?.standard_rate}
                                    handleChange={handleChange}
                                    value={formState?.standard_rate}
                                    required
                                    label={t('dashboard:standardRate')}
                                    sx={{ mb: 1 }}
                                />
                            )}
                            {bulkEditFields.includes('price_list_rate') && (
                                <InputTextField
                                    type="number"
                                    name={'price_list_rate'}
                                    error={formErrState?.price_list_rate}
                                    handleChange={handleChange}
                                    value={formState?.price_list_rate}
                                    required
                                    label={t('products:Taux_de_la_liste_de_prix')}
                                    sx={{ mb: 1 }}
                                />
                            )}
                            {bulkEditFields.includes('max_discount') && (
                                <InputTextField
                                    isPercentage
                                    type="number"
                                    name={'max_discount'}
                                    error={formErrState?.max_discount}
                                    handleChange={handleChange}
                                    value={formState?.max_discount}
                                    required
                                    label={'Réduction maximale (%)'}
                                    sx={{ mb: 1 }}
                                />
                            )}
                            {bulkEditFields.includes('item_name') && (
                                <InputTextField
                                    name={'item_name'}
                                    error={formErrState?.item_name}
                                    handleChange={handleChange}
                                    value={formState?.item_name}
                                    required
                                    label={t('products:productName')}
                                    sx={{ mb: 1 }}
                                />
                            )}
                            {bulkEditFields.includes('item_code') && (
                                <InputTextField
                                    name={'item_code'}
                                    error={formErrState?.item_code}
                                    handleChange={handleChange}
                                    value={formState?.item_code}
                                    required
                                    label={t('products:productCode')}
                                    sx={{ mb: 1 }}
                                />
                            )}
                            {bulkEditFields.includes('description') && (
                                <InputTextField
                                    name={'description'}
                                    error={formErrState?.description}
                                    handleChange={handleChange}
                                    value={formState?.description}
                                    required
                                    label={t('common:description')}
                                    sx={{ mb: 1 }}
                                />
                            )}
                            {bulkEditFields.includes('item_group') && (
                                <SelectForm
                                    label={t('products:productCategory')}
                                    data={itemGroups}
                                    error={formErrState?.item_group}
                                    handleChange={handleSelectChange}
                                    value={formState?.item_group}
                                    name={'item_group'}
                                    propToRender={'name'}
                                    required
                                    sx={{ mb: 1 }}
                                />
                            )}
                            {bulkEditFields.includes('brand') && (
                                <SelectForm
                                    label={t('products:brand')}
                                    data={brands}
                                    error={formErrState?.brand}
                                    handleChange={handleSelectChange}
                                    value={formState?.brand}
                                    name={'brand'}
                                    propToRender={'name'}
                                    required
                                    sx={{ mb: 1 }}
                                />
                            )}
                        </Box>
                        {/* SUBMITTION BUTTON */}
                        <Grid container xs={12} sm={12} md={8} lg={8} xl={8}>
                            <Grid item xs={12}>
                                <Box display={'flex'} justifyContent={'center'} mt={4}>
                                    <Button variant="outlined" size="large" color="blue" onClick={() => backLink()}>
                                        {t('common:cancel')}
                                    </Button>
                                    <LoadingButton
                                        sx={{ ml: 1 }}
                                        loading={loadingButton}
                                        variant="contained"
                                        size="large"
                                        color="blue"
                                        onClick={() => formVerif()}
                                    >
                                        {t('common:save')}
                                    </LoadingButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </MainCard>
        </div>
    );
};

export default BulkEditForm;
