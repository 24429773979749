import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box } from '@mui/system';
import InputTextField from 'ui-component/form/InputTextField';
import SelectForm from 'ui-component/form/SelectForm';
import InputCheckboxField from 'ui-component/form/InputCheckboxField';
import { LoadingButton } from '@mui/lab';
import { useEffect } from 'react';
import Loader from 'ui-component/Loader';
import { formatErpResponse, formatServerMessages } from 'utils/utils';
import { useDispatch } from 'react-redux';
import { SetNotification } from 'store/services/api';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useCallback } from 'react';
import axios from 'axios';

const ProductNamingSeries = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loadingButton, setLoadingButton] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [loadingOptions, setLoadingOptions] = React.useState(false);
    const [loadingPreview, setLoadingPreview] = React.useState(false);
    const [loadingSeriesNumberButton, setLoadingSeriesNumberButton] = React.useState(false);
    const [itemFields, setItemFields] = React.useState(null);
    const [doc, setDoc] = React.useState(null);

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const axiosHeader = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem('user'))?.token
        }
    };

    useEffect(() => {
        setLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_URI}/api/details?doctype=Naming+Series&name=Naming+Series`, axiosHeader, {
                body: null
            })
            .then(({ data }) => {
                setDoc(data?.docs[0]);
                if (data) {
                    fetch(`${process.env.REACT_APP_API_URI}/api/run-method`, {
                        method: 'POST',
                        headers: header,
                        body: JSON.stringify({
                            method: 'get_transactions',
                            docs: {
                                name: 'Naming Series',
                                owner: data?.docs[0].owner,
                                creation: data?.docs[0].creation,
                                modified: data?.docs[0].modified,
                                modified_by: data?.docs[0].modified_by,
                                idx: '0',
                                docstatus: 0,
                                select_doc_for_series: '',
                                set_options: '',
                                user_must_always_select: 0,
                                prefix: '',
                                current_value: 0,
                                doctype: 'Naming Series'
                            }
                        })
                    })
                        .then((res) => {
                            setLoading(false);
                            if (!res.ok) {
                                dispatch(SetNotification({ code: 'error', message: 'An error has occurred' }));
                            }
                            return res.json();
                        })
                        .then((data) => {
                            if (data) {
                                setItemFields(data?.message);
                            }
                        });
                }
            })
            .catch((err) => {});
    }, []);

    const [state, setState] = React.useState({
        select_doc_for_series: 'Item',
        naming_series_to_check: '',
        set_options: '',
        user_must_always_select: 0,
        prefix: '',
        current_value: 0
    });

    const [previews, setPreviews] = React.useState('');
    const handleChange = (event) => {
        const { name, value } = event.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangeCheckbox = (event, value) => {
        setState({ ...state, [event.target.name]: value ? 1 : 0 });
    };
    const handleReset = (fieldToReset) => {
        const updatedFormState = { ...state };
        updatedFormState[fieldToReset] = '';
        setState(updatedFormState);
    };

    const fetchData = useCallback(async () => {
        try {
            setLoadingOptions(true);
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/run-method`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify({
                    docs: {
                        name: 'Naming Series',
                        owner: doc.awner,
                        creation: doc.creation,
                        modified: doc.modified,
                        modified_by: doc.modified_by,
                        idx: '0',
                        docstatus: 0,
                        select_doc_for_series: state.select_doc_for_series,
                        naming_series_to_check: state.naming_series_to_check,
                        set_options: state.set_options,
                        user_must_always_select: state.user_must_always_select,
                        prefix: state.prefix,
                        current_value: state.current_value,
                        doctype: 'Naming Series'
                    },
                    method: 'get_options'
                })
            });

            if (!response.ok) {
                throw new Error('Something went wrong');
            }

            const data = await response.json();

            setState((prevState) => ({
                ...prevState,
                set_options: data?.message
            }));
        } catch (error) {
        } finally {
            setLoadingOptions(false);
        }
    }, [state.select_doc_for_series]);

    const fetchPreviewSerie = useCallback(async () => {
        try {
            setLoadingPreview(true);
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/run-method`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify({
                    docs: {
                        name: 'Naming Series',
                        owner: doc.awner,
                        creation: doc.creation,
                        modified: doc.modified,
                        modified_by: doc.modified_by,
                        idx: '0',
                        docstatus: 0,
                        select_doc_for_series: state.select_doc_for_series,
                        naming_series_to_check: state.naming_series_to_check,
                        set_options: state.set_options,
                        user_must_always_select: state.user_must_always_select,
                        prefix: state.prefix,
                        current_value: state.current_value,
                        doctype: 'Naming Series'
                    },
                    method: 'preview_series'
                })
            });

            if (!response.ok) {
                throw new Error('Something went wrong');
            }

            const data = await response.json();
            setPreviews(data?.message);
        } catch (error) {
        } finally {
            setLoadingPreview(false);
        }
    }, [state.naming_series_to_check]);

    useEffect(() => {
        if (state.naming_series_to_check !== '') {
            fetchPreviewSerie();
        }
    }, [fetchPreviewSerie]);

    useEffect(() => {
        if (state.select_doc_for_series !== '') {
            fetchData();
        }
    }, [fetchData]);

    const updateSeries = () => {
        setLoadingButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/run-method`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({
                docs: {
                    name: 'Naming Series',
                    owner: doc.awner,
                    creation: doc.creation,
                    modified: doc.modified,
                    modified_by: doc.modified_by,
                    idx: '0',
                    docstatus: 0,
                    select_doc_for_series: state.select_doc_for_series,
                    naming_series_to_check: state.naming_series_to_check,
                    set_options: state.set_options,
                    user_must_always_select: state.user_must_always_select,
                    prefix: state.prefix,
                    current_value: state.current_value,
                    doctype: 'Naming Series'
                },
                method: 'update_series'
            })
        })
            .then((res) => {
                setLoadingButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: "Une erreur s'est produite" }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?._server_messages) {
                    dispatch(SetNotification({ code: 'info', message: formatServerMessages(data?._server_messages)[0]?.message }));
                    return;
                }
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                    return;
                }
            });
    };

    const updateSeriesNumber = () => {
        setLoadingSeriesNumberButton(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/run-method`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify({
                docs: {
                    name: 'Naming Series',
                    owner: doc.awner,
                    creation: doc.creation,
                    modified: doc.modified,
                    modified_by: doc.modified_by,
                    idx: '0',
                    docstatus: 0,
                    select_doc_for_series: state.select_doc_for_series,
                    naming_series_to_check: state.naming_series_to_check,
                    set_options: state.set_options,
                    user_must_always_select: state.user_must_always_select,
                    prefix: state.prefix,
                    current_value: state.current_value,
                    doctype: 'Naming Series'
                },
                method: 'update_series_start'
            })
        })
            .then((res) => {
                setLoadingSeriesNumberButton(false);
                if (!res.ok) {
                    dispatch(SetNotification({ code: 'error', message: "Une erreur s'est produite" }));
                }
                return res.json();
            })
            .then((data) => {
                if (data?._server_messages) {
                    dispatch(SetNotification({ code: 'info', message: formatServerMessages(data?._server_messages)[0]?.message }));

                    return;
                }
                if (data?.success === false) {
                    dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                    return;
                }
            });
    };

    const prefixArray = itemFields && itemFields?.prefixes && itemFields?.prefixes?.split('\n').filter((prefix) => prefix.trim() !== '');

    if (loading) return <Loader />;

    return (
        <>
            <MainCard
                border
                isWhite
                boxShadow
                divider
                title={
                    <Box display={'flex'} alignItems={'center'}>
                        <SettingsIcon sx={{ mr: 1 }} />
                        {t('products:Product_Prefix')}
                    </Box>
                }
                sx={{ height: '100%', mt: 2 }}
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', height: '100%', gap: 20 }}>
                    <div style={{ width: '100%' }}>
                        <MainCard
                            border
                            isWhite
                            boxShadow
                            title={
                                <Box display={'flex'} alignItems={'center'}>
                                    <SettingsIcon sx={{ mr: 1 }} />
                                    {t('settings:sss')}
                                </Box>
                            }
                            sx={{ height: '100%' }}
                        >
                            <Box>
                                <Box hidden>
                                    <SelectForm
                                        fullwidth
                                        label={t('settings:st')}
                                        data={['Item']}
                                        name="select_doc_for_series"
                                        value={state.select_doc_for_series || 'Item'}
                                        sx={{ mb: 2 }}
                                        handleChange={handleChange}
                                        reset={state.select_doc_for_series !== ''}
                                        handleReset={() => handleReset('select_doc_for_series')}
                                    />
                                </Box>

                                {state.select_doc_for_series && (
                                    <>
                                        <div>
                                            <Typography variant="h6" color="primary" fontWeight={800}>
                                                {t('settings:text-1')}
                                            </Typography>
                                            <ul>
                                                <li>
                                                    <Typography variant="h6" color="primary" fontWeight={600}>
                                                        {t('settings:text-2')}
                                                    </Typography>
                                                </li>
                                                <li>
                                                    <Typography variant="h6" color="primary" fontWeight={600}>
                                                        {t('settings:text-3')}
                                                    </Typography>
                                                </li>
                                                <li>
                                                    <Typography variant="h6" color="primary" fontWeight={600}>
                                                        {t('settings:text-4')}
                                                    </Typography>
                                                </li>
                                                <li>
                                                    <Typography variant="h6" color="primary" fontWeight={600}>
                                                        {t('settings:text-5')}
                                                    </Typography>
                                                    <Typography variant="h6" color="primary" fontWeight={600}>
                                                        {t('settings:text-6')}
                                                    </Typography>

                                                    <ul>
                                                        <li>
                                                            <Typography variant="h6" color="primary" fontWeight={600}>
                                                                <strong>.YYYY.</strong>- {t('settings:text-7')}
                                                            </Typography>
                                                        </li>
                                                        <li>
                                                            <Typography variant="h6" color="primary" fontWeight={600}>
                                                                <strong>.YY.</strong>- {t('settings:text-8')}
                                                            </Typography>
                                                        </li>
                                                        <li>
                                                            <Typography variant="h6" color="primary" fontWeight={600}>
                                                                <strong>.MM.</strong>- {t('settings:text-9')}
                                                            </Typography>
                                                        </li>
                                                        <li>
                                                            <Typography variant="h6" color="primary" fontWeight={600}>
                                                                <strong>.DD.</strong>- {t('settings:text-10')}
                                                            </Typography>
                                                        </li>
                                                        <li>
                                                            <Typography variant="h6" color="primary" fontWeight={600}>
                                                                <strong>.WW.</strong>- {t('settings:text-11')}
                                                            </Typography>
                                                        </li>
                                                        <li>
                                                            <Typography variant="h6" color="primary" fontWeight={600}>
                                                                <strong>.FY.</strong>- {t('settings:text-12')}
                                                            </Typography>
                                                        </li>
                                                        <li>
                                                            <Typography variant="h6" color="primary" fontWeight={600}>
                                                                <strong>.{'fieldname'}.</strong>- {t('settings:text-13')}
                                                            </Typography>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                        <div>
                                            <Typography variant="h6" color="primary" fontWeight={800}>
                                                {t('settings:text-14')}
                                            </Typography>
                                            <ul>
                                                <li>
                                                    <Typography variant="h6" color="primary" fontWeight={600}>
                                                        INV-
                                                    </Typography>
                                                </li>
                                                <li>
                                                    <Typography variant="h6" color="primary" fontWeight={600}>
                                                        INV-10-
                                                    </Typography>
                                                </li>
                                                <li>
                                                    <Typography variant="h6" color="primary" fontWeight={600}>
                                                        INVK-
                                                    </Typography>
                                                </li>
                                                <li>
                                                    <Typography variant="h6" color="primary" fontWeight={600}>
                                                        INV-.YYYY.-. <br />
                                                        {'branch'}.-.MM.-.####
                                                    </Typography>
                                                </li>
                                            </ul>
                                        </div>
                                    </>
                                )}

                                <InputTextField
                                    type="text"
                                    label={t('settings:text-15')}
                                    name="naming_series_to_check"
                                    value={state.naming_series_to_check}
                                    sx={{ mb: 2 }}
                                    handleChange={handleChange}
                                />
                                {state.naming_series_to_check && (
                                    <>
                                        <Typography variant="h6" color="primary" fontWeight={800}>
                                            {t('settings:text-16')}
                                        </Typography>

                                        {loadingPreview ? (
                                            <CircularProgress />
                                        ) : (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column'
                                                }}
                                            >
                                                <Typography variant="h6" color="primary" fontWeight={600}>
                                                    {previews ?? previews}
                                                </Typography>
                                            </div>
                                        )}
                                    </>
                                )}

                                {loadingOptions ? (
                                    <CircularProgress />
                                ) : (
                                    state.select_doc_for_series && (
                                        <InputTextField
                                            type="text"
                                            label={t('settings:text-17')}
                                            name="set_options"
                                            value={state.set_options}
                                            sx={{ mb: 2, mt: 2 }}
                                            handleChange={handleChange}
                                            multiline
                                        />
                                    )
                                )}

                                <InputCheckboxField
                                    fullwidth
                                    label={t('settings:text-18')}
                                    name="user_must_always_select"
                                    value={state.user_must_always_select === 1 ? true : false}
                                    sx={{ mb: 2, alignItems: 'left' }}
                                    handleChange={handleChangeCheckbox}
                                />
                                <Typography variant="h6" color="primary" fontWeight={600}>
                                    {t('settings:text-19')}
                                </Typography>
                                <Box display={'flex'} justifyContent={'left'} mt={3}>
                                    <LoadingButton
                                        variant="outlined"
                                        size="large"
                                        color="blue"
                                        loading={loadingButton}
                                        onClick={updateSeries}
                                    >
                                        {t('settings:text-20')}
                                    </LoadingButton>
                                </Box>
                            </Box>
                        </MainCard>
                    </div>
                    <div style={{ width: '100%' }}>
                        <MainCard
                            border
                            isWhite
                            boxShadow
                            title={
                                <Box display={'flex'} alignItems={'center'}>
                                    <SettingsIcon sx={{ mr: 1 }} />
                                    {t('settings:text-21')}
                                </Box>
                            }
                            sx={{ height: '100%' }}
                        >
                            <Box display={'flex'} mt={-4} mb={2}>
                                <InfoIcon sx={{ mt: 0.6 }} />
                                <Typography p={1} variant="h6" color="primary">
                                    {t('settings:text-22')}
                                </Typography>
                            </Box>
                            <Box mt={2}>
                                <SelectForm
                                    label={t('settings:text-23')}
                                    data={prefixArray}
                                    name="prefix"
                                    value={state.prefix}
                                    sx={{ mb: 2 }}
                                    handleChange={handleChange}
                                    reset={state.prefix != ''}
                                    handleReset={() => handleReset('prefix')}
                                />
                                <InputTextField
                                    type="number"
                                    label={t('settings:text-24')}
                                    name="current_value"
                                    value={state.current_value}
                                    sx={{ mb: 2 }}
                                    handleChange={handleChange}
                                    helper={t('settings:text-26')}
                                />
                                <Box display={'flex'} justifyContent={'left'} mt={3}>
                                    <LoadingButton
                                        variant="outlined"
                                        size="large"
                                        color="blue"
                                        loading={loadingSeriesNumberButton}
                                        onClick={updateSeriesNumber}
                                    >
                                        {t('settings:text-25')}
                                    </LoadingButton>
                                </Box>
                            </Box>
                        </MainCard>
                    </div>
                </div>
            </MainCard>
        </>
    );
};

export default ProductNamingSeries;
