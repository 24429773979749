import React, { useState } from 'react';
import { Paper, Typography, List, ListItem, ListItemIcon, ListItemText, Checkbox, Button, Pagination } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import useFetch from 'hooks/useFetch';
import { useTheme } from '@mui/material/styles';

const SupplierDownloader = () => {
    const [selectedSuppliers, setSelectedSuppliers] = useState([]);
    const [page, setPage] = useState(1);
    const { t } = useTranslation(['common']);
    const theme = useTheme();

    const itemsPerPage = 5;

    const supplierReqData = {
        doctype: 'Supplier',
        fields: ['*'],
        order_by: '`tabSupplier`.`modified` desc',
        start: 0,
        page_length: 500
    };

    const { data: suppliersData, isPending, refetch } = useFetch(`/api/get-list`, supplierReqData, 'POST');

    const handleToggle = (value) => () => {
        const currentIndex = selectedSuppliers.indexOf(value);
        const newChecked = [...selectedSuppliers];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setSelectedSuppliers(newChecked);
    };

    const handleDownload = () => {
        if (selectedSuppliers.length === 0) {
            alert(t('common:pleaseSelectAtLeastOneSupplier'));
            return;
        }

        const selectedSuppliersData = suppliersData.filter((supplier) => selectedSuppliers.includes(supplier.name));

        const worksheetData = selectedSuppliersData.map((supplier) => ({
            'Supplier Name': supplier.supplier_name,
            'Supplier Code': supplier.supplier_code,
            PAN: supplier.pan,
            'Supplier Code Settings': supplier.supplier_code_settings,
            'Supplier Type': supplier.supplier_type,
            'Supplier Group': supplier.supplier_group,
            'Allow Purchase Invoice Creation Without Purchase Order': supplier.allow_purchase_invoice_creation_without_purchase_order,
            'Allow Purchase Invoice Creation Without Purchase Receipt': supplier.allow_purchase_invoice_creation_without_purchase_receipt,
            'Warn RFQs': supplier.warn_rfqs,
            'Warn POs': supplier.warn_pos,
            'Prevent RFQs': supplier.prevent_rfqs,
            'Prevent POs': supplier.prevent_pos,
            'Is Transporter': supplier.is_transporter,
            'Is Internal Supplier': supplier.is_internal_supplier,
            Disabled: supplier.disabled,
            IRS_1099: supplier.irs_1099,
            'On Hold': supplier.on_hold,
            'Hold Type': supplier.hold_type,
            'Release Date': supplier.release_date,
            'Tax ID': supplier.tax_id,
            Country: supplier.country,
            'Represents Company': supplier.represents_company,
            'Tax Category': supplier.tax_category,
            'Email ID': supplier.email_id,
            'Mobile No': supplier.mobile_no,
            'Address Line1': supplier.address_line1,
            City: supplier.city,
            Companies: supplier.companies,
            'Default Currency': supplier.default_currency,
            'Default Price List': supplier.default_price_list,
            'Tax Withholding Category': supplier.tax_withholding_category,
            'Default Bank Account': supplier.default_bank_account,
            'Payment Terms': supplier.payment_terms
        }));

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(worksheetData);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Suppliers');

        XLSX.writeFile(workbook, 'suppliers.xlsx');
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const paginatedData = suppliersData ? suppliersData.slice((page - 1) * itemsPerPage, page * itemsPerPage) : [];

    return (
        <Paper style={{ padding: '20px', maxWidth: '500px', margin: 'auto' }}>
            <Typography variant="h6" gutterBottom>
                {t('core:exportlist')}
            </Typography>
            <List dense>
                {paginatedData.map((supplier) => {
                    const labelId = `checkbox-list-label-${supplier.name}`;

                    return (
                        <ListItem
                            key={supplier.name}
                            button
                            onClick={handleToggle(supplier.name)}
                            style={{
                                backgroundColor:
                                    selectedSuppliers.indexOf(supplier.name) !== -1 ? theme.palette.action.selected : 'transparent',
                                marginBottom: '4px',
                                borderRadius: '4px'
                            }}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={selectedSuppliers.indexOf(supplier.name) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                id={labelId}
                                primary={supplier.name}
                                primaryTypographyProps={{
                                    style: {
                                        color: theme.palette.text.primary
                                    }
                                }}
                            />
                        </ListItem>
                    );
                })}
            </List>
            <Pagination
                count={Math.ceil((suppliersData?.length || 0) / itemsPerPage)}
                page={page}
                onChange={handleChangePage}
                color="primary"
                style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
            />
            <Button
                variant="contained"
                color="primary"
                startIcon={<CloudDownloadIcon />}
                onClick={handleDownload}
                disabled={isPending || selectedSuppliers.length === 0}
                style={{ marginTop: '20px' }}
            >
                {isPending ? t('core:loading') : t('common:export')}
            </Button>
        </Paper>
    );
};

export default SupplierDownloader;
