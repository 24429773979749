import { Box } from '@mui/system';
import useGetAxios from 'hooks/useGetAxios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useStyles } from 'views/utilities/Style';
import InfoIcon from '@mui/icons-material/Info';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MainCard from 'ui-component/cards/MainCard';
import { Button, Grid, Modal, Typography } from '@mui/material';
import InputTextField from 'ui-component/form/InputTextField';
import GeneralForm from 'ui-component/form/GeneralForm';
import { modalStyle } from 'views/utilities/ModalStyle';
import useFetch from 'hooks/useFetch';
import SelectForm from 'ui-component/form/SelectForm';
import { LoadingButton } from '@mui/lab';
import { SetNotification } from 'store/services/api';
import { formatErpResponse } from 'utils/utils';
import Loader from 'ui-component/Loader';
import ItemsGrid from './ItemsGrid';
import { useTranslation } from 'react-i18next';

const ProductBundleForm = () => {
    const classes = useStyles();
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { data: doc, isPending } = useGetAxios(`${process.env.REACT_APP_API_URI}/api/details?doctype=Product%20Bundle&name=${id}`);

    const itemGroupsReqData = {
        doctype: 'Item Group',
        fields: ['`tabItem Group`.`name`'],
        start: 0,
        page_length: 500
    };
    const { data: dataitemgroups, isPendingItemGroups } = useFetch(`/api/get-list`, itemGroupsReqData, 'POST');

    const parentItemsReqData = {
        doctype: 'Item',
        fields: ['`tabItem`.`name`'],
        filters: [['Item', 'is_stock_item', '=', 0]],
        order_by: '`tabItem`.`modified` asc'
    };

    const itemsReqData = {
        doctype: 'Item',
        fields: [
            '`tabItem`.`name`',
            '`tabItem`.`item_name`',
            '`tabItem`.`description`',
            '`tabItem`.`item_code`',
            '`tabItem`.`item_group`',
            '`tabItem`.`stock_uom`'
        ],
        filters: [['Item', 'is_stock_item', '=', 1]]
    };

    const productBundleReqData = {
        doctype: 'Product Bundle',
        fields: ['`tabProduct Bundle`.`new_item_code`'],
        start: 0,
        page_length: 100
    };

    const {
        data: parentItems,
        isPending: isParentItemsPending,
        refetch: refetchparentItems
    } = useFetch(`/api/get-list`, parentItemsReqData, 'POST');
    const { data: items, isPending: isItemsPending } = useFetch(`/api/get-list`, itemsReqData, 'POST');
    const { data: prodBundles, isProdBundlesPending, refetch } = useFetch(`/api/get-list`, productBundleReqData, 'POST');

    const [open, setOpen] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const handleOpen = () => setOpen(navigate('/pim/add-products-bundle'));
    const handleClose = () => setOpen(false);

    const [selectionItemsModel, setSelectionItemsModel] = useState([]);

    const [formState, setFormState] = useState({
        parent_item: '',
        description: ''
    });
    const [formErrState, setFormErrState] = useState({
        parent_item: false,
        description: false,
        items: false
    });

    const handleChange = (e) => {
        setFormState({ ...formState, [e.target.name]: e.target.value });
    };

    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem('user'))?.token
    };

    const handleSaveItemBundle = async () => {
        const errform = {
            parent_item: !formState.parent_item || formState.parent_item == '',
            description: !formState.description || formState.description == '',
            items: !selectionItemsModel || !Array.isArray(selectionItemsModel) || selectionItemsModel.length < 1
        };
        setFormErrState(errform);
        if (JSON.stringify(errform).indexOf('true') < 0) {
            setLoadingButton(true);
            let bundleItems = [];
            selectionItemsModel.map((selectedItem) => {
                let row = items?.find((item) => item.name === selectedItem);

                bundleItems.push({
                    __islocal: 1,
                    __unsaved: 1,
                    parentfield: 'items',
                    parenttype: 'Product Bundle',
                    __unedited: false,
                    description: row.description,
                    uom: row.stock_uom,
                    item_code: row.item_code,
                    qty: 1
                });
            });

            const data = {
                doc: {
                    ...(!id && { __islocal: 1 }),
                    ...(id && {
                        creation: doc.creation,
                        modified: doc.modified,
                        owner: doc.owner,
                        modified_by: doc.owner,
                        name: doc.name
                    }),
                    doctype: 'Product Bundle',
                    description: formState.description,
                    new_item_code: formState.parent_item,
                    items: bundleItems,
                    __unsaved: 1
                },
                action: 'Save'
            };

            fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
                method: 'POST',
                headers: header,
                body: JSON.stringify(data)
            })
                .then((res) => {
                    setLoadingButton(false);
                    if (!res.ok) {
                        dispatch(SetNotification({ code: 'error', message: t('common:AnErrorHasOccurred') }));
                    }
                    return res.json();
                })
                .then((data) => {
                    if (data?.success === false) {
                        dispatch(SetNotification({ code: 'warning', message: formatErpResponse(data?.message) }));
                    } else {
                        dispatch(
                            SetNotification({
                                code: 'success',
                                message: `Le Produit Nomenclature a été ${!id ? 'sauvegardé' : ''} modifié avec succès`
                            })
                        );
                        navigate('/pim/product-bundles');
                    }
                });
        }
    };

    const [loadingButtonItemParent, setLoadingButtonItemParent] = useState(false);
    const [stateItemParent, setStateItemParent] = useState({
        code: '',
        name: '',
        itemgroup: '',
        selling_rate: ''
    });
    const [labelItemParent, setLabelItemParent] = useState({
        code: t('products:productCode'),
        name: t('products:productName'),
        itemgroup: t('products:productCategory'),
        selling_rate: 'Taux du vente'
    });
    const [errorItemParent, setErrorItemParent] = useState({
        code: false,
        name: false,
        itemgroup: false,
        selling_rate: false
    });

    const handleSubmitItemParent = (e) => {
        setLoadingButtonItemParent(true);
        const errform = {
            code: !stateItemParent.code || stateItemParent.code == '',
            name: !stateItemParent.name || stateItemParent.name == '',
            itemgroup: !stateItemParent.itemgroup || stateItemParent.itemgroup == '',
            selling_rate: !stateItemParent.selling_rate || stateItemParent.selling_rate == '' || isNaN(stateItemParent.selling_rate)
        };
        setErrorItemParent(errform);
        if (JSON.stringify(errform).indexOf('true') < 0) {
            const doc = {
                doctype: 'Item',
                item_code: stateItemParent?.code,
                item_name: stateItemParent?.name,
                item_group: stateItemParent?.itemgroup.name,
                standard_rate: stateItemParent?.selling_rate,
                naming_series: 'STO-ITEM-.YYYY.-',
                stock_uom: 'Nos',
                is_stock_item: 0,
                create_variant: 0,
                has_variants: 0,
                default_material_request_type: 'Purchase',
                __islocal: 1,
                __unsaved: 1,
                disabled: 0,
                allow_alternative_item: 0,
                include_item_in_manufacturing: 1,
                is_fixed_asset: 0,
                auto_create_assets: 0,
                end_of_life: '2099-12-31',
                valuation_method: '',
                has_batch_no: 0,
                create_new_batch: 0,
                has_expiry_date: 0,
                retain_sample: 0,
                has_serial_no: 0,
                min_order_qty: 0,
                is_purchase_item: 1,
                is_customer_provided_item: 0,
                delivered_by_supplier: 0,
                enable_deferred_expense: 0,
                grant_commission: 1,
                is_sales_item: 1,
                enable_deferred_revenue: 0,
                inspection_required_before_purchase: 0,
                inspection_required_before_delivery: 0,
                is_sub_contracted_item: 0,
                published_in_website: 0
            };
            fetch(`${process.env.REACT_APP_API_URI}/api/insert`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
                    Authorization: JSON.parse(localStorage.getItem('user'))?.token
                },
                body: JSON.stringify({
                    doc: doc
                })
            })
                .then((res) => {
                    setLoadingButtonItemParent(false);
                    if (!res.ok) {
                        dispatch(SetNotification({ message: 'Une erreur se produit', code: 'error' }));
                    } else {
                        return res.json();
                    }
                })
                .then((data) => {
                    if (data) {
                        refetchparentItems({});
                        dispatch(
                            SetNotification({
                                message: 'Le produit a été modifiée avec succès',
                                code: 'success'
                            })
                        );
                        setFormState({ ...formState, parent_item: data.item_code });
                        setStateItemParent({
                            code: '',
                            name: '',
                            itemgroup: '',
                            selling_rate: ''
                        });
                    }
                    setOpen(false);
                });
        } else {
            setLoadingButtonItemParent(false);
        }
    };

    const addItemParentForm = (
        <Box sx={modalStyle}>
            <MainCard title={t('products:addParentProduct')} divider>
                <GeneralForm
                    isadd
                    ismodal
                    setOpen={setOpen}
                    loadingButton={loadingButtonItemParent}
                    state={stateItemParent}
                    label={labelItemParent}
                    setState={setStateItemParent}
                    error={errorItemParent}
                    setError={setErrorItemParent}
                    itemgroups={dataitemgroups}
                    handleSubmit={handleSubmitItemParent}
                />
            </MainCard>
        </Box>
    );

    const columns = [
        { field: 'name', headerName: 'name', flex: 1 },
        {
            field: 'item_name',
            headerName: t('products:productName'),
            flex: 1
        },
        {
            field: 'description',
            headerName: t('common:description'),
            flex: 1
        },
        {
            field: 'item_group',
            headerName: t('products:productCategory'),
            flex: 1
        }
    ];

    useEffect(() => {
        if (id && doc?.new_item_code) {
            setFormState({ ...formState, parent_item: doc.new_item_code, description: doc.description });
            const items = [];
            doc?.items?.map((el) => {
                items.push(el.item_code);
            });
            setSelectionItemsModel(items);
        }
    }, [doc]);

    const title = (
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Box p={-1} display={'flex'} flexDirection={'column'}>
                <ArrowBackIcon className={classes.backLink} onClick={() => navigate(-1)} />
                {id ? `${t('products:productsBundle')}: ${doc?.description}` : t('products:newProductBundle')}
            </Box>
            <Grid>
                <Button variant="outlined" color="blue" onClick={handleOpen}>
                    {t('products:addParentProduct')}
                </Button>
            </Grid>
        </Box>
    );

    const getUnused = (data) => {
        let result = [];
        if (Array.isArray(prodBundles)) {
            data.map((el) => {
                let indx = prodBundles.findIndex((row) => row.new_item_code === el.name);
                if (indx === -1) {
                    result.push(el);
                }
            });
            return result;
        } else {
            return data;
        }
    };

    if ((id && isPending) || isProdBundlesPending || isParentItemsPending) return <Loader />;

    return (
        <Box>
            <MainCard title={title}>
                <Box display={'flex'} mt={-4} mb={2}>
                    <InfoIcon sx={{ mt: 0.6 }} />
                    <Typography p={1} variant="h5" color="primary">
                        {t('products:msg2')}
                    </Typography>
                </Box>
                <Grid container spacing={2} display={'flex'} justifyContent={'left'} flexDirection={'column'}>
                    <SelectForm
                        //flagParentProductBundle
                        label={t('products:parentProduct')}
                        handleOpenParentProductBdl={handleOpen}
                        error={formErrState?.parent_item && formState.parent_item == ''}
                        value={formState.parent_item}
                        handleChange={handleChange}
                        data={id ? parentItems : getUnused(parentItems)}
                        propToRender={'name'}
                        name="parent_item"
                        refetch={refetchparentItems}
                        required
                        helper={"C'est le produit principal ou global de le Produit Nomenclature spécifié dans le système "}
                    />
                    <InputTextField
                        required
                        error={formErrState?.description && formState.description == ''}
                        handleChange={handleChange}
                        value={formState.description}
                        label={t('common:description')}
                        name="description"
                    />

                    <br />
                    <br />
                    <ItemsGrid
                        selectionItemsModel={selectionItemsModel}
                        setSelectionItemsModel={setSelectionItemsModel}
                        columns={columns}
                        items={items}
                        formErrState={formErrState}
                        isItemsPending={isItemsPending}
                        labelItems={t('products:productsList') + ' :'}
                        labelSelection={'Cet ensemble inclus :'}
                    />
                </Grid>
                <br />
                <br />
                <Grid container item xs={12} sm={12} md={8} lg={8} xl={8} spacing={4} display={'flex'} justifyContent={'right'}>
                    <Grid item>
                        <LoadingButton loading={loadingButton} variant="contained" size="large" color="blue" onClick={handleSaveItemBundle}>
                            {t('common:save')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </MainCard>
            <Modal onClose={handleClose} open={open}>
                {addItemParentForm}
            </Modal>
        </Box>
    );
};

export default ProductBundleForm;
